const regulationsGuatemala = {
    country: "Guatemala",
    countryCode: 'GT',
    deviceTypes: {
        "Medical Device": {
            "Classes": ["Class I", "Class II", "Class III", "Class IV"]
        },
        "In Vitro Device": {
            "Classes": ["Class I", "Class II", "Class III", "Class IV"]
        }
    },
    timeline: "The Sanitary Authorizations Section has 45 business days to process the file: 35 for evaluation and 10 to assign the registration number. If there are observations, the professional is notified once. The professional has 45 non-extendable business days to respond. Without response, the process is canceled and filed. If responded adequately, the Section has an additional 15 days to finalize and issue the certificate. If there are errors in finalized procedures, they must be corrected within a maximum of three months.",
    cost: {
        "Class I": "$10-$689.45",
        "Class II": "$10-$689.45",
        "Class III": "$10-$689.45",
        "Class IV": "$10-$689.45",
    },

    requirements: {
        sanitaryRegistration: {
            "Proof of Payment of Fees. Payment receipt for the Sanitary Registration process fee of a Food Product.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Labels. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "VC-3-001 Form. Obtain the latest version of the form from the department.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Copy of the G3-A2 Receipt from the Department." : {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Sanitary Registration Application. F-AS-08 current version, signed, sealed, and stamped by the Responsible Professional.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Manufacturer's Warranty Certificate. Simple copy of the valid certificate issued by the Regulatory Authority or Manufacturing Warranty Certifying Entities (GMP, Approved Standards, ISO Certificate).": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Free Sale Certificate. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Manufacturing Contract. An excerpt of the contract that details relevant information about the parties, manufacturing practices, production processes, technical management, materials used, among others, must be presented.": {
                appliesTo: {
                    "Medical Device": ["Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class II", "Class III", "Class IV"],
                }
            },
            "Power or Mandate. A simple copy of a Power or Mandate granted by the Authorizing Party in favor of the Representative in Guatemala.": {
                appliesTo: {
                    "Medical Device": ["Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class II", "Class III", "Class IV"],
                }
            },
            "Appointment Certificate. Simple copy of the Legal Representative's Appointment Certificate in the country, with registration in the Commercial Registry.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Letterhead Letter. Must be recent, signed and sealed by the Legal Representative, appointing the Professional Responsible for the Sanitary Registration.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Valid Sanitary License for Distributors. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Product Formula. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Product Specifications.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Detailed Product Description. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Quality Control Analysis Certificate. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Technical Literature/Information. Technical literature of the product must be presented, either in printed format or on CD.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "** Documents must be presented in a natural-colored folder, with a specific format (F-AS-0F-JE-P-13 current version).": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
        },
        technicalAnalysis: {
            "Physicochemical and Microbiological Analysis. ": {
                appliesTo: {
                    "Medical Device": ["Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class II", "Class III", "Class IV"],
                }
            }, 
        }
    }
};

export default regulationsGuatemala;