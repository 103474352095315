import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import { listProducts, getManufacturer } from './graphql/queries';
import styled from 'styled-components';
import { useUser } from './UserContext';
import { Link, useNavigate } from 'react-router-dom';

// Styles as consts
const containerStyle = {
    maxWidth: '313px',
    minWidth: '313px',
    background: '#FFFFFF',
    boxShadow: '0 4px 16px #60657b24',
    paddingTop: '20px',
    paddingLeft: '40px',
    paddingBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
    position: 'sticky',
    height: '100vh',
    zIndex: 1,
    left: 0,
    top: 0
};

const skipLinkStyle = {
    background: '#333954',
    color: '#fff',
    fontSize: '.875rem',
    lineHeight: '1.3125rem',
    textDecoration: 'underline',
    textAlign: 'center',
    padding: '9px 15px 10px',
    position: 'absolute',
    width: '100%',
    left: 0,
    transform: 'translateY(-100px)',
    transition: 'transform .3s'
};

const containerFlexStyle = {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'flex-end'
};

const doubleStyle = {
    height: '4vh',
    minHeight: '24px',
    maxHeight: '32px'
};

const spacerStyle = {
    width: '100%',
    height: '2vh',
    minHeight: '12px',
    maxHeight: '16px'
};

const rightStyle = {
    width: '90%'
};

const dividerStyle = {
    backgroundColor: '#f1f2fa',
    height: '1px',
    width: '100%'
};

const tripleStyle = {
    height: '6vh',
    minHeight: '36px',
    maxHeight: '48px'
};

const menuWrapperStyle = {
    overflow: 'auto'
};

const subtitleStyle = {

    alignItems: 'center',
    color: '#6d758d',
    paddingLeft: '40px',
    fontSize: '.6875rem',
    fontWeight: 400,
    lineHeight: '1rem',
    letterSpacing: '.8px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    webkitBoxOrient: 'vertical',
    webkitLineClamp: 3,
    width: '200px'
};

const smallSpacerStyle = {
    height: '1vh',
    minHeight: '6px',
    maxHeight: '8px',
    paddingLeft: '30px'

};

const Logo = styled.img`
  width: 218px;
  height: 80px;
  margin-bottom: 0.5rem;
`;

const Title = styled.div`
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 10px;
`;

const MenuItem = styled(Link)`
    display: flex;
    align-items: center;
    padding: 10px 10px;
    cursor: ${props => props.active ? 'default' : 'pointer'};
    pointer-events: ${props => props.active ? 'none' : 'auto'};
    transition: background-color 0.3s;
    text-decoration: none; // Remove default link styling
    color: inherit; // Inherit the text color

    &:hover {
        background-color: ${props => props.active ? 'transparent' : '#ECF5FF'};
    }

    & > svg {
        margin-right: 10px;
    }
    background-color: ${props => props.active ? '#ECF5FF' : 'transparent'};
`;

const ProdMenuItem = styled(Link)`
    display: flex;
    align-items: center;
    padding: 10px 10px;
    cursor: ${props => props.active ? 'default' : 'pointer'};
    pointer-events: ${props => props.active ? 'none' : 'auto'};
    transition: background-color 0.3s;
    text-decoration: none; // Remove default link styling
    color: inherit; // Inherit the text color

    &:hover {
        background-color: ${props => props.active ? 'transparent' : '#ECF5FF'};
    }

    & > svg {
        margin-right: 10px;
    }
    background-color: ${props => props.active ? '#ECF5FF' : 'transparent'};
`;


const GearIcon = styled.img`
    width: 25px;      // Adjust as necessary
    height: 25px;     // Adjust as necessary
    margin-right: 10px;
`;

const RegIcon = styled.img`
    width: 25px;      // Adjust as necessary
    height: 25px;     // Adjust as necessary
    margin-right: 10px;
`;

const Subtitle = styled(Link)`
    font-size: 1rem;
    color: #6d758d; // Adjust the color as needed
    display: flex;
    padding: 5px 47px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-decoration: none; // Remove default link styling
    color: inherit; // Inherit the text color

    &:hover {
        background-color: #ECF5FF;
    }

    & > svg {
        margin-right: 10px;
    }
    background-color: ${props => props.active ? '#ECF5FF' : 'transparent'};
`;

const ProductLink = styled(Link)`
    // Add your styles for product links here
`;

const SubMenuTitle = styled.div`
    padding: 10px 47px;
    font-size: 1rem;
    color: #333;
    font-weight: bold;
`;

const SubMenuLink = styled(Link)`
    display: block;
    padding: 10px 47px;
    font-size: 0.9rem;
    color: #6d758d;
    text-decoration: none;

    &:hover {
        background-color: #ECF5FF;
    }
`;

const Sidebar = ({ activeItem }) => {
    const { userType, userId } = useUser();
    const [isManuf, setIsManuf] = useState(false);
    const [products, setProducts] = useState([]);
    const [productId, setProductId] = useState([]);
    const isAdmin = sessionStorage.getItem('isAdmin') === 'true';
    const [companyData, setCompanyData] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        const effectiveUserId = localStorage.getItem('userId');
        const userType = localStorage.getItem('userType');

        const fetchManufacturerData = async () => {
            if (!effectiveUserId) {
                console.error("No userId available");
                // Handle error as needed
                return;
            }

            try {
                const userDataResponse = await API.graphql(graphqlOperation(getManufacturer, { id: effectiveUserId }));
                const manufacturerData = userDataResponse.data.getManufacturer;

                if (manufacturerData) {
                    setCompanyData({
                        companyId: manufacturerData.companyID,
                        companyName: manufacturerData.companyName
                    });
                    fetchProducts(manufacturerData.companyID);
                } else {
                    console.error("Manufacturer data not found for userId:", effectiveUserId);
                    // Handle error as needed
                }
            } catch (error) {
                console.error("Error fetching manufacturer data:", error);
                // Handle error as needed
            }
        };

        if (userType === 'Manufacturer') {
            fetchManufacturerData();
        }
    }, []);

    useEffect(() => {
        //console.log(`isAdmin: ${isAdmin}, userType: ${userType}, userId: ${userId}, isManuf: ${isManuf}`);
        if (userType === 'Manufacturer') {
            setIsManuf(true);
            fetchProducts();
        }
    }, [userType, userId, isManuf, setIsManuf]);

    const fetchProducts = async (companyId) => {
        try {
            const response = await API.graphql(graphqlOperation(listProducts, { filter: { companyId: { eq: companyId } } }));
            const productsData = response.data.listProducts.items;
            setProducts(productsData);

        } catch (error) {
            console.error('Error fetching products:', error);
            // Handle error as needed
        }
    };

    const handleProductClick = (productId) => {
        // Navigate to ProductPage with the selected product ID
        navigate(`/ProductPage/${productId}`);
    };


    return (
        <div style={containerStyle}>
            {/* Add your sidebar content here */}
            <Logo src="/medikanaLogoWithName.png" alt="Logo" />

            <div style={spacerStyle}></div>
            <div style={rightStyle}></div>
            <div style={dividerStyle}></div>
            <div style={tripleStyle}></div>

            <div style={menuWrapperStyle}>

            </div>
            <div style={subtitleStyle}>
                <Title>My Account</Title>   {/* Adding the title */}
            </div>
            <div style={smallSpacerStyle}>
                {/* <MenuItem to="/deviceClassificationForm" active={activeItem === 'deviceClassificationForm'}> 
                    <RegIcon src="/classificationIcon.png" alt="Settings Icon" />
                    Device Classification
                </MenuItem> */}
               
                {isAdmin && ( //isManuf

                    <div style={menuWrapperStyle}>
                        <ProdMenuItem to={`/ProductPage`}>
                            <RegIcon src="/addProductIcon.png" alt="Settings Icon" />
                            My Products
                        </ProdMenuItem>
                        {products.map((product) => (
                            <SubMenuLink
                                key={product.productId}
                                to={`/ProductPage/${product.id}`}
                            >
                                {product.productName}
                            </SubMenuLink>
                        ))}
                    </div>
                )} 
                 {isAdmin && ( //isManuf
                <div style={menuWrapperStyle}>
                    <MenuItem to="/RegulatorySummaries" active={activeItem === 'regulatorySummaries'}>
                        <RegIcon src="/regulatoryIcon.png" alt="Settings Icon" />
                        Regulatory Summaries
                    </MenuItem>
                
                </div>
                    
                )} 
                <div style={menuWrapperStyle}>
                    <MenuItem to="/dashboard" active={activeItem === 'accountSettings'}>
                        <GearIcon src="/gearIcon.png" alt="Settings Icon" />
                        Account Settings
                    </MenuItem>
                </div>
                {isAdmin && (
                    <div style={menuWrapperStyle}>
                        <MenuItem to="/OverviewPanel" active={activeItem === 'admin'}>
                            Database Overview Panel
                        </MenuItem>
                    </div>
                )}

            </div>
        </div>
    );
};

export default Sidebar;

