import React, { useState, useEffect } from 'react';
import Sidebar from './sideBar';
import { useUser } from './UserContext';
import { API, graphqlOperation } from 'aws-amplify';
import { getManufacturer, listProducts } from './graphql/queries';
import { createProducts } from './graphql/mutations'; // Import the createProduct mutation
import styled from 'styled-components';
import { Button, TextField, MenuItem, Typography } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { S3Client, GetObjectCommand, PutObjectCommand } from '@aws-sdk/client-s3';
import Grid from '@material-ui/core/Grid';
import { useNavigate } from 'react-router-dom';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';



const styles = {
    container: {
        display: 'flex',
        minHeight: '100vh'
    },
    content: {
        flex: 1,
        padding: '20px',
        backgroundColor: '#f4f4f4', // Light grey background for the content area
    },
    title: {
        color: '#333', // Dark grey color for text
        marginBottom: '20px',
    },
    section: {
        backgroundColor: '#fff', // White background for each section
        padding: '15px',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Subtle shadow for depth
        marginBottom: '20px',
    },
    sectionTitle: {
        color: '#0056b3', // Blue color for section titles
        marginBottom: '10px',
    },
    requirement: {
        marginBottom: '15px',
        borderBottom: '1px solid #eee', // Light line for separating requirements
        paddingBottom: '10px',
    },
    fileInput: {
        border: '1px solid #ddd',
        borderRadius: '4px',
        padding: '10px',
        marginRight: '10px',
    },
    uploadButton: {
        backgroundColor: '#4caf50', // Updated button color
        color: 'white',
        padding: '10px 15px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'background-color 0.2s',
    },
};

const Container = styled.div`
    background: url('/corners.png') no-repeat center center fixed; 
    background-size: cover; // Ensure that one image covers the full container
`;

const Logo = styled.img`
  width: 350px;
  height: 130px;
  margin-bottom: 1rem;
  margin-right: 1.5rem;
  @media (max-width: 600px) {
    width: 300px; // Smaller width for smaller screens
    height: auto; // Maintain aspect ratio
  }

  @media (max-width: 480px) {
    width: 200px; // Even smaller width for mobile screens
    height: auto; // Maintain aspect ratio
  }

`;

const WelcomeMessage = styled(Typography)`
  margin-bottom: 2rem;
  color:#000000;
  fontFamily: '"aktiv-grotesk", Arial, sans-serif !important',
  font-weight: 900;
`;

const Form = styled.form`
  flex-wrap: wrap;
  justify-content: flex-start; // Changed from space-between to flex-start
  max-width: 600px;
  width: 100%;
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 10px 25px -5px rgba(0, 0, 0, 0.2);
  margin-bottom: 50px;
  @media (max-width: 600px) {
    max-width: 90%; // Allow the form to scale down
    padding: 1rem;
  }

  @media (max-width: 480px) {
    max-height: calc(100vh - 80px);// Further scale down the form on very small screens
    padding: 1rem;
  }
  
`;

const FormField = styled(TextField)`
  margin-bottom: 1rem;
  width: 100%; // Subtract padding from the total width
  padding: 10px; // Add padding to prevent text from touching the edges
  box-sizing: border-box; // Ensures padding is included in width
  border-radius: 50px;
`;

const FieldContainer = styled.div`
  width: 100%; // adjust the width as needed
`;

const ActionButton = styled(Button)`
  fontFamily: '"aktiv-grotesk", Arial, sans-serif !important',
  margin-top: 1rem;
  border-radius: 90px;
  color: white;
  

  &:hover {
    background: linear-gradient(45deg, #5982DB 30%, #5982DB 90%);
  }

  &:active {
    transform: scale(0.98);
    transition: all .1s ease-in-out;
  }
  && {
    text-transform: Capitalize;
  }
`;

const StyledDropzone = styled.div`
    border: 2px dashed #cccccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: border 0.3s ease;
    width: 50%; // Added to make width smaller
    height: 80px; 
    margin-bottom: 10px;

    &:hover {
    border: 2px dashed #0000cc;
    }
`;

const LinkContainer = styled.div`
    display: inline-flex;
    align-items: center;
    padding: 5px 10px ;
    background-color: #f4f4f4;
    border-radius: 5px;
`;

const LinkText = styled.span`
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    padding: 0 5px; /* Add padding inside the text component */
`;

const RemoveLinkButton = styled.button`
    background-color: #a8a8a8;
    color: white;
    border: none;
    margin-left: 10px;
    border-radius: 5px;
    padding: 0 8px;
    cursor: pointer;
`;

const TitleLabel = styled.div`
  font-weight: bold; // Bold font weight for title
  margin-bottom: 5px;
  color: #808080;

  @media (max-width: 768px) {
    font-size: 14px; // Smaller font size on mobile
  }

  @media (max-width: 321px) {
    font-size: 12px; // Even smaller font size for very small screens
  }
`;

const SubtitleLabel = styled.div`
  font-weight: normal; // Normal font weight for subtitle
  color: #808080;

  @media (max-width: 768px) {
    font-size: 14px; // Smaller font size on mobile
  }

  @media (max-width: 321px) {
    font-size: 12px; // Even smaller font size for very small screens
  }
`;

const productType = [
    'Software', 'Consumable', 'Implantable Devices', 'Capital Equipment',
    'In Vitro Diagnostics', 'Reusable Equipment', 'Hybrid', 'Other'
];

const developmentStage = [
    'Design and Development', 'Pre-Clinical Testing', 'Regulatory Submitted',
    'Regulatory Approved and Commercial in Country of Origin', 'Regulatory Approved and Commercial in other countries',
    'Other'
];

const regulatoryApprovals = [
    'FDA (United States)', 'CE Mark (Europe)', 'COFEPRIS (Mexico)', 'ANVISA (Brazil)', 'NMPA (China)',
    'PMDA (Japan)', 'CDSCO (India)', 'Other - Country of Origin', 'Other'
]

const currencies = [
    { label: 'USD ($)', value: 'USD' },      // United States Dollar
    { label: 'EUR (€)', value: 'EUR' },      // Euro
    { label: 'JPY (¥)', value: 'JPY' },      // Japanese Yen
    { label: 'CNY (¥)', value: 'CNY' },      // Chinese Yuan Renminbi
    { label: 'INR (₹)', value: 'INR' },      // Indian Rupee
    { label: 'GBP (£)', value: 'GBP' },      // British Pound Sterling
];

const AddProduct = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const urlUserId = queryParams.get('userId');
    const { userId: contextUserId } = useUser();
    const [companyData, setCompanyData] = useState({ companyId: '', companyName: '' });
    const effectiveUserId = urlUserId || contextUserId || localStorage.getItem('userId');

    const [regulatoryApproval, setRegulatoryApproval] = useState('');
    const [files, setFiles] = useState([]);
    const [showDropzone, setShowDropzone] = useState(false);
    const [currency, setSelectedCurrency] = useState('USD'); // default currency
    const [linkInput, setLinkInput] = useState('');  // Temporary state for the current link being inputted
    const [relatedLinks, setRelatedLinks] = useState([]);  // Array to store multiple links

    const [productAdded, setProductAdded] = useState(false);
    const [products, setProducts] = useState([]);

    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        companyName: '',
        productName: '',
        productDescription: '',
        productType: '',
        competitiveAdvantage: '',
        developmentStage: '',
        regulatoryApprovals: [],
        approvalDocuments: [],
        clinicalIndications: '',
        competitors: '',
        targetDemographic: '',
        currency: '',
        transferPriceMin: 0,
        transferPriceMax: 0,
        distributionChannels: '',
        relatedLinks: [],
        otherDocuments: [],
    });
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const fetchManufacturerData = async () => {
            //console.log("Fetching data for userId:", effectiveUserId);
            if (!effectiveUserId) {
                console.error("No userId available");
                setErrorMessage("No userId available");
                return;
            }

            try {
                const userDataResponse = await API.graphql(graphqlOperation(getManufacturer, { id: effectiveUserId }));
                //console.log("Manufacturer Data Response:", userDataResponse);
                const manufacturerData = userDataResponse.data.getManufacturer;

                if (manufacturerData) {
                    setCompanyData({
                        companyId: manufacturerData.companyID,
                        companyName: manufacturerData.companyName
                    });
                } else {
                    console.error("Manufacturer data not found for userId:", effectiveUserId);
                    setErrorMessage('Manufacturer data not found for the given userId.');
                }
            } catch (error) {
                console.error("Error fetching manufacturer data:", error);
                setErrorMessage('Error fetching manufacturer data.');
            }
        };

        fetchManufacturerData();
    }, [effectiveUserId]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const timestamp = Date.now();
        const productID = `PROD-${timestamp}-${Math.floor(Math.random() * 1000)}`;

        const uploadedFiles = await Promise.all(files.map(file =>
            uploadFileToS3(file, companyData.companyId, productID)
        ));

        // Filter successful uploads and get their S3 keys
        const s3Keys = uploadedFiles.filter(file => file.success).map(file => file.s3Key);
        console.log(companyData.companyName)
        try {
            const newProductData = {
                ...formData,
                productId: productID,
                companyId: companyData.companyId,
                companyName: companyData.companyName,
                approvalDocuments: s3Keys
            };
            await API.graphql(graphqlOperation(createProducts, { input: newProductData }));
            setProductAdded(true);
            navigate('/ProductPage');
            
        } catch (error) {
            setErrorMessage('Error adding product. Please try again.');
        }
    };

    const clearApproval = () => {
        setRegulatoryApproval(null);
    }

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setFiles(prevFiles => [...prevFiles, ...Array.from(e.target.files)]);
        }
    };;

    const FileDropzone = ({ onFileChange }) => {
        const { getRootProps, getInputProps } = useDropzone({
            onDrop: acceptedFiles => {
                setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
            },
            multiple: true
        });

        if (!showDropzone) return null;

        return (
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                <StyledDropzone {...getRootProps()}>
                    <input {...getInputProps()} />
                    <img src="/upload-icon.png" width="22" height="22"></img>
                    <TitleLabel>
                        Upload approval documents
                    </TitleLabel>
                    <SubtitleLabel>Drag & drop your files here, or click to select them</SubtitleLabel>
                </StyledDropzone>
            </div>
        );
    };

    const handleFileRemove = (index) => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    };

    const handleRegulatoryApprovalChange = (event) => {
        const selectedApproval = event.target.value;
        setFormData({ ...formData, regulatoryApprovals: selectedApproval });

        // Update the showDropzone state based on the selection
        if (selectedApproval && selectedApproval.length > 0) {
            setShowDropzone(true);
        } else {
            setShowDropzone(false);
        }
    };

    const uploadFileToS3 = async (file, companyId, productId) => {
        const s3Client = new S3Client({
            region: 'us-east-1',
            credentials: {
                accessKeyId: "AKIAWH47LBWIJ52QVAHC",
                secretAccessKey: "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"
            }
        });

        const fileName = `${companyId}/${productId}/${file.name}`;
        try {
            const command = new PutObjectCommand({
                Bucket: 'manufactureruploads171127-dev',
                Key: `public/${fileName}`,
                Body: file
            });

            await s3Client.send(command);
            //console.log("S3 Key for uploaded file:", fileName);
            return { success: true, s3Key: fileName };

        } catch (error) {
            console.error("Error uploading file to S3:", error);
            return { success: false };
        }
    };

    const handleCurrencyChange = (event) => {
        setSelectedCurrency(event.target.value);
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value }); const handleRemoveLink = (linkToRemove) => {
            const newLinks = relatedLinks.filter(link => link !== linkToRemove);
            setRelatedLinks(newLinks);
            setFormData({ ...formData, relatedLinks: newLinks }); // Update the formData state here too
        };
    };

    const handleLinkInputChange = (event) => {
        setLinkInput(event.target.value);
    };

    const handleLinkInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (linkInput) {
                const newLinks = [...relatedLinks, linkInput];
                setRelatedLinks(newLinks);
                setFormData({ ...formData, relatedLinks: newLinks }); // Update the formData state here
                setLinkInput('');
            }
        }
    };

    const handleRemoveLink = (linkToRemove) => {
        const newLinks = relatedLinks.filter(link => link !== linkToRemove);
        setRelatedLinks(newLinks);
        setFormData({ ...formData, relatedLinks: newLinks }); // Update the formData state here too
    };

    const fetchProducts = async () => {
        try {
            const response = await API.graphql(graphqlOperation(listProducts, { filter: { companyId: { eq: companyData.companyId } } }));
            // Assuming the response structure includes an items array
            const productsData = response.data.listProducts.items;
            setProducts(productsData);
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    };

    useEffect(() => {
        if (productAdded) {
            fetchProducts();
        }
    }, [productAdded]);
    



    return (
        <Container style={{ width: '100%', height: '100%' }}>
        <div style={{ display: 'flex', minHeight: '100vh' }}>
            <Sidebar />
            <div style={{ flex: 1, padding: '20px' }}>
                {productAdded ? (
                    <div>
                        <h2 style={styles.title}>Your Products</h2>
                        {/* Table to display products */}
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold' }}>Product Name</TableCell>
                                    <TableCell style={{ fontWeight: 'bold' }}>Description</TableCell>
                                    {/* Add other relevant columns */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(products) && products.length > 0 ? (
                                    products.map(product => (
                                        <TableRow key={product.id}>
                                            <TableCell>{product.productName}</TableCell>
                                            <TableCell>{product.productDescription}</TableCell>
                                            {/* Render other product details */}
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={2}>No products found</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                        <ActionButton variant="contained" onClick={() => setProductAdded(false)} style={{ width: '20%', borderRadius: '10px', marginRight: '10px', backgroundColor: "#0086ff", color: "white", marginTop: '20px' }}>
                            Add New Product
                        </ActionButton>
                    </div>
                ) : (
                    <div style={{ flex: 1, padding: '20px' }}>
                        <Form onSubmit={handleSubmit}>
                            <WelcomeMessage variant="h4" color="primary" style={{ marginBottom: '15px', fontSize: '28px', color: "#000000" }}>
                                Add A New Product
                            </WelcomeMessage>
                            {/* Form fields for product data */}
                            {/* Example field */}
                            <FieldContainer>
                                <FormField
                                    label="Product Name"
                                    name="productName"
                                    value={formData.productName}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    required
                                    style={{ marginBottom: '15px' }}
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FormField
                                    label="Product Description"
                                    multiline // Add this line to make it multi-line
                                    rows={3}  // This specifies the number of visible rows. You can adjust it based on your preference.
                                    name="productDescription"
                                    value={formData.productDescription}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    required
                                    style={{ marginBottom: '15px' }}
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FormField
                                    select
                                    name="productType"
                                    label="Product Type"
                                    variant="outlined"
                                    required
                                    onChange={handleInputChange}
                                    value={formData.productType}
                                    style={{ marginBottom: '15px' }}
                                >
                                    {productType.map((type) => (
                                        <MenuItem key={type} value={type}>
                                            {type}
                                        </MenuItem>
                                    ))}
                                </FormField>
                            </FieldContainer>
                            <FieldContainer>
                                <FormField
                                    select
                                    name="developmentStage"
                                    label="Development Stage"
                                    variant="outlined"
                                    required
                                    onChange={handleInputChange}
                                    value={formData.developmentStage}
                                    style={{ marginBottom: '15px' }}
                                >
                                    {developmentStage.map((stage) => (
                                        <MenuItem key={stage} value={stage}>
                                            {stage}
                                        </MenuItem>
                                    ))}
                                </FormField>
                            </FieldContainer>
                            <FieldContainer>
                                <FormField
                                    label="Competitive and Clinical Advantage"
                                    multiline
                                    rows={3}
                                    name="competitiveAdvantage"
                                    value={formData.competitiveAdvantage}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    required
                                    style={{ marginBottom: '15px' }}
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FormField
                                    select
                                    name="regulatoryApprovals"
                                    label="Regulatory approvals, if any"
                                    variant="outlined"
                                    onChange={handleRegulatoryApprovalChange}
                                    value={formData.regulatoryApprovals}
                                    style={{ marginBottom: '15px' }}
                                >
                                    {regulatoryApprovals.map((regulation) => (
                                        <MenuItem key={regulation} value={regulation}>
                                            {regulation}
                                        </MenuItem>
                                    ))}
                                    <RemoveLinkButton onClick={clearApproval}>Clear</RemoveLinkButton>
                                </FormField>
                            </FieldContainer>
                            {showDropzone && (
                                <FieldContainer>
                                    <FileDropzone onFileChange={handleFileChange} />
                                    {files.map((file, index) => (
                                        <LinkContainer key={index} style={{ marginBottom: '5px' }}>
                                            <LinkText>{file.name}</LinkText>
                                            <RemoveLinkButton onClick={() => handleFileRemove(index)}>X</RemoveLinkButton>
                                        </LinkContainer>
                                    ))}
                                </FieldContainer>
                            )}
                            <FieldContainer>
                                <FormField
                                    label="Applicable Clinical Indications"
                                    multiline // Add this line to make it multi-line
                                    rows={3}
                                    name="clinicalIndications"
                                    value={formData.clinicalIndications}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    required
                                    style={{ marginBottom: '15px', marginTop: '5px' }}
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FormField
                                    label="Applicable Competitors"
                                    type="text"
                                    name="competitors"
                                    value={formData.competitors}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    style={{ marginBottom: '15px' }}
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FormField
                                    label="Target Demographic (If applicable)"
                                    type="text"
                                    name="targetDemographic"
                                    value={formData.targetDemographic}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    style={{ marginBottom: '15px' }}
                                />
                            </FieldContainer>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <FieldContainer>
                                        <FormField
                                            select
                                            label="Currency"
                                            name="currency"
                                            onChange={handleCurrencyChange}
                                            value={formData.currency}
                                            variant="outlined"
                                        >
                                            {currencies.map((curr) => (
                                                <MenuItem key={curr.value} value={curr.value}>
                                                    {curr.label}
                                                </MenuItem>
                                            ))}
                                        </FormField>
                                    </FieldContainer>
                                </Grid>
                                <Grid item xs={4}>
                                    <FieldContainer>
                                        <FormField
                                            label="Min Transfer Price"
                                            type="text"
                                            name="transferPriceMin"
                                            value={formData.transferPriceMin}
                                            onChange={handleInputChange}
                                            variant="outlined"
                                            style={{ marginBottom: '15px', paddingRight: '20px' }}

                                        />
                                    </FieldContainer>
                                </Grid>
                                <Grid item xs={4}>
                                    <FieldContainer>
                                        <FormField
                                            label="Max Transfer Price"
                                            type="text"
                                            name="transferPriceMax"
                                            value={formData.transferPriceMax}
                                            onChange={handleInputChange}
                                            variant="outlined"
                                            style={{ paddingRight: '20px' }}
                                        />
                                    </FieldContainer>
                                </Grid>
                            </Grid>
                            <FieldContainer>
                                <FormField
                                    label="Existing Distribution Channels"
                                    type="text"
                                    name="distributionChannels"
                                    value={formData.distributionChannels}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    style={{ marginBottom: '15px' }}
                                />
                            </FieldContainer>
                            <FieldContainer>
                                <FormField
                                    label="Links to related journal, press article, etc (Press Enter to add)"
                                    type="text"
                                    name="relatedLinkInput"
                                    value={linkInput}
                                    onChange={handleLinkInputChange}
                                    onKeyDown={handleLinkInputKeyDown}
                                    variant="outlined"
                                    style={{ marginBottom: '10px' }}
                                />
                                <div>
                                    {relatedLinks.map(link => {
                                        const absoluteLink = link.startsWith('http://') || link.startsWith('https://') ? link : 'http://' + link;

                                        return (
                                            <LinkContainer key={link} style={{ marginBottom: '10px' }}>
                                                <LinkText href={absoluteLink} target="_blank" rel="noopener noreferrer">{link}</LinkText>
                                                <RemoveLinkButton onClick={() => handleRemoveLink(link)}>X</RemoveLinkButton>
                                            </LinkContainer>
                                        );
                                    })}
                                </div>
                            </FieldContainer>
                            <ActionButton variant="contained" type="submit" style={{ width: '30%', borderRadius: '10px', marginRight: '10px', backgroundColor: "#0086ff", color: "white", marginTop: '5px' }}>
                                Add New Product
                            </ActionButton>
                            {errorMessage && (
                                <Typography variant="body1" color="error">
                                    {errorMessage}
                                </Typography>
                            )}
                        </Form>
                    </div>
                )
                }
            </div >
        </div >
        </Container>
    );
};

export default AddProduct;