import React, { useState, useEffect } from 'react';
import countryRegulations from './regulations';
import Sidebar from './sideBar';
import Select from 'react-select';
import styled from 'styled-components';
import { FormControl, InputLabel, Chip, Button, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import ReactCountryFlag from "react-country-flag";
import { useUser } from './UserContext';

const Container = styled.div`
  background: url('/corners.png') no-repeat center center fixed; 
  background-size: cover; // Ensure that one image covers the full container
`;

const ActionButton = styled(Button)`
    font-family: "aktiv-grotesk", Arial, sans-serif !important; /* Fallback to Arial or sans-serif */
    color: 'white',
    padding: '10px 15px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    background-color: #0086ff; // Changed color here

  &:hover {
    background: linear-gradient(45deg, #0075db 30%, #0075db 90%); // Changed color here
  }

  &:active {
    transform: scale(0.98);
    transition: all .1s ease-in-out;
  }
  && {
    text-transform: Capitalize;
  }
`;

const MedikanaIcon = styled.img`
    width: 25px;      // Adjust as necessary
    height: 25px;     // Adjust as necessary
    margin-right: 10px;
`;

const MenuItem = styled.div`
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-decoration: none; // Remove default link styling
    color: inherit; // Inherit the text color
    justify-content: space-between;
`;

const ComparisonTab = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  width: 25%; // adjust as needed
  height: 100%;
  background-color: white;
  box-shadow: -5px 0 5px -5px rgba(0,0,0,0.5);
  transform: translateX(${props => props.isOpen ? '0' : '100%'});
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  padding: 20px;
`;

const SectionTitle = styled.h3`
  color: #0056b3; // Example color
  margin-bottom: 0.5rem;
`;

const SectionContent = styled.div`
  background-color: #f2f2f2; // Light grey background
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 1rem;
`;

const SectionSeparator = styled.hr`
  border: 0;
  height: 1px;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0));
  margin: 1rem 0;
`;


const RegulationBox = ({ countryInfo, deviceType, deviceClass, selectedCountries, setSelectedCountries, isForComparison }) => {
    const [detailsOpen, setDetailsOpen] = useState(false);
    const countryCode = getCountryCode(countryInfo.country);
    const navigate = useNavigate();

    const formatTitle = (str) => {
        return str
            // Split based on uppercase letters
            .split(/(?=[A-Z])/)
            // Capitalize the first letter of each word
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            // Join the words with spaces
            .join(' ');
    };

    // Filter function to determine if the requirement applies to the selected device type and class
    const doesApplyToSelection = (requirement) => {
        let applicableClasses = [];

        // Special handling for Colombia Class IIa and IIb
        if (countryInfo.country === "Colombia") {
            if (deviceClass === "Class II") {
                applicableClasses = ["Class II", "Class IIa", "Class IIb"];
            } else if (deviceClass === "Class IIa" || deviceClass === "Class IIb") {
                applicableClasses = ["Class IIa", "Class IIb"];
            } else {
                applicableClasses = [deviceClass];
            }
        } else {
            applicableClasses = [deviceClass];
        }

        // Guard against undefined deviceType and deviceClass
        if (!deviceType || !deviceClass) {
            //console.log('deviceType or deviceClass is undefined');
            return false;
        }

        // Check if requirement.appliesTo is a valid object
        if (!requirement.appliesTo || typeof requirement.appliesTo !== 'object') {
            //console.log('requirement.appliesTo is not a valid object');
            return false;
        }

        // Rest of the logic as before
        if (deviceType && !deviceClass) {
            if (!requirement.appliesTo[deviceType]) {
                //console.log(`No requirements found for device type ${deviceType}`);
                return false;
            }
            let appliesToType = requirement.appliesTo[deviceType].length > 0;
            //console.log(`Requirement applies to device type ${deviceType}:`, appliesToType);
            return appliesToType;
        }

        return requirement.appliesTo[deviceType]?.some(c => applicableClasses.includes(c));
    };

    const getCost = (countryInfo, deviceType, deviceClass) => {
        // Handling for Colombia with separate categories
        if (countryInfo.country === "Colombia") {
            // Check if the cost entry exists for the given deviceType and deviceClass
            if (countryInfo.cost[deviceType] && countryInfo.cost[deviceType][deviceClass]) {
                return countryInfo.cost[deviceType][deviceClass];
            } else {
                // For Colombia, 'Class II' under 'Medical Device' might need special handling
                if (deviceType === "Medical Device" && deviceClass === "Class II") {
                    return countryInfo.cost[deviceType]["Class IIa"]; // Defaulting to "Class IIa" for "Medical Device"
                } else {
                    return 'Cost information not available';
                }
            }
        } else {
            // Handling for other countries
            return countryInfo.cost && countryInfo.cost[deviceClass]
                ? countryInfo.cost[deviceClass]
                : 'Cost information not available';
        }
    };

    const isCountrySelected = selectedCountries.includes(countryInfo.country);
    const handleSelectCountry = () => {
        if (isCountrySelected) {
            setSelectedCountries(selectedCountries.filter(country => country !== countryInfo.country));
        } else {
            setSelectedCountries([...selectedCountries, countryInfo.country]);
        }
    };

    const toggleDetails = () => {
        setDetailsOpen(!detailsOpen);
    };

    const handleUploadPageNavigation = () => {
        navigate(`/upload/${countryInfo.country}`);
    };


    // Get all requirements that apply to the selected device type and class
    const boxStyle = {
        border: '1px solid #ddd',
        borderRadius: '8px',
        padding: '20px',
        backgroundColor: '#f9f9f9',
        display: 'flex',
        flexDirection: 'column',
        width: '100%' // Ensure the box takes the full width
    };

    const summaryStyle = {
        display: 'flex', // Use flexbox layout
        alignItems: 'center', // Align items vertically
        justifyContent: 'space-between', // Spread children across the full width
        width: '100%', // Ensure summary takes the full width of the box
        cursor: 'pointer',
    };


    const buttonStyle = {
        color: 'white',
        padding: '10px 15px',
        border: 'none',
        borderRadius: '6px',
        cursor: 'pointer',
        transition: 'background-color 0.2s',
        backgroundColor: "#0086ff",
        color: 'white',

        // Remove marginLeft to allow flexbox to position the button
    };

    const titleStyle = {
        display: 'flex', // Use flexbox layout
        alignItems: 'center', // Align items vertically
        margin: '0', // Remove margin from the title (h2) if necessary
        // Any other styles you want to apply to the title container
    };

    const markerStyle = {
        marginRight: '5px', // Adjust space between marker and title as needed
        transform: detailsOpen ? 'rotate(0deg)' : 'rotate(-90deg)', // Rotate the marker when details are open
        transition: 'transform 0.3s', // Smooth transition for rotation
        display: 'inline-block' // Required for transform to work
    };

    const comparisonBoxStyle = {
        margin: '5px 0', // Add vertical margin

        // You can add more styles as needed
    };


    if (isForComparison) {
        return (
            <details style={{ ...comparisonBoxStyle, width: '90%', border: '1px solid #ddd', borderRadius: '8px', padding: '20px', backgroundColor: '#f9f9f9', display: 'flex', flexDirection: 'column' }}>
                <summary style={summaryStyle}>
                    <div style={titleStyle}>
                        {countryCode && <ReactCountryFlag countryCode={countryCode} svg style={{ width: '1.5em', height: '1.5em', marginRight: '10px' }} />}
                        <h2 style={{ margin: 0 }}>{countryInfo.country}</h2>
                    </div>
                    <ActionButton style={buttonStyle} onClick={handleSelectCountry}>
                        {selectedCountries.includes(countryInfo.country) ? 'Remove' : 'Add to Compare'}
                    </ActionButton>
                </summary>
                {/* Optionally, add more content or styling here */}
            </details>
        );
    }

    return (
        <details style={{ border: '1px solid #ddd', borderRadius: '8px', margin: '10px 0', padding: '20px', backgroundColor: '#f9f9f9', display: 'flex', flexDirection: 'column', width: '100%' }} onToggle={toggleDetails}>
            <summary style={summaryStyle}>
                <div style={titleStyle}>
                    {countryCode && <ReactCountryFlag countryCode={countryCode} svg style={{ width: '1.5em', height: '1.5em', marginRight: '10px' }} />}
                    <span style={markerStyle}>&#9662;</span>
                    <h2 style={{ margin: 0 }} onClick={handleUploadPageNavigation}>{countryInfo.country}</h2>
                </div>
                <ActionButton style={buttonStyle} onClick={handleSelectCountry}>
                    {selectedCountries.includes(countryInfo.country) ? 'Remove' : 'Add to Compare'}
                </ActionButton>
            </summary>
            <SectionTitle>Requirements for {deviceType || 'All Devices'}, {deviceClass || 'All Classes'}:</SectionTitle>
            <SectionContent>
                {Object.entries(countryInfo.requirements).map(([categoryName, requirements], catIndex) => (
                    <div key={catIndex}>
                        <h4>{formatTitle(categoryName)}</h4>
                        <ul>
                            {Object.entries(requirements).filter(([reqName, reqDetails]) => doesApplyToSelection(reqDetails))
                                .map(([reqName], reqIndex) => <li key={reqIndex}>{reqName}</li>)}
                        </ul>
                    </div>
                ))}
            </SectionContent>
            <SectionSeparator />
            <SectionTitle>Timeline</SectionTitle>
            <SectionContent>
                <p>{countryInfo.timeline}</p>
            </SectionContent>
            <SectionSeparator />

            <SectionTitle>Cost</SectionTitle>
            <SectionContent>
                <p>{deviceType && deviceClass ? getCost(countryInfo, deviceType, deviceClass) : 'Please select a device type and class'}</p>
            </SectionContent>
        </details>
    );
};

const getCountryCode = (countryName) => {
    // This is a simple example; you may need a more comprehensive list or a better mapping strategy
    const countryCodes = {
        "Argentina": "AR",
        "Brasil": "BR",
        "Chile": "CL",
        "Colombia": "CO",
        "Costa Rica": "CR",
        "Guatemala": "GT",
        "Ecuador": "EC",
        "Mexico": "MX",
        "Panama": "PA",
        "Peru": "PE",
        // ... add other countries as needed ...
    };


    return countryCodes[countryName];
};


const RegulatorySummaries = () => {
    const [selectedDeviceType, setSelectedDeviceType] = useState(null);
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedCountries, setSelectedCountries] = useState([]);
    const [isTabOpen, setIsTabOpen] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (selectedCountries.length > 0) {
            setIsTabOpen(true);
        } else {
            setIsTabOpen(false);
        }
    }, [selectedCountries]);

    const closeTab = () => {
        setIsTabOpen(false);
        setSelectedCountries([]); // This line will reset the selectedCountries array to empty
    };

    //console.log("Selected Device Type:", selectedDeviceType);
    //console.log("Selected Class:", selectedClass);

    // Transform deviceTypes into the format expected by react-select
    const deviceTypesOptions = Object.keys(countryRegulations[0].deviceTypes).map(type => ({
        value: type,
        label: type
    }));

    // Conditional logic to get class options based on selected device type
    const classOptions = selectedDeviceType
        ? countryRegulations[0].deviceTypes[selectedDeviceType.value].Classes.map(cls => ({
            label: cls,
            value: cls
        }))
        : [];

    const handleNavigateToComparison = () => {
        //console.log('Selected countries before navigation:', selectedCountries);
        navigate('/regulatoryComparison', { state: { selectedCountries } });
    }

    return (
        <Container style={{ width: '100%', height: '100%' }}>
        <div style={{ display: 'flex', minHeight: '100vh' }}>
            <Sidebar />
            <div style={{ flex: 1, padding: '20px' }}>
                <h1>Latin America Regulatory Summaries</h1>
                <div style={{ marginBottom: '20px', width: '80%' }}>
                    <div style={{ paddingBottom: '15px' }}>
                        <label htmlFor="deviceType" >Device Type:</label>
                        <Select
                            id="deviceType"
                            value={selectedDeviceType}
                            onChange={setSelectedDeviceType}
                            options={deviceTypesOptions}
                            isClearable
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    height: '30px',
                                    minHeight: '40px',
                                    lineHeight: '30px',
                                }),
                            }}
                        />
                    </div>
                    <div>
                        <label htmlFor="classType" >Class:</label>
                        <Select
                            id="classType"
                            value={selectedClass}
                            onChange={setSelectedClass}
                            options={classOptions}
                            isClearable
                            isDisabled={!selectedDeviceType}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    height: '30px',
                                    minHeight: '40px',
                                    lineHeight: '30px',
                                }),
                            }}
                        />
                    </div>
                </div>
                <div style={{ width: '100%' }}>
                    {countryRegulations.map((countryInfo, index) => (
                        <RegulationBox
                            key={index}
                            countryInfo={countryInfo}
                            deviceType={selectedDeviceType ? selectedDeviceType.value : ''}
                            deviceClass={selectedClass ? selectedClass.value : ''}
                            selectedCountries={selectedCountries} // Add this line
                            setSelectedCountries={setSelectedCountries}
                        />
                    ))}
                </div>
            </div>
            <div style={{ flex: 1, marginRight: '60px' }}>
                {isTabOpen && (
                    <ComparisonTab isOpen={isTabOpen}>
                        <MenuItem style={{ marginBottom: '25px' }}>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                <MedikanaIcon src="/medikanaLogoNoName.png" alt="Settings Icon" />
                                <h2 style={{ margin: '0 0 0 5px' }}>Comparison View</h2>
                            </div>
                            <button style={{ borderRadius: '10px', backgroundColor: "#EA4335", color: 'white', border: '2px solid #EA4335', outline: 'none', cursor: 'pointer', padding: '0 8px' }} onClick={closeTab}>x</button>
                        </MenuItem>
                        {selectedCountries.map(country => {
                            const countryInfo = countryRegulations.find(c => c.country === country);
                            return (
                                <RegulationBox
                                    key={country}
                                    countryInfo={countryInfo}
                                    isForComparison={true}
                                    selectedCountries={selectedCountries}
                                    setSelectedCountries={setSelectedCountries}
                                />
                            );
                        })}
                        <div style={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}>
                            <ActionButton variant="contained" type="submit" style={{ borderRadius: '6px', backgroundColor: "#0086ff", color: 'white' }} onClick={handleNavigateToComparison}>
                                Show Detailed Comparison
                            </ActionButton>
                        </div>
                    </ComparisonTab>
                )}
            </div>
        </div>
        </Container>
    );
};

export default RegulatorySummaries;
