const regulationsPanama = {
    country: "Panama",
    countryCode: 'PA',
    deviceTypes: {
        "Medical Device": {
            "Classes": ["Class I", "Class II", "Class III", "Class IV"]
        },
        "In Vitro Device": {
            "Classes": ["Class I", "Class II", "Class III", "Class IV"]
        }
    },
    timeline: "The National Directorate of Medical Devices will process requests for the Technical Criterion Certificate within a maximum of 30 working days. The provider may be asked for additional information or corrections about the product, and they have up to 60 days to provide this. The Directorate must also respond to the initial request within 30 days. An extension of time can be requested by either the evaluator or the applicant.",
    cost: {
        "Class I": "$350",
        "Class II": "$350",
        "Class III": "$350",
        "Class IV": "$350",
    },

    requirements: {
        sanitaryRegistration: {
            "Proof of Operating Authorization for Manufacturer and/or Importer Company. (Operating License)": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Application through a Lawyer. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Technical Studies and Analytical Tests. Analysis method.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Certificate of Analysis. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Free Sale Certificate. Issued by the health authority of the country of origin or type World Health Organization.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Good Manufacturing Practices Certificate of the laboratory, issued by the health authority of the country of origin.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Clinical Studies on use to Demonstrate Safety and Effectiveness. Only for innovative products, new indications, and those regulated by the Health Authority.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Labels. ": { //and packaging.
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Written Study of the Product. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Samples. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Product Formula. Qualiquantitative Formula.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Stability Certificate. Stability Studies for products applying for the first time.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Product Specifications. Finished product specifications.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Prior Control. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "List of Standards Used. Analytical Standards when required.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Endorsement of a Suitable Pharmacist. This person may be the manager.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Endorsement of the National College of Pharmacists. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
        },
        technicalAnalysis: {
            "Labels. Simple color (legible) copy of the product labeling or the proposed labeling. (Does not apply to Biomedical Equipment).": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Instruction Manual. See Article 38 for the information it must contain.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Certificate of Good Manufacturing Practices. Or other quality certifications issued by recognized entities.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Application Form for Certificate of Technical Criterion. Application should be duly filled out and signed by the Legal Representative or Legal Attorney duly empowered legally to process through notarized power or power registered in the Public Registry.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Identification of the Owner or Legal Representative of the Entity. Simple copy of the ID or passport of the Legal Representative or his legal attorney.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Medical Device Operation License. Simple copy.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Public Registry Certificate. Simple copy.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            }, 
            "Supplier Certificate. Simple copy.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Current Technical Data Sheets. Downloaded from the website of the National Interinstitutional Technical Committee corresponding to the product(s) requested. Simple copy.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Technical Literature/Information. Copy of the manufacturer's documents with technical literature of the Medical Device. The supplier must highlight or mark in the technical literature the technical specifications that allow the evaluator to verify that the product complies with the descriptions stated in the Technical Data Sheet of the National Interinstitutional Technical Committee. Technical literature may be presented in electronic or digital format.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Letter from the Manufacturer. Manufacturer's commitment letter.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Free Sale Certificate. Issued by the competent authority of the country of origin. If there is more than one country of origin, the Free Sale Certificate from all countries must be presented, except for products manufactured in the Republic of Panama.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Color Photos of the Medica Device. Include photos where the characteristics of the medical device and the product packaging can be verified. If necessary, a sample of the product may be requested (does not apply to biomedical equipment)": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Sterilization Process. For sterile products, provide the sterilization method used. For reusable devices, sterilization and/or cleaning method to be used (when applicable). ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            // "For reusable devices, the sterilization and/or cleaning method to be used (when applicable).": {
            //     appliesTo: {
            //         "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
            //         "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
            //     }
            // }, merged with above req
            "Clinical Studies on use to Demonstrate Safety and Effectiveness. Provide a summary of the study (does not apply to biomedical equipment).": {
                appliesTo: {
                    "Medical Device": [""],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Description of the Manufacturing Process. Description of manufacturing and packaging materials (does not apply to biomedical equipment).": {
                appliesTo: {
                    "Medical Device": [""],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Risk Management and Safety Compliance Documentation. Risk analysis and risk reduction safety measures": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Traceability Protocols. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Clinical Studies on use to Demonstrate Safety and Effectiveness. Detailed information of clinical and preclinical safety and effectiveness studies.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Current ISO 13485 Certificate. Optional.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            }, 

        }
    }
};

export default regulationsPanama;