import React from 'react';
import { BrowserRouter, Routes, Route, Switch} from 'react-router-dom';
import RoleSelection from './RoleSelection';
import DistributorSignup from './DistributorSignup';
import DistributorSignupDetailed from './DistributorSignupDetailed';
import DistributorSignupDetailed2 from './DistributorSignupDetailed2';
import ManufacturerSignup from './ManufacturerSignupDetailed';
import ManufacturerSignup2 from './ManufacturerSignupDetailed2';
import ManufacturerSignup3 from './ManufacturerSignupDetailed3';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import LoginPage from './LoginPage';
import Dashboard from './Dashboard';
import PasswordReset from './forgotPassword';
import CompanyPage from './CompanyPage'
import ProductPage from './ProductPage';
import EmailVerification from './EmailVerification';
import RegulatorySummaries from './RegulatorySummaries';
import DeviceClassificationForm from './deviceClassificationForm';
import RegulatoryComparison from './regulatoryComparison';
import FileUploadPage from './FileUploadPage';
import DatabaseOverviewPanel from './OverviewPanel'
import ConfirmSignUpPage from './confirmationPage';
import { UserProvider } from './UserContext';
import AddProduct from './AddProduct';



Amplify.configure(awsconfig);


function App() {
  return (
    <UserProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<DistributorSignup />} />
        <Route path="/confirmationPage" element={<ConfirmSignUpPage />} />
        <Route path="/Distributor" element={<DistributorSignupDetailed/>} />
        <Route path="/Distributor2" element={<DistributorSignupDetailed2/>} />
        <Route path="/ManufacturerSignup" element={<ManufacturerSignup />} />
        <Route path="/ManufacturerSignup2" element={<ManufacturerSignup2 />} />
        <Route path="/ManufacturerSignup3" element={<ManufacturerSignup3 />} />
        <Route path="/Success" element={<RoleSelection />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/Dashboard" element={<Dashboard />} />
        <Route path= "/company" element={<CompanyPage/>}/>
        <Route path="/forgotPassword" element={<PasswordReset/>} />
        <Route path="/regulatorySummaries" element={<RegulatorySummaries/>} />
        <Route path="/deviceClassificationForm" element={<DeviceClassificationForm/>} />
        <Route path="regulatoryComparison" element={<RegulatoryComparison/>}/>
        <Route path="/upload/:country" element={<FileUploadPage />} />
        <Route path="/overviewPanel" element={<DatabaseOverviewPanel />} />
        <Route path="/AddProduct" element={<AddProduct />} />
        <Route path="/ProductPage" element={<ProductPage />} />
        <Route path="/ProductPage/:productId" element={<ProductPage/>} />

      </Routes>
    </BrowserRouter>
    </UserProvider>
  );
}

export default App;

