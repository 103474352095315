import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { API, graphqlOperation } from 'aws-amplify';
import { createTodo, createManufacturer, createProducts } from './graphql/mutations';
import { Button, TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Typography, Popover } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { createGlobalStyle } from 'styled-components';

//import { ReactComponent as MedikanaWorld } from './medikana-world.svg';

const companyID = uuidv4();


const Container = styled.div`
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  height: calc(100vh - 70px);
  width: 100vw; // Use the full width of the viewport
  margin: 0;
  border-radius: 0;
  background: url('/background.png') no-repeat center center fixed; 
  background-size: cover; // Ensure that one image covers the full container
  overflow-y: auto; 
  overflow-x: hidden;
  padding-bottom: 40px
`;


const Logo = styled.img`
  width: 350px;
  height: 130px;
  margin-bottom: 1rem;
  margin-right: 1.5rem;
  @media (max-width: 600px) {
    width: 300px; // Smaller width for smaller screens
    height: auto; // Maintain aspect ratio
  }

  @media (max-width: 480px) {
    width: 240px; // Even smaller width for mobile screens
    height: auto; // Maintain aspect ratio
  }

`;

const WelcomeMessage = styled(Typography)`
  margin-bottom: 2rem;
  color:#000000;
  font-family: "aktiv-grotesk", Arial, sans-serif !important; /* Fallback to Arial or sans-serif */
  font-weight: 900;
`;

const Form = styled.form`
  flex-wrap: wrap;
  justify-content: flex-start; // Changed from space-between to flex-start
  max-width: 500px;
  min-width: 500px
  width: 100%;
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 10px 25px -5px rgba(0, 0, 0, 0.2);
  margin-bottom: 50px;
  @media (max-width: 600px) {
    max-width: 90%; // Allow the form to scale down
    padding: 1rem;
  }

  @media (max-width: 480px) {
    max-height: calc(100vh - 80px);// Further scale down the form on very small screens
    padding: 1rem;
  }
  
`;

const FormField = styled(TextField)`
  margin-bottom: 1rem;
  width: 100%; // Changed from 51% to 100%
  border-radius: 50px;

  &:focus {
    border-color: #000000;
    box-shadow: 0 0 1.25rem rgba(74, 86, 226, .5);
    transition: box-shadow .2s ease, border-color .2s ease;

  }

`;

const FieldContainer = styled.div`
  width: 100%; // adjust the width as needed
`;

const ActionButton = styled(Button)`
  font-family: "aktiv-grotesk", Arial, sans-serif !important; /* Fallback to Arial or sans-serif */
  margin-top: 1rem;
  color: white;
  

  &:hover {
    background: linear-gradient(45deg, #5982DB 30%, #5982DB 90%);
  }

  &:active {
    transform: scale(0.98);
    transition: all .1s ease-in-out;
  }
  && {
    text-transform: Capitalize;
  }
`;

const radioStyle = {
    color: '#a85dff',
};

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    //overflow: hidden; // Prevent horizontal scrolling
  }

  // Your other styles...
`;



const DistributorSignup = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        companyName: '',
        userType: '',
    });
    const [confirmPass, setConfirmPass] = useState({
        password: '',
        confirmPassword: ''
    })
    const [distributorId, setDistributorId] = useState(null);
    const [manufacturerId, setManufacturerId] = useState(null);
    const [mCompanyId, setmCompanyId] = useState(null);
    const [mProductId, setmProductId] = useState(null);

    const [errorMessage, setErrorMessage] = useState('');
    const [showPasswordRequirements, setShowPasswordRequirements] = useState(false);
    //const { sendVerificationEmail } = require('./sendEmail');

    const open = showPasswordRequirements;

    const [passwordRequirements, setPasswordRequirements] = useState({
        hasUpperCase: false,
        hasLowerCase: false,
        hasNumber: false,
        hasMinimumLength: false,
    });

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // This variable will be true only if all password requirements are satisfied
    const allPasswordReqsMet = passwordRequirements.hasUpperCase && passwordRequirements.hasLowerCase && passwordRequirements.hasNumber && passwordRequirements.hasMinimumLength;


    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleInputChange = (event) => {
        const { name, value } = event.target;

        if (name === 'password' || name === 'confirmPassword') {
            setConfirmPass({ ...confirmPass, [name]: value }); // Update password and confirmPassword here


            if (name === 'password') {
                const hasUpperCase = /[A-Z]/.test(value);
                const hasLowerCase = /[a-z]/.test(value);
                const hasNumber = /\d/.test(value);
                const hasMinimumLength = value.length >= 8;

                setPasswordRequirements({
                    hasUpperCase,
                    hasLowerCase,
                    hasNumber,
                    hasMinimumLength,
                });
            }
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    const savePartialData = async (formData) => {
        try {
            const timestamp = Math.floor(Date.now() / 1000);
            let extendedFormData;
    
            if (formData.userType === 'Manufacturer') {
                const productID = "PROD-" + timestamp + "-" + Math.floor(Math.random() * 1000); 
                const companyID = "COMP-" + timestamp;
    
                extendedFormData = { ...formData, createdAtUnix: timestamp, productID, companyID };
    
                const manufacturerResult = await API.graphql(graphqlOperation(createManufacturer, { input: extendedFormData }));
                localStorage.setItem('manufacturerId', manufacturerResult.data.createManufacturer.id);
                localStorage.setItem('manufCompanyId', companyID);
                localStorage.setItem('manufProductId', productID);
    
                //console.log("Manufacturer Result:", manufacturerResult);
            } else {
                extendedFormData = { ...formData, createdAtUnix: timestamp };
    
                const distributorResult = await API.graphql(graphqlOperation(createTodo, { input: extendedFormData }));
                // Assuming distributor ID is returned in a similar manner
                const distributorId = distributorResult.data.createTodo.id;
                localStorage.setItem('distributorId', distributorId);
    
               // console.log("Distributor Result:", distributorResult);
            }
    
        } catch (error) {
            console.error('Error in savePartialData:', error);
            setErrorMessage('Error saving partial data. Please try again.');
        }
    };
    


    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();

        //console.log("Form Data:", formData);

        const { name, email, companyName, userType } = formData;
        const { password, confirmPassword } = confirmPass; // take confirmPassword from the confirmPass state
        if (password !== confirmPassword) {
            setErrorMessage("Passwords do not match. Please try again.");
            return;
        }

        const hasUpperCase = /[A-Z]/.test(password);
        const hasLowerCase = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        const hasMinimumLength = password.length >= 8;

        if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasMinimumLength) {
            // Inform user about the password requirements
            setErrorMessage("Password requirements not met. Please try again.");
            return;
        }
        try {
            // Sign up user using AWS Amplify
            const { user } = await Auth.signUp({
                username: email,
                password,
                attributes: {
                    name,   // Optional
                    ...(userType === 'Manufacturer' && { 'custom:userType': 'manufacturer' }),
                },
            });
            
            await savePartialData(formData);

            if (formData.userType === 'Manufacturer') {
                const manufacturerId = localStorage.getItem('manufacturerId');
                const companyID = localStorage.getItem('manufCompanyId');
                const productID = localStorage.getItem('manufProductId');
                // Define the product data including companyName
                const productData = {
                    id: manufacturerId,
                    productId: productID, // If productId is different from the manufacturerId
                    companyId: companyID,
                    companyName: formData.companyName,
                };

                // Make an API call to save the product data
                const productCreationResponse = await API.graphql(graphqlOperation(createProducts, { input: productData }));
                //console.log('Product created successfully:', productCreationResponse);
            }

            // Navigate based on userType
            if (formData.userType === 'Distributor') {
                navigate('/distributor');
            } else {
                //navigate('/success')
                navigate('/ManufacturerSignup');
            }

        } catch (error) {
            if (error.code === "UsernameExistsException") {
                setErrorMessage('Account already exists. Please login instead.');
            } else {
                setErrorMessage('Error signing up. Please try again.', error);
            }
        }
    };

    return (
        <div>
            <GlobalStyle />
            <Container>
                <a href="https://medikana.io">
                    <Logo src="/medikanaLogoWithName.png" alt="Logo"></Logo>
                </a>
                <Form onSubmit={handleSubmit}>
                    <WelcomeMessage
                        variant="h4"
                        style={{
                            marginBottom: '15px',
                            fontSize: window.innerWidth <= 600 ? '23px' : '28px',
                            color: "#000000"
                        }}
                    >
                        Let’s create your account.
                    </WelcomeMessage>

                    <FieldContainer>
                        <FormField name="name" label="Name" variant="outlined" required onChange={handleInputChange} value={formData.name} style={{ marginBottom: '15px' }} />
                        <FormField name="email" label="Email" variant="outlined" required onChange={handleInputChange} value={formData.email} style={{ marginBottom: '15px' }} />
                        <FormField
                            type="password"
                            name="password"
                            label="Password"
                            variant="outlined"
                            required
                            onChange={handleInputChange}
                            value={confirmPass.password} // use confirmPass.password
                            style={{ marginBottom: '15px' }}
                        />
                        {!allPasswordReqsMet && confirmPass.password && (
                            <div style={{
                                fontSize: '12px',
                                marginLeft: '15px',
                                width: '250px',
                                flex: 'none',
                                marginBottom: '25px',
                            }}>
                                <p style={{ color: passwordRequirements.hasUpperCase ? 'green' : 'red' }}>{passwordRequirements.hasUpperCase ? '✔' : '✖'} Must contain at least one uppercase letter</p>
                                <p style={{ color: passwordRequirements.hasLowerCase ? 'green' : 'red' }}>{passwordRequirements.hasLowerCase ? '✔' : '✖'} Must contain at least one lowercase letter</p>
                                <p style={{ color: passwordRequirements.hasNumber ? 'green' : 'red' }}>{passwordRequirements.hasNumber ? '✔' : '✖'} Must contain at least one number</p>
                                <p style={{ color: passwordRequirements.hasMinimumLength ? 'green' : 'red' }}>{passwordRequirements.hasMinimumLength ? '✔' : '✖'} Must be at least 8 characters long</p>
                            </div>
                        )}
                        <FormField
                            type="password"
                            name="confirmPassword"
                            label="Confirm Password"
                            variant="outlined"
                            required
                            onChange={handleInputChange}
                            value={confirmPass.confirmPassword}
                            style={{ marginBottom: '15px' }}
                        />
                        <FormField name="companyName" label="Company Name" variant="outlined" required onChange={handleInputChange} value={formData.companyName} style={{ marginBottom: '15px' }} />
                        <FormControl component="fieldset" style={{ marginBottom: '15px' }}>
                            <FormLabel component="legend" >User Type</FormLabel>
                            <RadioGroup row aria-label="userType" name="userType" required value={formData.userType} onChange={handleInputChange} >
                                <FormControlLabel value="Manufacturer" control={<Radio style={radioStyle} />} label="Manufacturer" />
                                <FormControlLabel value="Distributor" control={<Radio style={radioStyle} />} label="Distributor" />
                            </RadioGroup>
                        </FormControl>
                    </FieldContainer>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <ActionButton variant="contained" type="submit" style={{ width: '30%', borderRadius: '10px', marginRight: '10px', backgroundColor: "#0086ff", color: 'white' }}>
                            Continue
                        </ActionButton>
                        <a
                            href="/login"
                            onClick={(e) => { e.preventDefault(); navigate('/login'); }}
                            style={{ textDecoration: 'underline', color: '#0086ff', cursor: 'pointer' }}
                        >
                            Login Instead?
                        </a>
                    </div>
                    {errorMessage && (
                        <Typography variant="body1" color="error" style={{ marginTop: '10px' }}>
                            {errorMessage}
                        </Typography>
                    )}
                </Form>
            </Container>
        </div>


    );
};

export default DistributorSignup;
