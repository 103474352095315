const regulationsArgentina = {
    country: "Argentina",
    countryCode: 'AR',
    deviceTypes: {
        "Medical Device": {
            "Classes": ["Class I", "Class II", "Class III", "Class IV"]
        },
        "In Vitro Device": {
            "Classes": ["Class I", "Class II", "Class III", "Class IV"]
        }
    },
    timeline: "The maximum period is 180 days for the evaluation of the documentation and communication to the interested party.  5 years of validity From the date of issuance. Applications for Revalidation of the registration of a medical product must be submitted within 90 (ninety) days prior to the expiration of the said 5-year term.",
    cost: {
        "Class I": "$153.53",
        "Class II": "$197.73",
        "Class III": "$262.30",
        "Class IV": "$366.46",
    },

    requirements: {
        sanitaryRegistration: {
            "Proof of Payment of Fees.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Certification of Conformity Assessment. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Manufacturer or Importer Information. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Labels. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Instruction Manual.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Certificate of Good Manufacturing Practices. " : {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Proof of Operating Authorization for Manufacturer and/or Importer Company. Copy of the provision and certificate. According to ANMAT Disposition No. 2319/02 (o.t. 2004)": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Compliance Tests/Proof. These should meet requirements set by the specific MERCOSUR technical regulations applicable to the product to be registered, when applicable, issued by official laboratories, or private ones accredited by the Argentine Accreditation Body created by Decree No. 1474/94 and its complementary norms.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Sterilization Process. Validation reports or calibration of sterile products or with a measuring function.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Risk Management and Safety Compliance Documentation. Risk management report (According to ISO 14971 standard and authenticated copy of the complete test reports that support the report on Essential Requirements for Safety and Efficacy).": {
                appliesTo: {
                    "Medical Device": ["Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class II", "Class III", "Class IV"],
                }
            },
            "Commercial History. Including a list of countries where the product is marketed, adverse events, product-related market withdrawals, and measures taken, if applicable, also indicating the date up to which the history was considered.": {
                appliesTo: {
                    "Medical Device": ["Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class II", "Class III", "Class IV"],
                }
            },
            "Authenticated copy of the Registration Certificate. Granted by the competent authority in the country where the medical product is manufactured, with an issuance date not older than 24 months prior to the filing of the dossier (For importers).": { //Only Argentina, checked Nov 29
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Letter from the Manufacturer. Letter commits to inform the importer who markets their MP in the recipient country, of market withdrawals and field actions carried out on the marketed products (For importers).": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
        },
        technicalReport: {
            "Detailed Product Description. Including the principles of its operation and action, its content or composition, when applicable, as well as a list of accessories intended to be part of the product.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Indication, Purpose, or Intended Use. As specified by the manufacturer.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Precautions and Restrictions. Warnings, special care, and clarifications regarding the use of the medical product, as well as its storage and transportation.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Forms of Presentation of the Medical Product.": { 
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Manufacturing Process Flow Chart. Flow chart containing the stages of the manufacturing process until obtaining the finished product.": {
                appliesTo: {
                    "Medical Device": ["Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class II", "Class III", "Class IV"],
                }
            },
            "Efficacy and Safety Information. In accordance with ANVISA regulation stipulating the Essential Requirements for Efficacy and Safety of Medical Products. If this description does not prove the efficacy and safety of the product, ANVISA will request a clinical investigation of the product.": {
                appliesTo: {
                    "Medical Device": ["Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class II", "Class III", "Class IV"],
                }
            },
            // ... additional nested items under sanitaryRegistration  
        }
    }
};

export default regulationsArgentina;