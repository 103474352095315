import regulationsCostaRica from './regulationsCostaRica';
import regulationsBrazil from './regulationsBrazil';
import regulationsArgentina from './regulationsArgentina';
import regulationsChile from './regulationsChile';
import regulationsEcuador from './regulationsEcuador';
import regulationsColombia from './regulationsColombia';
import regulationsGuatemala from './regulationsGuatemala';
import regulationsMexico from './regulationsMexico';
import regulationsPanama from './regulationsPanama';
import regulationsPeru from './regulationsPeru';

const countryRegulations = [
    regulationsArgentina,
    regulationsBrazil,
    regulationsChile,
    regulationsColombia,
    regulationsCostaRica,
    regulationsGuatemala,
    regulationsEcuador,
    regulationsMexico, 
    regulationsPanama,
    regulationsPeru,
    // ... add other countries
  ];
  
  export default countryRegulations;