//Not included in the site
import React, { useState, useEffect, useRef } from 'react';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import styled from 'styled-components';
import { getManufacturer, getTodo } from './graphql/queries'; // adjust your path
import { updateTodo, updateManufacturer } from './graphql/mutations'; // use createDistributor mutation
import { FormControl, InputLabel, MenuItem, Chip, Button, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { Storage } from 'aws-amplify';
import Select from 'react-select';
import Sidebar from './sideBar';


const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #ffffff;
  margin: 0;
  padding-top: 0px;
  min-height: 100vh;
  width: 100%;
  

`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding: 40px;
  overflow-x: hidden; 
`;


const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ddd;
  margin-top: 5px
  overflow-x: hidden;
  white-space: wrap;  // Content doesn't wrap to next line
  text-overflow: ellipsis;  // Show ellipsis when content overflows

`;

const ActionButton = styled(Button)`
  font-family: "aktiv-grotesk" !important;
  margin-top: 1rem;
  border-radius: 90px;
  background-color: #0086ff; // Changed color here
  color: white;

  &:hover {
    background: linear-gradient(45deg, #0075db 30%, #0075db 90%); // Changed color here
  }

  &:active {
    transform: scale(0.98);
    transition: all .1s ease-in-out;
  }
  && {
    text-transform: Capitalize;
  }
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
`;

const StyledLine = styled.div`
  height: 1px;
  background-color: #f1f2fa;  // or another color that matches the design
  margin: 8px ;  // add some margin for spacing
  width: 90%;  // or adjust to fit the desired length
`;

const AccountTitle = styled(Typography)`
  font-size: 1.5em;  
  margin-bottom: 3px;
  font-weight: 300, 
`;

const ProfileTitle = styled(Typography)`
  font-size: 1.2em;
  margin-top: 25px;
  margin-bottom: 30px;
`;

const LogoutButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #0086ff;
  margin: 20px;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`;

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: -10px;  // Adjust this value based on how much you want to move the section up
`;

const distributorFields = [
    { key: 'jobRole', label: 'Job Role' },
    { key: 'phoneNumber', label: 'Phone Number' },
    { key: 'website', label: 'Website' },
    { key: 'companySize', label: 'Company Size' },
    { key: 'foundedYear', label: 'Founded Year' },
    { key: 'companyHQ', label: 'Company HQ' },
    { key: 'territory', label: 'Distribution Territories' },
    { key: 'scope', label: 'Distribution Scope' },
    { key: 'brands', label: 'Brands or products you currently distribute' },
    { key: 'specialties', label: 'Specialties of products you currently distribute' },
    { key: 'desiredSpecialties', label: 'Specialties of products you would like to distribute' },
    { key: 'certificates', label: 'Certificates or Credentials' }
];

const manufacturerFields = [
    { key: 'companyName', label: 'Company Name' },
    { key: 'companyWebsite', label: 'Company Website' },
    { key: 'companyHQ', label: 'Company Headquarters' },
    { key: 'companySize', label: 'Company Size' },
    { key: 'yearFounded', label: 'Year Founded' },
    { key: 'clinicalSegment', label: 'Clinical Segment' },
    { key: 'companyLogo', label: 'Company Logo' },
    
];


const countryCodes = [
    { country: 'Argentina', code: '+54' },
    { country: 'Bolivia', code: '+591' },
    { country: 'Brazil', code: '+55' },
    { country: 'Chile', code: '+56' },
    { country: 'Colombia', code: '+57' },
    { country: 'Costa Rica', code: '+506' },
    { country: 'Cuba', code: '+53' },
    { country: 'Dominican Republic', code: '+1' },
    { country: 'Ecuador', code: '+593' },
    { country: 'El Salvador', code: '+503' },
    { country: 'Guatemala', code: '+502' },
    { country: 'Honduras', code: '+504' },
    { country: 'Mexico', code: '+52' },
    { country: 'Nicaragua', code: '+505' },
    { country: 'Panama', code: '+507' },
    { country: 'Paraguay', code: '+595' },
    { country: 'Peru', code: '+51' },
    { country: 'Puerto Rico', code: '+1' },
    { country: 'Uruguay', code: '+598' },
    { country: 'Venezuela', code: '+58' },
    { country: 'USA', code: '+1' },
];

const latinAmericanCountries = [
    'Argentina', 'Bolivia', 'Brazil', 'Chile', 'Colombia', 'Costa Rica', 'Cuba', 'Dominican Republic',
    'Ecuador', 'El Salvador', 'Guatemala', 'Honduras', 'Mexico', 'Nicaragua', 'Panama', 'Paraguay',
    'Peru', 'Puerto Rico', 'Uruguay', 'Venezuela',
];

const countries = [
    'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua and Barbuda', 'Argentina',
    'Armenia', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados',
    'Belarus', 'Belgium', 'Belize', 'Benin', 'Bhutan', 'Bolivia', 'Bosnia and Herzegovina', 'Botswana',
    'Brazil', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Côte d\'Ivoire', 'Cabo Verde',
    'Cambodia', 'Cameroon', 'Canada', 'Central African Republic', 'Chad', 'Chile', 'China', 'Colombia',
    'Comoros', 'Congo', 'Costa Rica', 'Croatia', 'Cuba', 'Cyprus', 'Czechia', 'Democratic Republic of the Congo',
    'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea',
    'Eritrea', 'Estonia', 'Eswatini', 'Ethiopia', 'Fiji', 'Finland', 'France', 'Gabon', 'Gambia', 'Georgia',
    'Germany', 'Ghana', 'Greece', 'Grenada', 'Guatemala', 'Guinea', 'Guinea-Bissau', 'Guyana', 'Haiti', 'Holy See',
    'Honduras', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Israel', 'Italy',
    'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia',
    'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Madagascar', 'Malawi',
    'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Mauritania', 'Mauritius', 'Mexico', 'Micronesia',
    'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Morocco', 'Mozambique', 'Myanmar', 'Namibia', 'Nauru', 'Nepal',
    'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'North Korea', 'North Macedonia', 'Norway', 'Oman',
    'Pakistan', 'Palau', 'Palestine State', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland',
    'Portugal', 'Qatar', 'Romania', 'Russia', 'Rwanda', 'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Vincent and the Grenadines',
    'Samoa', 'San Marino', 'Sao Tome and Principe', 'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone',
    'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia', 'South Africa', 'South Korea', 'South Sudan',
    'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Sweden', 'Switzerland', 'Syria', 'Tajikistan', 'Tanzania', 'Thailand',
    'Timor-Leste', 'Togo', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Tuvalu', 'Uganda', 'Ukraine',
    'United Arab Emirates', 'United Kingdom', 'United States of America', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Venezuela',
    'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe'
];

const specialtiesList = [
    'Alternative Medicine',
    'Anaesthesia',
    'Bariatric Equipment',
    'Cardiology',
    'Clinical Consumables',
    'Continence',
    'Cosmetic and Plastic',
    'Critical Care',
    'Dermatology',
    'Diabetes',
    'Diagnostics',
    'Dialysis/Kidney/Renal',
    'Disability-Assistive Products',
    'Emergency Care',
    'Endoscopy',
    'Gastroenterology',
    'General Surgery',
    'Gynecology & Obstetrics',
    'ICU',
    'Imaging/Radiology',
    'Infection Control',
    'Infrastructure/Furniture/Logistics',
    'IT/Data Analytics/Telemedicine',
    'Laboratory/Testing',
    'Medical Equipment',
    'Neonatal',
    'Neurology',
    'Neonatal/Pediatric Care',
    'Oncology',
    'Ophthalmology',
    'Orthopedics',
    'Otorhinolaryngology (ENT)',
    'Patient Monitoring/AI',
    'Rehabilitation/Physiotherapy',
    'Respiratory',
    'Simulation',
    'Sterile Services',
    'Surgery',
    'Textiles/Medical Clothing/PPE',
    'Urology',
    'Wound Care',
];

//Generate a list of years from 1900 to current year
const getYears = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 1899 }, (v, i) => 1900 + i);
    return years;
};

const sizes = [
    { value: 'Small', label: 'Small (1-50)' },
    { value: 'Medium', label: 'Medium (51-200)' },
    { value: 'Large', label: 'Large (201-500)' },
    { value: 'Expanding', label: ' (500+)' },
];

const scope = [
    { value: 'Regional', label: 'Regional' },
    { value: 'National', label: 'National' },
    { value: 'Multinational', label: 'Multinational' },
];

const jobRoles = [
    { value: 'Management/Owner/C-Level', label: 'Management/Owner/C-Level' },
    { value: 'Engineer/Designer', label: 'Engineer/Designer' },
    { value: 'Regulatory Affairs Specialist', label: 'Regulatory Affairs Specialists' },
    { value: 'Sales and Marketing Team', label: 'Sales and Marketing Team' },
    { value: 'Supply Chain and Logistics Professionals', label: 'Supply Chain and Logistics Professionals' },
    { value: 'Customer/Technical Support Representative', label: 'Customer/Technical Support Representative' },
    { value: 'Data Scientists/Analysts', label: 'Data Scientists/Analysts' },
    { value: 'Other', label: 'Other' },

];

const productTypes = [
    'Software', 'Consumable', 'Implantable Devices', 'Capital Equipment',
    'In Vitro Diagnostics', 'Reusable Equipment', 'Hybrid', 'Other'
];

const regulatoryApproval = [
    'FDA (United States)', 'CE Mark (Europe)', 'COFEPRIS (Mexico)', 'ANVISA (Brazil)', 'NMPA (China)',
    'PMDA (Japan)', 'CDSCO (India)', 'Other - Country of Origin', 'Other'
]

const currencies = [
    { label: 'USD ($)', value: 'USD' },      // United States Dollar
    { label: 'EUR (€)', value: 'EUR' },      // Euro
    { label: 'JPY (¥)', value: 'JPY' },      // Japanese Yen
    { label: 'CNY (¥)', value: 'CNY' },      // Chinese Yuan Renminbi
    { label: 'INR (₹)', value: 'INR' },      // Indian Rupee
    { label: 'GBP (£)', value: 'GBP' },      // British Pound Sterling
];

const stages = [
    'Design and Development', 'Pre-Clinical Testing', 'Regulatory Submitted',
    'Regulatory Approved and Commercial in Country of Origin', 'Regulatory Approved and Commercial in other countries',
    'Other'
];

const dropdownFields = {
    jobRole: jobRoles,
    companyHQ: latinAmericanCountries.map(compHQ => ({ value: compHQ, label: compHQ })),
    companySize: sizes,
    scope: scope,
    territory: latinAmericanCountries.map(country => ({ value: country, label: country })),
    specialties: specialtiesList.map(specialty => ({ value: specialty, label: specialty })),
    desiredSpecialties: specialtiesList.map(specialty => ({ value: specialty, label: specialty }))
};

const dropdownFieldsManufacturer = {
    companyHQ: countries.map(compHQ => ({ value: compHQ, label: compHQ })),
    companySize: sizes,
    clinicalSegment: specialtiesList.map(specialty => ({ value: specialty, label: specialty })),
    productType: productTypes.map(productTypes => ({ value: productTypes, label: productTypes })),
    developmentStage: stages.map(stages => ({ value: stages, label: stages })),
    regulatoryApprovals: regulatoryApproval.map(regulatoryApproval => ({ value: regulatoryApproval, label: regulatoryApproval })),
    currency: currencies,
}

const SESSION_TIMEOUT = 15 * 60 * 1000;

const CompanyPage = () => {
    const [userData, setUserData] = useState({});
    const [editData, setEditData] = useState({});
    const [logoURL, setLogoURL] = useState({});
    const [images, setImages] = useState([]);
    const [inputValue, setInputValue] = useState('');


    const navigate = useNavigate();
    const logoutTimerRef = useRef(null);

    const userType = localStorage.getItem('userType')
    let userId;

    if (userType === 'Distributor') {
        userId = localStorage.getItem('distributorId'); // Get user ID from local storage
    } else if (userType === 'Manufacturer') {
        userId = localStorage.getItem('manufacturerId'); // Get user ID from local storage

    }
    //console.log(userId, userType)

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                if (userId) {
                    if (userType === 'Distributor') {
                        const userData = await API.graphql(graphqlOperation(getTodo, { id: userId }));
                        setUserData(userData.data.getTodo);
                        setEditData(userData.data.getTodo);

                    } else if (userType === 'Manufacturer') {
                        const userData = await API.graphql(graphqlOperation(getManufacturer, { id: userId }));
                        setUserData(userData.data.getManufacturer);
                        setEditData(userData.data.getManufacturer);
                        if (userData.data.getManufacturer.companyLogo) {
                            logoURL = userData.data.getManufacturer.companyLogo
                            console.log(logoURL)
                        }
                    }
                } else {
                    console.error('User ID not found in local storage');
                }
            } catch (error) {
                console.error('Error fetching user data', error);
            }
        };

        fetchUserData();
        //fetchImages();



        // Set up session timeout
        setSessionTimeout();

        // Set up event listeners for user activity
        window.addEventListener("mousemove", resetSessionTimeout);
        window.addEventListener("mousedown", resetSessionTimeout);
        window.addEventListener("keypress", resetSessionTimeout);
        window.addEventListener("scroll", resetSessionTimeout);

        return () => {
            // Cleanup logic...
            clearTimeout(logoutTimerRef.current);

            // Remove event listeners
            window.removeEventListener("mousemove", resetSessionTimeout);
            window.removeEventListener("mousedown", resetSessionTimeout);
            window.removeEventListener("keypress", resetSessionTimeout);
            window.removeEventListener("scroll", resetSessionTimeout);
        };
    }, [userId]);


    const updateProfile = async () => {
        try {
            const changes = getChangedFields(userData, editData);
            changes.id = changes.id || userData.id; // Ensuring ID is present

            // Check if it's a Manufacturer and ensure required fields are present
            if (userType === 'Manufacturer') {
                changes.companyID = changes.companyID || userData.companyID; // Ensuring companyID is present
                changes.productID = changes.productID || userData.productID; // Ensuring productID is present

                if (!changes.companyID || !changes.productID || !changes.id) {
                    console.error('Missing required fields for Manufacturer', changes);
                    alert('Error: Missing required data for Manufacturer. Please try again.');
                    return;
                }
            } else if (userType === 'Distributor' && !changes.id) {
                console.error('Missing required ID for Distributor', changes);
                alert('Error: Missing required data for Distributor. Please try again.');
                return;
            }

            if (Object.keys(changes).length > 1) {
                if (userType === 'Distributor') {
                    await API.graphql(graphqlOperation(updateTodo, { input: changes }));
                }
                else if (userType === 'Manufacturer') {
                    await API.graphql(graphqlOperation(updateManufacturer, { input: changes }));
                }
                setUserData(editData);
                alert('Data saved successfully!');
            } else {
                console.log('No changes to update');
            }
        } catch (error) {
            console.error('Error updating user data', error);
            if (error.errors) console.error('Error details: ', error.errors);
        }
    };

    const getChangedFields = (originalData, updatedData) => {
        const changes = {};
        Object.keys(updatedData).forEach(key => {
            if (originalData[key] !== updatedData[key]) {
                changes[key] = updatedData[key];
            }
        });
        return changes;
    };


    const handleSignOut = async () => {
        try {
            await Auth.signOut();  // Calling signOut method from Auth module
            localStorage.clear()
            navigate('/login')
            console.log('User signed out!');

            // Here, navigate the user to the sign-in page or do any other necessary cleanup
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };

    const handleChange = (key, value) => {
        if (key === 'relatedLinks' && typeof value === 'string') {
            setEditData(prev => ({ ...prev, [key]: [...prev[key] || [], value] }));
        } else if (Array.isArray(value)) {
            setEditData(prev => ({ ...prev, [key]: value }));
        } else {
            setEditData(prev => ({ ...prev, [key]: value }));
        }
    };


    const handleSave = async () => {
        if (JSON.stringify(userData) !== JSON.stringify(editData)) {
            await updateProfile(editData);
        }
    };

    const setSessionTimeout = () => {
        if (logoutTimerRef.current) {
            clearTimeout(logoutTimerRef.current);
        }

        logoutTimerRef.current = setTimeout(() => {
            handleSessionTimeout();
        }, SESSION_TIMEOUT);
    };

    const resetSessionTimeout = () => {
        setSessionTimeout();
    };

    const handleSessionTimeout = async () => {
        try {
            await Auth.signOut();
            localStorage.clear();
            navigate('/login');
            console.log('Session timed out. User signed out!');
        } catch (error) {
            console.error('Error handling session timeout: ', error);
        }
    };

    // const fetchImages = async () => {
    //     let imageKeys = await Storage.list('');
    //     console.log('imageKeys before processing:', imageKeys);
    
    //     // Check if imageKeys.results is defined and an array
    //     if (imageKeys.results && Array.isArray(imageKeys.results)) {
    //         imageKeys = await Promise.all(
    //             imageKeys.results.map(async (k) => {
    //                 const key = await Storage.get(k.key);
    //                 return key;
    //             })
    //         );
    //         console.log('Processed imageKeys:', imageKeys);
    //         setImages(imageKeys);
    //     } else {
    //         console.error('imageKeys.results is not an array:', imageKeys.results);
    //     }
    // };
    
    // Before the return statement of your component
    const parsedEditData = { ...editData };

    if (typeof parsedEditData.territory === 'string') {
        parsedEditData.territory = parsedEditData.territory.split(', ').map(item => item.trim());
    }
    if (parsedEditData.scope && parsedEditData.scope.length === 1) {
        parsedEditData.scope = parsedEditData.scope[0].split(',').map(item => item.trim());
    }
    if (parsedEditData.specialties && parsedEditData.specialties.length === 1) {
        parsedEditData.specialties = parsedEditData.specialties[0].split(',').map(item => item.trim());
    }
    if (parsedEditData.desiredSpecialties && parsedEditData.desiredSpecialties.length === 1) {
        parsedEditData.desiredSpecialties = parsedEditData.desiredSpecialties[0].split(',').map(item => item.trim());
    }


    return (
        <Container style={{ width: '100%', height: '100%' }}>
            <Sidebar activeItem="accountSettings" />
            <MainContent >
                <FlexContainer>
                    <AccountTitle variant="h5" style={{ fontWeight: '550' }}>Account Details</AccountTitle>
                    <LogoutButton onClick={handleSignOut}>Sign Out</LogoutButton>
                </FlexContainer>
                <StyledLine />
                <div>
                    {images.map((image) => (
                        <img
                            src={image}
                            key={image}
                            style={{ width: 500, height: 300 }}
                        />
                    ))}
                </div>
                <div style={{ maxWidth: '370px' }}>
                    <ProfileTitle variant="h6" style={{ marginBottom: '15px', fontWeight: 'bold', borderBottom: '2px solid #f1f2fa' }}>Company Profile</ProfileTitle>
                    {editData.userType === 'Distributor' && distributorFields.map(field => (
                        <React.Fragment key={field.key}>
                            {field.key === 'phoneNumber' && <ProfileTitle variant="h6" style={{ marginBottom: '15px', fontWeight: 'bold', borderBottom: '2px solid #f1f2fa' }}>Company</ProfileTitle>}
                            <Label style={dropdownFields[field.key] ? { display: 'block', marginBottom: '30px', maxWidth: '100%' } : { display: 'block', marginBottom: '10px' }}>
                                {field.label}:
                                {dropdownFields[field.key] ? (
                                    <Select
                                        isMulti={['territory', 'specialties', 'desiredSpecialties'].includes(field.key)}
                                        value={['territory', 'specialties', 'desiredSpecialties'].includes(field.key)
                                            ? dropdownFields[field.key].filter(option => parsedEditData[field.key].includes(option.value))
                                            : dropdownFields[field.key].find(option => option.value === parsedEditData[field.key])}
                                        options={dropdownFields[field.key]}
                                        onChange={selected => {
                                            const selectedValues = Array.isArray(selected) ? selected.map(s => s.value) : selected?.value;
                                            handleChange(field.key, selectedValues);
                                        }}
                                    />
                                ) : (
                                    (field.key === 'brands' || field.key === 'certificates') ? (
                                        <textarea
                                            value={editData[field.key] || ''}
                                            onChange={(e) => handleChange(field.key, e.target.value)}
                                            style={{
                                                width: '100%',
                                                fontSize: '16px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                marginBottom: '20px',
                                                boxSizing: 'border-box',
                                                padding: '10px 10px 40px 10px',
                                                overflowWrap: 'break-word',
                                                resize: 'none',
                                                fontFamily: 'aktiv-grotesk',
                                            }}
                                        />
                                    ) : (
                                        <Input
                                            type="text"
                                            value={editData[field.key] || ''}
                                            onChange={(e) => handleChange(field.key, e.target.value)}
                                            style={{
                                                width: '100%',
                                                fontSize: '16px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                marginBottom: '20px',
                                                boxSizing: 'border-box',

                                            }}
                                        />
                                    )
                                )}
                            </Label>
                            {field.key === 'scope' && <ProfileTitle variant="h6" style={{ marginBottom: '15px', fontWeight: 'bold', borderBottom: '2px solid #f1f2fa' }}>Products</ProfileTitle>}
                        </React.Fragment>
                    ))}

                    {
                        editData.userType === 'Manufacturer' && manufacturerFields.map(field => (
                            <React.Fragment key={field.key}>
                                {field.key === 'productName' && <ProfileTitle variant="h6" style={{ marginBottom: '15px', fontWeight: 'bold', borderBottom: '2px solid #f1f2fa' }}>Product Details</ProfileTitle>}
                                {field.key === 'competitors' && <ProfileTitle variant="h6" style={{ marginBottom: '15px', fontWeight: 'bold', borderBottom: '2px solid #f1f2fa' }}>Product Distribution & Pricing Details</ProfileTitle>}

                                <Label style={dropdownFieldsManufacturer[field.key] ? { display: 'block', marginBottom: '30px', maxWidth: '100%' } : { display: 'block', marginBottom: '10px' }}>
                                    {field.label}:
                                    {field.key === 'relatedLinks' ? (
                                        <Select
                                            isMulti
                                            isClearable
                                            isSearchable
                                            inputValue={inputValue}
                                            menuIsOpen={false}
                                            value={(editData[field.key] || []).map(link => ({ label: link, value: link }))}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    maxWidth: '100%',
                                                }),
                                            }}
                                            onInputChange={(inputValue, { action }) => {
                                                setInputValue(inputValue);
                                                if (action === 'input-blur' || action === 'menu-close') return;
                                                if (action === 'set-value') {
                                                    setInputValue(''); // Just clear the input value on selecting, since we're handling Enter key separately now
                                                }
                                            }}
                                            onKeyDown={e => {
                                                if (e.key === 'Enter' && inputValue.trim() !== '') {
                                                    handleChange('relatedLinks', [...(editData.relatedLinks || []), inputValue]);
                                                    setInputValue(''); // clear the inputValue after adding
                                                }
                                            }}
                                            onChange={(selected) => {
                                                const selectedValues = Array.isArray(selected) ? selected.map(s => s.value) : [];
                                                handleChange(field.key, selectedValues);
                                            }}
                                        />
                                    ) : dropdownFieldsManufacturer[field.key] ? (
                                        <Select
                                            isMulti={['clinicalSegment', 'regulatoryApprovals'].includes(field.key)}  // Update with actual keys if any
                                            value={
                                                ['clinicalSegment', 'regulatoryApprovals'].includes(field.key)
                                                    ? dropdownFieldsManufacturer[field.key].filter(option => parsedEditData[field.key].includes(option.value))
                                                    : dropdownFieldsManufacturer[field.key].find(option => option.value === parsedEditData[field.key])
                                            }
                                            options={dropdownFieldsManufacturer[field.key]}
                                            onChange={selected => {
                                                const selectedValues = Array.isArray(selected) ? selected.map(s => s.value) : selected?.value;
                                                handleChange(field.key, selectedValues);
                                            }}
                                        />
                                    ) : (
                                        <Input
                                            type="text"
                                            value={editData[field.key] || ''}
                                            onChange={(e) => handleChange(field.key, e.target.value)}
                                            style={{
                                                width: '100%',
                                                fontSize: '16px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                marginBottom: '20px',
                                                boxSizing: 'border-box'
                                            }}
                                        />
                                    )}
                                </Label>
                            </React.Fragment>
                        ))
                    }
                    <ActionButton variant="contained" type="submit" style={{ width: '35%', borderRadius: '10px', marginRight: '10px', backgroundColor: "#0086ff", color: 'white', marginTop: '10px' }} onClick={handleSave}> Save</ActionButton>
                </div>
            </MainContent>
        </Container>
    );
};

export default CompanyPage;
