import React, { createContext, useState, useContext } from 'react';

const UserContext = createContext(null);

export const UserProvider = ({ children }) => {
    const [userId, setUserIdState] = useState(localStorage.getItem('userId') || null);
    const [userType, setUserTypeState] = useState(localStorage.getItem('userType') || null);
    const [isAdmin, setIsAdminState] = useState(localStorage.getItem('isAdmin') === 'true');
    const [isManuf, setIsManufState] = useState(localStorage.getItem('isManuf') === 'true');

    const setUserId = (id) => {
        localStorage.setItem('userId', id);
        setUserIdState(id);
    };

    const setUserType = (type) => {
        localStorage.setItem('userType', type);
        setUserTypeState(type);
    };

    const setIsAdmin = (adminStatus) => {
        localStorage.setItem('isAdmin', adminStatus);
        setIsAdminState(adminStatus === 'true');
    };

    const setIsManuf = (manufStatus) => {
        localStorage.setItem('isManuf', manufStatus);
        setIsManufState(manufStatus === 'true');
    };

    const contextValue = {
        userId, setUserId,
        userType, setUserType,
        isAdmin, setIsAdmin,
        isManuf, setIsManuf
    };

    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
