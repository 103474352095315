import React, { useState } from 'react';
import styled from 'styled-components';
import { API, graphqlOperation } from 'aws-amplify';
import { updateManufacturer, createProducts, updateProducts } from './graphql/mutations'; // use createDistributor mutation
import { Button, TextField, MenuItem, Typography, InputLabel } from '@material-ui/core';
import { Storage } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import Amplify from 'aws-amplify';
import config from './aws-exports';
import { createGlobalStyle } from 'styled-components';


const Container = styled.div`
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  height: calc(100vh - 70px);
  width: 100vw; // Use the full width of the viewport
  margin: 0;
  border-radius: 0;
  background: url('/background.png') no-repeat center center fixed; 
  background-size: cover; // Ensure that one image covers the full container
  overflow-y: auto; 
  overflow-x: hidden;
  padding-bottom: 40px
`;


const Logo = styled.img`
  width: 350px;
  height: 130px;
  margin-bottom: 1rem;
  margin-right: 1.5rem;
  @media (max-width: 600px) {
    width: 300px; // Smaller width for smaller screens
    height: auto; // Maintain aspect ratio
  }

  @media (max-width: 480px) {
    width: 200px; // Even smaller width for mobile screens
    height: auto; // Maintain aspect ratio
  }

`;


const WelcomeMessage = styled(Typography)`
  margin-bottom: 2rem;
  color:#000000;
  font-family: "aktiv-grotesk", Arial, sans-serif !important; /* Fallback to Arial or sans-serif */
  font-weight: 900;
`;

const Form = styled.form`
  flex-wrap: wrap;
  justify-content: flex-start; // Changed from space-between to flex-start
  max-width: 500px;
  width: 100%;
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 10px 25px -5px rgba(0, 0, 0, 0.2);
  margin-bottom: 50px;
  @media (max-width: 600px) {
    max-width: 90%; // Allow the form to scale down
    padding: 1rem;
  }

  @media (max-width: 480px) {
    max-height: calc(100vh - 80px);// Further scale down the form on very small screens
    padding: 1rem;
  }
  
`;

const FormField = styled(TextField)`
  margin-bottom: 1rem;
  width: 100%; // Subtract padding from the total width
  padding: 10px; // Add padding to prevent text from touching the edges
  box-sizing: border-box; // Ensures padding is included in width
  border-radius: 50px;
`;

const FieldContainer = styled.div`
  width: 100%; // adjust the width as needed
`;

const ActionButton = styled(Button)`
  font-family: "aktiv-grotesk", Arial, sans-serif !important; /* Fallback to Arial or sans-serif */
  margin-top: 1rem;
  border-radius: 90px;
  color: white;
  

  &:hover {
    background: linear-gradient(45deg, #5982DB 30%, #5982DB 90%);
  }

  &:active {
    transform: scale(0.98);
    transition: all .1s ease-in-out;
  }
  && {
    text-transform: Capitalize;
  }
`;


const StyledDropzone = styled.div`
    border: 2px dashed #cccccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: border 0.3s ease;
    width: 50%; // Added to make width smaller
    height: 80px; 
    margin-bottom: 10px;

    &:hover {
    border: 2px dashed #0000cc;
    }
`;


const LinkContainer = styled.div`
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    background-color: #f4f4f4;
    border-radius: 5px;
`;

const LinkText = styled.span`   // changed from 'a' to 'span' since it's not a hyperlink
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
`;

const RemoveLinkButton = styled.button`
    background-color: #a8a8a8;
    color: white;
    border: none;
    margin-left: 10px;
    border-radius: 5px;
    padding: 0 8px;
    cursor: pointer;
`;

const productType = [
    'Software', 'Consumable', 'Implantable Devices', 'Capital Equipment',
    'In Vitro Diagnostics', 'Reusable Equipment', 'Hybrid', 'Other'
];


const regulatoryApprovals = [
    'FDA (United States)', 'CE Mark (Europe)', 'COFEPRIS (Mexico)', 'ANVISA (Brazil)', 'NMPA (China)',
    'PMDA (Japan)', 'CDSCO (India)', 'Other - Country of Origin', 'Other'
]

const developmentStage = [
    'Design and Development', 'Pre-Clinical Testing', 'Regulatory Submitted',
    'Regulatory Approved and Commercial in Country of Origin', 'Regulatory Approved and Commercial in other countries',
    'Other'
];

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    //overflow: hidden; // Prevent horizontal scrolling
  }

  // Your other styles...
`;

const TitleLabel = styled.div`
  font-weight: bold; // Bold font weight for title
  margin-bottom: 5px;
  color: #808080;

  @media (max-width: 768px) {
    font-size: 14px; // Smaller font size on mobile
  }

  @media (max-width: 321px) {
    font-size: 12px; // Even smaller font size for very small screens
  }
`;

const SubtitleLabel = styled.div`
  font-weight: normal; // Normal font weight for subtitle
  color: #808080;

  @media (max-width: 768px) {
    font-size: 14px; // Smaller font size on mobile
  }

  @media (max-width: 321px) {
    font-size: 12px; // Even smaller font size for very small screens
  }
`;


const ManufacturerSignup2 = () => {
    const [files, setFiles] = useState([]);
    const [showDropzone, setShowDropzone] = useState(false);
    const [regulatoryApproval, setRegulatoryApproval] = useState('');


    const [formData, setFormData] = useState({
        productName: '',
        productDescription: '',
        productType: '',
        developmentStage: '',
        competitiveAdvantage: '',
        regulatoryApprovals: '',
        approvalDocuments: ''
    });

    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });

        if (name === 'regulatoryApprovals') {
            setShowDropzone(!!value);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const manufacturerId = localStorage.getItem('manufacturerId');
        const companyID = localStorage.getItem('manufCompanyId');
        const productID = localStorage.getItem('manufProductId');
        //console.log("local storage got:", companyID, productID)
        let urls = [];

        for (const file of files) {
            const fileName = `${Date.now()}-${file.name}`;

            try {
                await Storage.put(fileName, file, {
                    contentType: file.type,
                });
                urls.push(fileName);

            } catch (error) {
                console.error('Error uploading the file:', error);
                return;
            }
        }
        formData.approvalDocuments = urls;

        const productData = {
            id: manufacturerId,
            productId: productID, // If productId is different from the manufacturerId
            companyId: companyID,
            companyName: formData.companyName, // Add this if companyName is part of formData
            productName: formData.productName,
            productDescription: formData.productDescription,
            productType: formData.productType,
            competitiveAdvantage: formData.competitiveAdvantage,
            developmentStage: formData.developmentStage,
            regulatoryApprovals: formData.regulatoryApprovals,
            approvalDocuments: formData.approvalDocuments,
            // ... other fields as per your form data
        };

        try {
            //console.log("formData:", formData)
            const productCreationResponse = await API.graphql(graphqlOperation(updateProducts, { input: productData }));
            console.log('Product updated successfully:', productCreationResponse);

            const updatedData = {
                id: manufacturerId,
                companyID: companyID,
                productID: productID,
                ...formData,
            }


            const manufacturerUpdateResponse = await API.graphql(graphqlOperation(updateManufacturer, { input: updatedData }));
        console.log('Manufacturer updated successfully:', manufacturerUpdateResponse);

            //console.log("result:", result)
            localStorage.setItem('manufacturerSignupData', updatedData);
            //console.log('Partial data saved successfully');
            navigate('/ManufacturerSignup3');

        } catch (error) {
            console.error("Error updating manufacturer:", error);
        }

        //console.log('Form data submitted:', updatedData);
    };

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setFiles(prevFiles => [...prevFiles, ...Array.from(e.target.files)]);
        }
    };;

    const FileDropzone = ({ onFileChange }) => {
        const { getRootProps, getInputProps } = useDropzone({
            onDrop: acceptedFiles => {
                setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
            },
            multiple: true
        });

        if (!showDropzone) return null;

        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledDropzone {...getRootProps()}>
                    <input {...getInputProps()} />
                    <img src="/upload-icon.png" width="22" height="22"></img>
                    <TitleLabel>
                        Upload approval documents
                    </TitleLabel>
                    <SubtitleLabel>Drag & drop your files here, or click to select them</SubtitleLabel>
                </StyledDropzone>
            </div>
        );
    };
    
    const handleFileRemove = (index) => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    };

    const clearApproval = () => {
        setRegulatoryApproval(null);
    }


    return (
        <>
            <GlobalStyle />
            <Container>
                <a href="https://medikana.io">
                    <Logo src="/medikanaLogoWithName.png" alt="Logo"></Logo>
                </a>
                <Form onSubmit={handleSubmit}>
                    <WelcomeMessage variant="h4" color="primary" style={{ marginBottom: '15px', fontSize: '28px', color: "#000000" }}>
                        About Your Product
                    </WelcomeMessage>
                    <FieldContainer>
                        <FormField
                            label="Product Name"
                            type="text"
                            name="productName"
                            value={formData.productName}
                            onChange={handleInputChange}
                            variant="outlined"
                            required
                            style={{ marginBottom: '15px' }}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <FormField
                            label="Product Description"
                            multiline // Add this line to make it multi-line
                            rows={3}  // This specifies the number of visible rows. You can adjust it based on your preference.
                            name="productDescription"
                            value={formData.productDescription}
                            onChange={handleInputChange}
                            variant="outlined"
                            required
                            style={{ marginBottom: '15px' }}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <FormField
                            select
                            name="productType"
                            label="Product Type"
                            variant="outlined"
                            required
                            onChange={handleInputChange}
                            value={formData.productType}
                            style={{ marginBottom: '15px' }}
                        >
                            {productType.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </FormField>
                    </FieldContainer>
                    <FieldContainer>
                        <FormField
                            select
                            name="developmentStage"
                            label="Development Stage"
                            variant="outlined"
                            required
                            onChange={handleInputChange}
                            value={formData.developmentStage}
                            style={{ marginBottom: '15px' }}
                        >
                            {developmentStage.map((stage) => (
                                <MenuItem key={stage} value={stage}>
                                    {stage}
                                </MenuItem>
                            ))}
                        </FormField>
                    </FieldContainer>
                    <FieldContainer>
                        <FormField
                            label="Competitive and Clinical Advantage"
                            multiline
                            rows={3}
                            name="competitiveAdvantage"
                            value={formData.competitiveAdvantage}
                            onChange={handleInputChange}
                            variant="outlined"
                            required
                            style={{ marginBottom: '15px' }}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <FormField
                            select
                            name="regulatoryApprovals"
                            label="Regulatory approvals, if any"
                            variant="outlined"
                            onChange={handleInputChange}
                            value={formData.regulatoryApprovals}
                            style={{ marginBottom: '15px' }}
                        >
                            {regulatoryApprovals.map((regulation) => (
                                <MenuItem key={regulation} value={regulation}>
                                    {regulation}
                                </MenuItem>
                            ))}
                            <RemoveLinkButton onClick={clearApproval}>Clear</RemoveLinkButton>
                        </FormField>
                    </FieldContainer>
                    <FieldContainer>
                        <FileDropzone onFileChange={handleFileChange} />
                        {files.map((file, index) => (
                            <LinkContainer key={index} style={{ marginBottom: '5px' }}>
                                <LinkText>{file.name}</LinkText>
                                <RemoveLinkButton onClick={() => handleFileRemove(index)}>X</RemoveLinkButton>
                            </LinkContainer>
                        ))}
                    </FieldContainer>
                    <ActionButton variant="contained" type="submit" style={{ width: '30%', borderRadius: '10px', marginRight: '10px', backgroundColor: "#0086ff", color: "white", marginTop: '5px' }}>
                        Continue
                    </ActionButton>
                </Form>
            </Container>
        </>
    );
};

export default ManufacturerSignup2;