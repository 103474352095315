import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #FFFFFF;
`;

const SelectionButton = styled(Button)`
  && {
    margin-top: 1rem;
    background-color: #572DEA;
    color: white;
  }
`;

const Image = styled.img`
  width: 100px; /* Adjust the width as per your requirement */
  height: auto;
`;

function RoleSelection() {
  const navigate = useNavigate();

  useEffect(() => {
    // wait for 3 seconds then redirect
    const timeout = setTimeout(() => {
      //window.location.href = 'https://www.medikana.io';
      navigate('/login')
      //window.location.href = 'https://www.medikana.io';
    }, 5000); // 3000 milliseconds = 3 seconds

    // cleanup function to clear the timeout if the component unmounts before the redirect happens
    return () => clearTimeout(timeout);
  }, [navigate]);

  return (
    <Container>
      <Image src="/MedikanaLogoNoName.png" alt="Checkmark" />
      <h1 style={{paddingTop:'35px', textAlign: 'center'}}>Your profile has been successfully created! </h1>
      <h2 style={{paddingBottom:'35px', textAlign: 'center'}}> Please click the link we sent to your email, and then log in.  
        Thank you! </h2>
    </Container>
  );
}

export default RoleSelection;
