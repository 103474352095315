import React from 'react';
import countryRegulations from './regulations';
import { Typography, Paper } from '@material-ui/core';
import Sidebar from './sideBar';
import { useLocation } from 'react-router-dom';
import ReactCountryFlag from "react-country-flag";


const normalizeRequirementName = (name) => {
    // Use only the first sentence or a specified part for comparison
    return name.split('. ')[0];
};

const RegulatoryComparison = () => { // Default to an empty array if not provided
    const location = useLocation();
    const { selectedCountries } = location.state || { selectedCountries: [] };
    //console.log('Selected Countries:', selectedCountries);
    const [checkedRequirements, setCheckedRequirements] = React.useState({});
    const [globalCheckedState, setGlobalCheckedState] = React.useState({});
    const [showMore, setShowMore] = React.useState({});


    const getCountryDetails = (country) => {
        const details = countryRegulations.find(c => c.country === country);

        if (details && details.requirements) {
            Object.keys(details.requirements).forEach(req => {
                details.requirements[req].isChecked = !!checkedRequirements[req];
            });
        }

        //console.log(`Details for ${country}:`, details);
        return details;
    };

    const handleCheckboxChange = (originalReqName) => {
        const normalizedReqName = normalizeRequirementName(originalReqName);
        setGlobalCheckedState(prevState => ({
            ...prevState,
            [normalizedReqName]: !prevState[normalizedReqName]
        }));
    };

    const calculateCheckedPercentage = (requirements) => {
        let total = 0;
        let checked = 0;
        Object.entries(requirements).forEach(([type, reqs]) => {
            Object.keys(reqs).forEach(reqName => {
                const normalizedReqName = normalizeRequirementName(reqName);
                // Ensure that we're not counting filtered-out requirements like "isChecked"
                if (!["isChecked"].includes(normalizedReqName)) {
                    total++;
                    if (globalCheckedState[normalizedReqName]) {
                        checked++;
                    }
                }
            });
        });
        return total > 0 ? (checked / total) * 100 : 0;
    };


    const formatTitle = (title) => {
        // Split camelCase and join with a space
        return title.replace(/([a-z0-9])([A-Z])/g, '$1 $2')
            .replace(/^./, str => str.toUpperCase()); // Capitalize first letter
    };

    const splitDescription = (description) => {
        let splitIndex, beforeText, afterText;

        // First, try to find a period for splitting
        splitIndex = description.indexOf('.');
        if (splitIndex !== -1) {
            beforeText = description.slice(0, splitIndex + 1);
            afterText = description.slice(splitIndex + 1);
            // Check if afterText is meaningful
            if (afterText.trim().length > 0) {
                return [beforeText, afterText];
            }
        }

        // If no meaningful content after period, check for parentheses
        splitIndex = description.indexOf('(');
        if (splitIndex !== -1) {
            beforeText = description.slice(0, splitIndex);
            afterText = description.slice(splitIndex);
            // Check if afterText (including parentheses) is meaningful
            if (afterText.trim().length > 1) { // More than just '('
                return [beforeText, afterText];
            }
        }

        // If no meaningful content found, return the original description
        return [description, ''];
    };

    const toggleShowMore = (countryName, type, reqName) => {
        const key = `${countryName}-${type}-${reqName}`;
        setShowMore(prev => ({ ...prev, [key]: !prev[key] }));
    };

    // This is a simple example; you may need a more comprehensive list or a better mapping strategy
    const countryCodes = {
        "Argentina": "AR",
        "Brasil": "BR",
        "Chile": "CL",
        "Colombia": "CO",
        "Costa Rica": "CR",
        "Guatemala": "GT",
        "Ecuador": "EC",
        "Mexico": "MX",
        "Panama": "PA",
        "Peru": "PE",
        // ... add other countries as needed ...
    };

    return (
        <div style={{ display: 'flex', minHeight: '100vh' }}>
            <Sidebar activeItem="requirementsComparison" />
            <div style={{ flex: 1, padding: '20px' }}>
                <h1>Comparison of Regulatory Requirements</h1>
                <div style={{ display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap' }}>
                    {selectedCountries.map((countryName) => {
                        const countryDetails = getCountryDetails(countryName);
                        const percentageChecked = calculateCheckedPercentage(countryDetails.requirements);
                        return (
                            <Paper key={countryName} style={{ margin: '10px', padding: '20px', width: '300px' }}>
                                <Typography variant="h5" component="h3" style={{ paddingBottom: '10px' }}>
                                    {countryCodes[countryName] && (
                                        <ReactCountryFlag
                                            countryCode={countryCodes[countryName]}
                                            svg
                                            style={{ width: '1.5em', height: '1.5em', marginRight: '10px' }}
                                        />
                                    )}
                                    {countryDetails.country}
                                </Typography>
                                <div style={{ width: '100%', backgroundColor: '#ddd', position: 'relative' }}>
                                    <div style={{ height: '24px', width: `${percentageChecked}%`, backgroundColor: '#B1D400' }}></div>
                                    <div style={{ position: 'absolute', top: '20%', left: '50%', transform: 'translateX(-50%)', color: 'black' }}>
                                        {percentageChecked.toFixed(0)}%
                                    </div>
                                </div>
                                <div>
                                    {Object.entries(countryDetails.requirements).map(([type, reqs], index) => (
                                        <div key={index}>
                                            <Typography variant="h6" style={{ paddingTop: '10px' }}>{formatTitle(type)}</Typography>
                                            {Object.keys(reqs).filter(reqName => reqName !== "isChecked").map((reqName) => {
                                                const [firstPart, morePart] = splitDescription(reqName);
                                                const normalizedReqName = normalizeRequirementName(reqName);
                                                const isChecked = globalCheckedState[normalizedReqName] || false;
                                                const key = `${countryName}-${type}-${reqName}`;
                                                return (
                                                    <div key={reqName} style={{ display: 'flex', alignItems: 'flex-start', marginTop: '10px' }}>
                                                        <input
                                                            type="checkbox"
                                                            checked={isChecked}
                                                            onChange={() => handleCheckboxChange(reqName)}
                                                            style={{ marginRight: '10px' }} // Space between checkbox and text
                                                        />
                                                        <Typography variant="body2">
                                                            {firstPart}
                                                            {morePart.trim().length > 0 && !showMore[key] &&
                                                                <span style={{ marginLeft: '5px', color: 'blue', cursor: 'pointer' }} onClick={() => toggleShowMore(countryName, type, reqName)}>
                                                                    More...
                                                                </span>
                                                            }
                                                            {showMore[key] && (
                                                                <div>
                                                                    <Typography variant="body2" style={{ marginTop: '5px' }}>{morePart}</Typography>
                                                                    <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => toggleShowMore(countryName, type, reqName)}>
                                                                        Less...
                                                                    </span>
                                                                </div>
                                                            )}
                                                        </Typography>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ))}
                                </div>
                            </Paper>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default RegulatoryComparison;