import React, { useState } from 'react';
import Sidebar from './sideBar';
import Select from 'react-select';

const customStyles = {
  control: (provided) => ({
    ...provided,
    height: '30px',
    minHeight: '40px',
    lineHeight: '30px',
  }),
};

// Costa Rica Classification Rules
const costaRicaClassification = (answers) => {
  if (answers.invasive === 'yes') {
    if (answers.surgicallyInvasive === 'yes' && answers.duration > 30) {
      return 'Class III';
    } else {
      return 'Class II';
    }
  } else if (answers.specialFeatures === 'yes' || answers.contactBody === 'yes') {
    return 'Class II';
  } else {
    return 'Class I';
  }
};

// Colombia Classification Rules
const colombiaClassification = (answers) => {
  // For Medical Devices
  if (answers.deviceType === 'medical') {
    if (answers.invasive === 'non-invasive') {
      return 'Class I';
    }
    if (answers.bloodOrFluids === 'yes') {
      return answers.bloodCompositionModification === 'yes' ? 'Class IIb' : 'Class IIa or I';
    }
    if (answers.surgicalUse === 'yes') {
      return 'Varies'; // The class can vary based on purpose and duration of use
    }
    if (answers.purposeAndRisk === 'yes') {
      return 'Class IIb or III';
    }
    return 'Class I'; // Default for other cases
  }

  // For In Vitro Diagnostic Devices
  if (answers.deviceType === 'in-vitro') {
    switch (answers.applicationType) {
      case 'screening':
        return 'Category III';
      case 'highRisk':
        return 'Category III';
      case 'general':
        return 'Category I';
      default:
        return 'Category II';
    }
  }

  return 'Unknown';
};


const peruClassification = (answers) => {
  switch (answers.deviceTypePeru) {
    case 'non-invasive':
      if (answers.contactCNSPeru === 'yes') {
        return 'Class IV'; // Devices in direct contact with the CNS
      }
      if (answers.modifyBodilyFluidsPeru === 'yes') {
        return 'Class III'; // Devices for modifying bodily fluids
      }
      return answers.externalUsePeru === 'yes' ? 'Class I' : 'Class II'; // External use or other cases

    case 'invasive':
      if (answers.longTermInvasivePeru === 'yes') {
        return 'Class IV'; // Long-term invasive or implantable devices
      }
      return answers.contraceptiveSTIPeru === 'yes' ? 'Class III' : 'Class II'; // Contraceptive devices or STI prevention

    case 'active':
      if (answers.suspendsMedicinalSubstancePeru === 'yes') {
        return 'Class IV'; // Devices suspending medicinal substances or made from biological materials
      }
      if (answers.monitorControlTherapeuticPeru === 'yes' || answers.providesEnergyPeru === 'yes') {
        return 'Class III'; // Devices for monitoring or controlling therapeutic devices or providing energy
      }
      return 'Class II'; // General active medical devices

    default:
      return 'Unknown'; // Default case if device type is not specified
  }
};


const DeviceClassificationForm = () => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [answers, setAnswers] = useState({});
  const [classification, setClassification] = useState('');

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);
    setAnswers({}); // Reset answers when country changes
    setClassification('');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAnswers(prevAnswers => ({ ...prevAnswers, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let classResult;
    switch (selectedCountry) {
      case 'Costa Rica':
        classResult = costaRicaClassification(answers);
        break;
      case 'Colombia':
        classResult = colombiaClassification(answers);
        break;
      case 'Peru':
        classResult = peruClassification(answers);
        break;
      default:
        classResult = 'Unknown';
    }
    setClassification(classResult);
  };

  return (
    <div style={{ display: 'flex', minHeight: '100vh' }}>
      <Sidebar /* ...props */ />
      <div style={{ flex: 1, padding: '20px' }}>
        <h1>Device Classification Form</h1>
        <form onSubmit={handleSubmit}>
          {/* Country Selection */}
          <label>
            Select Country for Classification:
            <select name="country" onChange={handleCountryChange} value={selectedCountry}>
              <option value="">Select Country</option>
              <option value="Costa Rica">Costa Rica</option>
              <option value="Colombia">Colombia</option>
              <option value="Peru">Peru</option>
            </select>
          </label>

          <br />

          {/* Costa Rica Specific Questions */}
          {selectedCountry === 'Costa Rica' && (
            <>
              <label>
                Is the device invasive?
                <select name="invasive" onChange={handleChange}>
                  <option value="">Select</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </label>
              <br />
              {answers.invasive === 'yes' && (
                <>
                  <label>
                    Is the device surgically invasive?
                    <select name="surgicallyInvasive" onChange={handleChange}>
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </label>
                  <br />
                  {answers.surgicallyInvasive === 'yes' && (
                    <label>
                      Does the device remain in the body for more than 30 days?
                      <select name="duration" onChange={handleChange}>
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </label>
                  )}
                </>
              )}

              <label>
                Does the device have any special features or contact body?
                <select name="specialFeatures" onChange={handleChange}>
                  <option value="">Select</option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </label>
              <br />
            </>
          )}

          {/* Colombia Specific Questions */}
          {selectedCountry === 'Colombia' && (
            <>
              {/* Determine if it's a Medical Device or In Vitro Diagnostic Device */}
              <label>
                Is the device for medical use or in vitro diagnostic use?
                <select name="deviceType" onChange={handleChange}>
                  <option value="">Select</option>
                  <option value="medical">Medical Device</option>
                  <option value="in-vitro">In Vitro Diagnostic Device</option>
                </select>
              </label>
              <br />

              {answers.deviceType === 'medical' && (
                <>
                  {/* Questions for Medical Devices */}
                  <label>
                    Is the device invasive or non-invasive?
                    <select name="invasive" onChange={handleChange}>
                      <option value="">Select</option>
                      <option value="invasive">Invasive</option>
                      <option value="non-invasive">Non-Invasive</option>
                    </select>
                  </label>
                  <br />

                  <label>
                    Is the device intended for conducting or storing blood or bodily fluids?
                    <select name="bloodOrFluids" onChange={handleChange}>
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </label>
                  <br />

                  <label>
                    Does the device modify the biological or chemical composition of blood?
                    <select name="bloodCompositionModification" onChange={handleChange}>
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </label>
                  <br />

                  {/* ... Additional medical device-specific questions ... */}
                </>
              )}
              {answers.deviceType === 'in-vitro' && (
                <>
                  {/* Questions for In Vitro Diagnostic Devices */}
                  <label>
                    What is the specific application or type of the in vitro diagnostic device?
                    <select name="applicationType" onChange={handleChange}>
                      <option value="">Select</option>
                      <option value="screening">Screening Transmissible Diseases</option>
                      <option value="highRisk">High-Risk Disease Diagnosis</option>
                      <option value="general">General Category</option>
                      <option value="specificMedicalAreas">Related to Specific Medical Areas</option>
                    </select>
                  </label>
                  <br />

                  {/* ... Additional in vitro diagnostic device-specific questions ... */}
                </>
              )}
            </>
          )}



          {/* Peru Specific Questions */}
          {selectedCountry === 'Peru' && (
            <>
              {/* Determine the Type of Device */}
              <label>
                What type of device is it? (Non-Invasive, Invasive, Active)
                <select name="deviceTypePeru" onChange={handleChange}>
                  <option value="">Select</option>
                  <option value="non-invasive">Non-Invasive</option>
                  <option value="invasive">Invasive</option>
                  <option value="active">Active</option>
                </select>
              </label>
              <br />

              {/* Questions for Non-Invasive Devices */}
              {answers.deviceTypePeru === 'non-invasive' && (
                <>
                  <label>
                    Is the device for external use or for compression/absorption without contact with internal tissues?
                    <select name="externalUsePeru" onChange={handleChange}>
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </label>
                  <br />

                  <label>
                    Does the device modify bodily fluids?
                    <select name="modifyBodilyFluidsPeru" onChange={handleChange}>
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </label>
                  <br />

                  <label>
                    Is the device in direct contact with the central nervous system?
                    <select name="contactCNSPeru" onChange={handleChange}>
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </label>
                  <br />
                </>
              )}

              {/* Questions for Invasive Devices */}
              {answers.deviceTypePeru === 'invasive' && (
                <>
                  <label>
                    Is the device a contraceptive or preventing sexually transmitted infections?
                    <select name="contraceptiveSTIPeru" onChange={handleChange}>
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </label>
                  <br />

                  <label>
                    Is the device long-term invasive or implantable?
                    <select name="longTermInvasivePeru" onChange={handleChange}>
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </label>
                  <br />
                </>
              )}

              {/* Questions for Active Devices */}
              {answers.deviceTypePeru === 'active' && (
                <>
                  <label>
                    Does the device provide energy to the body (excluding heating/lighting)?
                    <select name="providesEnergyPeru" onChange={handleChange}>
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </label>
                  <br />

                  <label>
                    Does the device suspend a medicinal substance or made from biological tissues or cells?
                    <select name="suspendsMedicinalSubstancePeru" onChange={handleChange}>
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </label>
                  <br />

                  <label>
                    Is the device used for monitoring or controlling the performance of therapeutic devices?
                    <select name="monitorControlTherapeuticPeru" onChange={handleChange}>
                      <option value="">Select</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                    </select>
                  </label>
                  <br />
                </>
              )}
            </>
          )}

 <br />
          <button type="submit">Classify</button>
        </form>

        {classification && (
          <div>
            <p>Classification in {selectedCountry}: {classification}</p>
          </div>
        )}
      </div>
      </div>


      );
};

      export default DeviceClassificationForm;