/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      email
      id
      name
      companyName
      jobRole
      phoneNumber
      website
      companySize
      foundedYear
      territory
      companyHQ
      scope
      brands
      specialties
      desiredSpecialties
      certificates
      userType
      createdAtUnix
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        email
        id
        name
        companyName
        jobRole
        phoneNumber
        website
        companySize
        foundedYear
        territory
        companyHQ
        scope
        brands
        specialties
        desiredSpecialties
        certificates
        userType
        createdAtUnix
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getManufacturer = /* GraphQL */ `
  query GetManufacturer($id: ID!) {
    getManufacturer(id: $id) {
      companyID
      productID
      email
      userType
      id
      name
      companyName
      companyWebsite
      companyHQ
      companySize
      yearFounded
      clinicalSegment
      companyLogo
      productName
      productDescription
      competitiveAdvantage
      developmentStage
      regulatoryApprovals
      approvalDocuments
      clinicalIndications
      competitors
      productType
      targetDemographic
      currency
      transferPriceMin
      transferPriceMax
      distributionChannels
      relatedLinks
      otherDocuments
      createdAtUnix
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listManufacturers = /* GraphQL */ `
  query ListManufacturers(
    $filter: ModelManufacturerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listManufacturers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        companyID
        productID
        email
        userType
        id
        name
        companyName
        companyWebsite
        companyHQ
        companySize
        yearFounded
        clinicalSegment
        companyLogo
        productName
        productDescription
        competitiveAdvantage
        developmentStage
        regulatoryApprovals
        approvalDocuments
        clinicalIndications
        competitors
        productType
        targetDemographic
        currency
        transferPriceMin
        transferPriceMax
        distributionChannels
        relatedLinks
        otherDocuments
        createdAtUnix
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getRegulatoryProfile = /* GraphQL */ `
  query GetRegulatoryProfile($id: ID!) {
    getRegulatoryProfile(id: $id) {
      id
      productId
      companyId
      AR
      BR
      CL
      CO
      CR
      GT
      EC
      MX
      PA
      PE
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listRegulatoryProfiles = /* GraphQL */ `
  query ListRegulatoryProfiles(
    $filter: ModelRegulatoryProfileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegulatoryProfiles(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        productId
        companyId
        AR
        BR
        CL
        CO
        CR
        GT
        EC
        MX
        PA
        PE
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProducts = /* GraphQL */ `
  query GetProducts($id: ID!) {
    getProducts(id: $id) {
      id
      productId
      companyId
      companyName
      productName
      productDescription
      productType
      competitiveAdvantage
      developmentStage
      regulatoryApprovals
      approvalDocuments
      clinicalIndications
      competitors
      targetDemographic
      currency
      transferPriceMin
      transferPriceMax
      distributionChannels
      relatedLinks
      otherDocuments
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        productId
        companyId
        companyName
        productName
        productDescription
        productType
        competitiveAdvantage
        developmentStage
        regulatoryApprovals
        approvalDocuments
        clinicalIndications
        competitors
        targetDemographic
        currency
        transferPriceMin
        transferPriceMax
        distributionChannels
        relatedLinks
        otherDocuments
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getProductByProductId = /* GraphQL */ `
  query GetProductByProductId($productId: String!) {
    getProductByProductId(productId: $productId) {
      id
      productId
      companyId
      companyName
      productName
      productDescription
      productType
      competitiveAdvantage
      developmentStage
      regulatoryApprovals
      approvalDocuments
      clinicalIndications
      competitors
      targetDemographic
      currency
      transferPriceMin
      transferPriceMax
      distributionChannels
      relatedLinks
      otherDocuments
    }
  }
`;



