const regulationsEcuador = {
    country: "Ecuador",
    countryCode: 'EC',
    deviceTypes: {
        "Medical Device": {
            "Classes": ["Class I", "Class II", "Class III", "Class IV"]
        },
        "In Vitro Device": {
            "Classes": ["Class I", "Class II", "Class III", "Class IV"]
        }
    },
    timeline: "ARCSA will review applications within 10 working days. Applicants have 30 days to correct any issues, with a maximum of two corrections allowed. If no issues are found, ARCSA will issue a Sanitary Registration certificate, which can be printed by the applicant. The registration does not have an expiration date.",
    cost: { 
        "Class I": "$904,34",
        "Class II": "$904,34",
        "Class III": "$904,34",
        "Class IV": "$904,34",
    },

    requirements: {
        sanitaryRegistrationCertificate: {
            "Labels. Draft of labels/tags": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Instruction Manual. Insertion or User Manual for medical devices": {
                appliesTo: {
                    "Medical Device": ["Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Certificate of Good Manufacturing Practices. Or ISO 13485 Standard Certificate, as applicable.": {
                appliesTo: {
                    "Medical Device": ["Class III", "Class IV"],
                    "In Vitro Device": [""],
                }
            },
            "General Information about the Device.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Interpretation of Batch or Series Code": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Detailed Product Description. Description of the functional components, parts, and structure of the medical device": {
                appliesTo: {
                    "Medical Device": [ "Class II", "Class III", "Class IV"],
                    "In Vitro Device": [""],
                }
            },
            "Generic Name or International Common Denomination (ICD) of the Active Ingredient. Qualitative and quantitative formula with active principles and excipients of the medicine": {
                appliesTo: {
                    "Medical Device": ["Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Description of the Raw Materials or Materials, if applicable. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Description of the Primary and Secondary Packaging, as appropriate. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Manufacturing Process Flow Chart. Information on the manufacturing process, including the flow chart of the manufacturing process of the medical device for human use.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Stability Certificate. ": {
                appliesTo: {
                    "Medical Device": ["Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Sterility Certificate. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": [""],
                }
            },
            "Biocompatibility Studies. ": {
                appliesTo: {
                    "Medical Device": [ "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Clinical and Diagnostic Performance Evaluation. Specific clinical studies for medical technology or detailed analysis of clinical evidence on the device. Diagnostic agent studies include: Precision / reproducibility studies, Sensitivity studies, Specificity studies": {
                appliesTo: {
                    "Medical Device": ["Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Certificate of Good Manufacturing Practices. Compliance certificate from Manufacturers (Manufacturing Sites) for the standard or quality management system according to the type and risk level of the human use medical devices they manufacture.": {
                appliesTo: {
                    "Medical Device": [ "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class II", "Class III", "Class IV"],
                }
            },
            "Authorization issued by the Competent Authority in Nuclear Applications Control. Only for controlled energy active human use medical devices that use radiations.": {
                appliesTo: {
                    "Medical Device": [""],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Free Sale Certificate. Export Certificate, or equivalent document": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Manufacturer's Authorization. Duly Legalized Authorization from the Product Owner. Authorizes the applicant to obtain the Sanitary Registration in Ecuador, clearly expressing the powers granted.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            
        },
        technicalReport: {
            "Sanitary Registration Certificate of the Current Country of Origin. Simple copy of the certificate or its equivalent, as applicable.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Quality Control Analysis Certificate. Simple copy of the certificate or its equivalent.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            // "Simple copy of the ISO 13485 Standard Certificate or specific product standard, or current Good Manufacturing Practices (GMP) Certificate, as applicable.": { // appeared twice, once in registration and one in tech report 
            //     appliesTo: {
            //         "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
            //         "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
            //     }
            // },
            "Sterility Certificate. Applies only to sterile medical devices. Simple copy.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Packing list. Simple Copy.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Efficacy and Safety Information. In accordance with ANVISA regulation stipulating the Essential Requirements for Efficacy and Safety of Medical Products. If this description does not prove the efficacy and safety of the product, ANVISA will request a clinical investigation of the product.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            // ... additional nested items under sanitaryRegistration  
        }
    }
};
export default regulationsEcuador;
