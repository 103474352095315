const regulationsColombia = {
    country: "Colombia",
    countryCode: 'CO',
    deviceTypes: {
        "Medical Device": {
            "Classes": ["Class I", "Class II", "Class IIb", "Class III"],
           
        },
        "In Vitro Device": {
            "Classes": ["Class I", "Class II", "Class III"],
        }
    },
    timeline: "Automatic registration for Biomedical Equipment (Class I and Class IIa): 1 month after completing the review of the information sent. Registration for Class IIb and Class III or Marketing Authorization: 4 months after receiving all the requirements. Another source says: Risk classification I and IIA: 2 business days after filing. Risk classification IIB and III: 90 business days after filing. Modifications to registrations due to minor changes in the product or stakeholders: 1 month after submission. Class I and II: Automatic registration: 2-4 days from application. Low-risk devices (medical devices and IVDs): 10 years. Moderate and high-risk devices (medical devices and IVDs): 5 years.", 
    cost: {
        "Medical Device": {
            "Class I": "$725",
            "Class IIa": "$725",
            "Class IIb": "$820",
            "Class III": "$820"
        },
        "In Vitro Device": {
            "Class I": "$480 to $3170",
            "Class II": "$480 to $3170",
            "Class III": "$640"
        }
    },
    requirements: {
        sanitaryRegistration: {
            "Proof of Payment of Fees.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class IIa", "Class IIb", "Class III"],
                    "In Vitro Device": [""]
                }
            },
            "Labels. Final arts of labels and inserts. A representative sketch will be presented indicating the product information.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class IIa", "Class IIb", "Class III"],
                    "In Vitro Device": [""]
                }
            },
            "Endorsement by the technical director (national products) or the person responsible for manufacturing (imported products).": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class IIa", "Class IIb", "Class III"],
                    "In Vitro Device": [""]
                }
            },
            "Free Sale Certificate. For imported products.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class IIa", "Class IIb", "Class III"],
                    "In Vitro Device": [""]
                }
            },
            "Manufacturer's Authorization to the Importer. For imported products. The manufacturer is the legal manufacturer.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class IIa", "Class IIb", "Class III"],
                    "In Vitro Device": [""]
                }
            },
            "Proof of Constitution, Existence, and Legal Representation of the Importer and Manufacturer as applicable. Health and legal documents demonstrating existence will be accepted.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class IIa", "Class IIb", "Class III"],
                    "In Vitro Device": [""]
                }
            },
            "Certificate of Good Manufacturing Practices. Or Copy of the Storage Capacity Certificate (CCAA)/information about storage and transportation.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class IIa", "Class IIb", "Class III"],
                    "In Vitro Device": [""]
                }
            },
            "Detailed Product Description. The description of the medical device refers only to: indications, contraindications, warnings, main components, accessories, patient relationship, and operation description; all in Spanish.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class IIa", "Class IIb", "Class III"],
                    "In Vitro Device": [""]
                }
            },
            "Technical Studies and Analytical Tests. The requirement is considered fulfilled with the presentation of any of the following two: A) Summary of design verification and validation documents: Compliance can be achieved with the declaration of conformity in which compliance with international reference standards is related; or B) Certificate of analysis of the finished product containing the specifications, indicating the values or acceptance ranges.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class IIa", "Class IIb", "Class III"],
                    "In Vitro Device": [""]
                }
            },
            "Sterilization Process. The statement of the method and the reference standard it is based on, when applicable.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class IIa", "Class IIb", "Class III"],
                    "In Vitro Device": [""]
                }
            },
            "Disposal Method of the Product, when applicable. Certification declaring that local waste disposal standards will be applied will be accepted.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class IIa", "Class IIb"],
                    "In Vitro Device": [""]
                }
            },
            "Statement Regarding Biomedical Equipment, when applicable. The commitment to deliver to the end user the operation or user manual which are available in Spanish and will have maintenance and operation manuals available when necessary.": {
                appliesTo: {
                    "Medical Device": ["Class IIa", "Class IIb", "Class III"],
                    "In Vitro Device": [""]
                }
            },
            "Efficacy and Safety Information. Scientific Information Supporting the Safety of the Product. Biocompatibility tests only apply to materials that represent innovations (those without international reference standards) and summaries of studies and tests can be requested on them.": {
                appliesTo: {
                    "Medical Device": ["Class IIa", "Class IIb", "Class III"],
                    "In Vitro Device": [""]
                }
            },
            "Risk Management and Safety Compliance Documentation. Risk analysis in the design stage, etc. Description of measures to comply with the essential safety requirements.": {
                appliesTo: {
                    "Medical Device": ["Class IIa", "Class IIb", "Class III"],
                    "In Vitro Device": [""]
                }
            },
            "List of Standards Used. It will be related in the declaration of conformity.": {
                appliesTo: {
                    "Medical Device": ["Class IIa", "Class IIb", "Class III"],
                    "In Vitro Device": [""]
                }
            },
            "Clinical Studies on use to Demonstrate Safety and Effectiveness. When they are not innovations, published clinical studies of similar or equivalent technologies can be delivered.": {
                appliesTo: {
                    "Medical Device": ["Class IIb", "Class III"],
                    "In Vitro Device": [""]
                }
            },
            "Commercial History. For imported products. This document may be signed by the health responsible in Colombia.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class IIa", "Class IIb", "Class III"],
                    "In Vitro Device": [""]
                }
            },
        },
        technicalAndLegalEvaluationForMarketingAuthorization: {
            "Compliance Tests/Proof. Certificate or proof of compliance of the equipment with international quality standards (brand and model), issued by a national or international entity.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class IIa", "Class IIb", "Class III"],
                    "In Vitro Device": [""]
                }
            },
            "Name and location of the Health Service Provider Institution where the equipment will be installed. Plus commitment to inform about the same, in case it has not yet been commercialized. Commitment that when the equipment is placed, information will be provided.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class IIa", "Class IIb", "Class III"],
                    "In Vitro Device": [""]
                }
            },
            "Letter from the Manufacturer. Guaranteed long-term support and comprehensive training. Declaration or letter issued by the manufacturer or representative of the equipment, stating the following: The equipment to be acquired is not under experimentation. The ability to supply consumables, parts, spare parts, and maintenance service for at least five (5) years, or for the lifetime of the equipment if it is shorter. That they will provide the user with programs and mechanisms for the training of operators and maintenance engineers or technicians. To deliver to the end user the operation or user manual which are available in Spanish and will have maintenance and operation manuals available when necessary. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class IIa", "Class IIb", "Class III"],
                    "In Vitro Device": [""]
                }
            },
            "Complete items 1-18 of the Health Registration.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class IIa", "Class IIb", "Class III"],
                    "In Vitro Device": [""]
                }
            },
        }
    }
};

export default regulationsColombia;