import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import styled from 'styled-components';
import { Typography, TextField, Button, CircularProgress, InputLabel } from '@material-ui/core';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'
import { createGlobalStyle } from 'styled-components';


const Container = styled.div`
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  height: calc(100vh - 70px);
  width: 100vw; // Use the full width of the viewport
  margin: 0;
  border-radius: 0;
  background: url('/background.png') no-repeat center center fixed; 
  background-size: cover; // Ensure that one image covers the full container
  overflow-y: auto; 
  overflow-x: hidden;
  padding-bottom: 40px
`;


const Logo = styled.img`
  width: 350px;
  height: 130px;
  margin-bottom: 1rem;
  margin-right: 1.5rem;
  @media (max-width: 600px) {
    width: 300px; // Smaller width for smaller screens
    height: auto; // Maintain aspect ratio
  }

  @media (max-width: 480px) {
    width: 200px; // Even smaller width for mobile screens
    height: auto; // Maintain aspect ratio
  }

`;

const Form = styled.form`
  flex-wrap: wrap;
  justify-content: flex-start; // Changed from space-between to flex-start
  max-width: 500px;
  width: 100%;
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 10px 25px -5px rgba(0, 0, 0, 0.2);
  margin-bottom: 50px;
  @media (max-width: 600px) {
    max-width: 90%; // Allow the form to scale down
    padding: 1rem;
  }

  @media (max-width: 480px) {
    max-height: calc(100vh - 80px);// Further scale down the form on very small screens
    padding: 1rem;
  }
  
`;

const FieldContainer = styled.div`
  width: 100%; // adjust the width as needed
`;

const ActionButton = styled(Button)`
  font-family: "aktiv-grotesk" !important;
  margin-top: 1rem;
  border-radius: 90px;
  background-color: #0086ff; // Changed color here
  color: white;


  &:hover {
    background: linear-gradient(45deg, #0075db 30%, #0075db 90%); // Changed color here
  }

  &:active {
    transform: scale(0.98);
    transition: all .1s ease-in-out;
  }
  && {
    text-transform: Capitalize;
  }
`;

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden; // Prevent horizontal scrolling
  }

  // Your other styles...
`;

const ResetPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState(1); //1 = email stage 2 = code stage
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const [passwordRequirements, setPasswordRequirements] = useState({
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    hasMinimumLength: false,
  });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const allPasswordReqsMet = passwordRequirements.hasUpperCase && passwordRequirements.hasLowerCase && passwordRequirements.hasNumber && passwordRequirements.hasMinimumLength;

  const userPool = new CognitoUserPool({
    UserPoolId: 'us-east-1_LJ7b58QSI',
    ClientId: 'ds68pub9s6cudnva97ovhrujb' // <- You need to specify your Client ID here
  });

  const getUser = () => {
    return new CognitoUser({
      Username: email.toLowerCase(),
      Pool: userPool,
    });
  };

  const sendCode = async (evt) => {
    evt.preventDefault();
    setLoading(true);
    setError('');

    getUser().forgotPassword({
      onSuccess: data => {
        // Proceed to the next stage
        console.log('Code sent successfully', data);
        setStage(2);
      },
      onFailure: err => {
        if (err.code === 'UserNotFoundException') {
          setError('No account found with the provided email address. Please check and try again.');
        } else {
          setError(err.message || 'An unexpected error occurred. Please try again.');
        }
      },
      inputVerificationCode: data => {
        console.log('Please input verification code', data);
        setStage(2);
      },
    });

    setLoading(false);
  };


  const resetPassword = async (evt) => {
    evt.preventDefault();
    setLoading(true);

    // Check if passwords match
    if (password !== confirmPassword) {
      setError("Passwords don't match. Please try again.");
      setLoading(false);
      return;
    }

    // Check if password meets the requirements
    const { hasUpperCase, hasLowerCase, hasNumber, hasMinimumLength } = passwordRequirements;
    if (!hasUpperCase || !hasLowerCase || !hasNumber || !hasMinimumLength) {
      setError('Password does not meet requirements: ' +
        `${!hasUpperCase ? '1 uppercase, ' : ''}` +
        `${!hasLowerCase ? '1 lowercase, ' : ''}` +
        `${!hasNumber ? '1 number, ' : ''}` +
        `${!hasMinimumLength ? 'minimum 8 characters.' : ''}`.trim().replace(/,([^,]*)$/, ' and$1')
      );
      setLoading(false);
      return;
    }

    // Attempt to reset password
    try {
      await getUser().confirmPassword(code, password, {
        onSuccess: data => {
          // handle success, possibly navigate to login page
          console.log('Password reset successfully', data);
          navigate('/login'); // redirect user to login page
          setError('');
        },
        onFailure: err => {
          // handle specific error
          setError(err.message || 'An unexpected error occurred. Please try again.');
        },
      });
    } catch (err) {
      setError(err.message || 'An unexpected error occurred. Please try again.');
    }

    setLoading(false);
  };


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Update password requirements when password changes
  useEffect(() => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasMinimumLength = password.length >= 8;

    setPasswordRequirements({
      hasUpperCase,
      hasLowerCase,
      hasNumber,
      hasMinimumLength,
    });
  }, [password]);


  return (
    <>
      <GlobalStyle />
      <Container>
        <a href="https://medikana.io" style={{ marginBottom: '40px' }}>
          <Logo src="/medikanaLogoWithName.png" alt="Logo" />
        </a>
        <Form onSubmit={stage === 1 ? sendCode : resetPassword} style={{ padding: '30px', margin: 'auto', marginTop: '20px', maxWidth: '500px' }}>
          <Typography variant="h6" style={{ paddingLeft: '4px', marginBottom: '0px', fontSize: '24px' }}>
            {stage === 1 ? 'Enter Email for Verification Code' : 'Confirm New Password'}
          </Typography>
          {stage === 2 && (
            <Typography variant="subtitle1" style={{ paddingLeft: '4px', marginTop: '5px', fontSize: '16px' }}>
              A Verification Code has been sent to your email address.
            </Typography>
          )}
          <FieldContainer style={{ width: '500px' }}>
            {stage === 1 && (
              <TextField
                fullWidth
                margin="normal"
                label="Email"
                variant="outlined"
                type="email"
                value={email}
                onChange={evt => setEmail(evt.target.value)}
              />

            )}
            {stage === 2 && (
              <>
                <TextField
                  fullWidth
                  margin="normal"
                  label="Verification Code"
                  variant="outlined"
                  type="text"
                  value={code}
                  onChange={evt => setCode(evt.target.value)}
                />
                <TextField
                  fullWidth
                  margin="normal"
                  label="New Password"
                  variant="outlined"
                  type="password"
                  value={password}
                  onChange={evt => setPassword(evt.target.value)}
                />
                {!allPasswordReqsMet && password && (
                  <div style={{
                    fontSize: '12px',
                    marginLeft: '15px',
                    width: '250px',
                    flex: 'none',
                  }}>
                    <p style={{ color: passwordRequirements.hasUpperCase ? 'green' : 'red' }}>{passwordRequirements.hasUpperCase ? '✔' : '✖'} Must contain at least one uppercase letter</p>
                    <p style={{ color: passwordRequirements.hasLowerCase ? 'green' : 'red' }}>{passwordRequirements.hasLowerCase ? '✔' : '✖'} Must contain at least one lowercase letter</p>
                    <p style={{ color: passwordRequirements.hasNumber ? 'green' : 'red' }}>{passwordRequirements.hasNumber ? '✔' : '✖'} Must contain at least one number</p>
                    <p style={{ color: passwordRequirements.hasMinimumLength ? 'green' : 'red' }}>{passwordRequirements.hasMinimumLength ? '✔' : '✖'} Must be at least 8 characters long</p>
                  </div>
                )}
                <TextField
                  fullWidth
                  margin="normal"
                  label="Confirm New Password"
                  variant="outlined"
                  type="password"
                  value={confirmPassword}
                  onChange={evt => setConfirmPassword(evt.target.value)}
                />
              </>
            )}
          </FieldContainer>
          <ActionButton variant="contained" type="submit" style={{ width: '33%', borderRadius: '10px', backgroundColor: "#0086ff", color: 'white', marginTop: '10px' }}>
            {stage === 1 ? 'Send Code' : 'Reset Password'}
          </ActionButton>
          {error && <Typography color="error" style={{ marginTop: '15px', fontSize: '0.9rem' }}>{error}</Typography>}

          {loading && <CircularProgress size={25} style={{ marginTop: '9px', color: '#0086ff' }} />}
        </Form>
      </Container>
    </>
  );
};


export default ResetPasswordPage;
