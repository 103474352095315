const regulationsCostaRica = {
    country: "Costa Rica",
    countryCode: 'CR',
    deviceTypes: {
        "Medical Device": {
            "Classes": ["Class I", "Class II", "Class III", "Class IV"]
        },
        "In Vitro Device": {
            "Classes": ["Class I", "Class II", "Class III", "Class IV"]
        }
    },
    timeline: "The reviewer may notify the applicant once in writing to complete or correct any missing requirements in the documentation, or to clarify information. The applicant has ten (10) business days from the receipt of the notice to submit corrected documents or arguments against the observations. Review of requirements for Class 1 and 2 has a deadline of 15 days. Review of requirements for Class 3 and 4 has a deadline of 30 days. Review of requirements for Class 1 and 2 has a deadline of 15 days. Review of requirements for Class 3 and 4 has a deadline of 30 days. If the technical evaluation of documents and studies is satisfactory, the sanitary registration of the EMB (Biomedical Equipment) will be granted, and the respective registration certificate will be issued within the indicated period.Within a maximum period of twenty-five (25) days after receiving the file, the reviewer may once again notify the applicant in writing to complete or correct any missing requirements. The applicant then has ten (10) business days to make corrections and submit them. ",
    cost: { 
        "Class I": "$0",
        "Class II": "$25",
        "Class III": "$50",
        "Class IV": "$50",
    },

    requirements: {
        sanitaryRegistration: {
            "Proof of Payment of Fees.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Labels. Original labeling of the product in case of equipment for personal 'in vitro' diagnostics.": {
                appliesTo: {
                    "Medical Device": [""],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Certificate of Good Manufacturing Practices. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Application Form for the Sanitary Registration of Biomedical Equipment (EMB)": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Free Sale Certificate": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "Imported devices": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Medical and Technical Specifications. For the Biomedical Equipment in Spanish": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Authorization from the Source of Emission for Biomedical Equipment that emit Ionizing Radiation.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Commercial History. List of countries where the Biomedical Equipment is commercialized.": {
                appliesTo: {
                    "Medical Device": ["Class III", "Class IV"],
                    "In Vitro Device": ["Class III", "Class IV"],
                }
            },
            "Presentation of a Surveillance Program by the Manufacturer.": {
                appliesTo: {
                    "Medical Device": ["Class III", "Class IV"],
                    "In Vitro Device": ["Class III", "Class IV"],
                }
            },
            "Clinical Studies on use to Demonstrate Safety and Effectiveness. Summary in Spanish of clinical trials including bibliographic references, demonstrating the safety and efficacy of the EMB, and the complete study in digital or printed form.": {
                appliesTo: {
                    "Medical Device": ["Class III", "Class IV"],
                    "In Vitro Device": ["Class III", "Class IV"],
                }
            },
            "Risk Management and Safety Compliance Documentation. Copy of the risk analysis and evaluation study and the measures to be adopted for their reduction, that meet the safety and efficacy requirements, prepared by the manufacturer.": {
                appliesTo: {
                    "Medical Device": ["Class IV"],
                    "In Vitro Device": ["Class IV"],
                }
            },
            "Bibliographic References. References of published reports should be related to the use, safety, and efficacy of the EMB.": {
                appliesTo: {
                    "Medical Device": ["Class IV"],
                    "In Vitro Device": ["Class IV"],
                }
            },
            "Biological Safety Certificate. In the case of EMBs manufactured from tissues and their derivatives from humans or animals, present the certificate issued by the manufacturer.": {
                appliesTo: {
                    "Medical Device": ["Class IV"],
                    "In Vitro Device": ["Class IV"],
                }
            },
            "List of Standards Used. Indicate the international standard that contains the accuracy and precision standards applicable to the Biomedical Equipment": {
                appliesTo: {
                    "Medical Device": [""],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            
            // ... additional nested items under sanitaryRegistration  
        },
        // ... other categories of requirements can be added here
    }
};
export default regulationsCostaRica;
