const regulationsBrasil = {
    country: "Brasil",
    countryCode: 'BR',
    deviceTypes: {
        "Medical Device": {
            "Classes": ["Class I", "Class II", "Class III", "Class IV"]
        },
        "In Vitro Device": {
            "Classes": ["Class I", "Class II", "Class III", "Class IV"]
        }
    },
    timeline: "ANVISA will evaluate the documentation submitted for registration, modification, or revalidation of the registration and will respond through publication in the Official Gazette of the Union - DOU. Notification Process: the notification number will be issued within 30 days. The registration will be granted within a maximum period of 90 days from the date of entry of the process. In practice, companies usually wait between 18-24 months for the product to be commercialized in the national market. Valid for 5 to 10 years, depending on the nature or the sanitary risk involved.",
    cost: {
        "Class I": "~$1,222",
        "Class II": "~$1,222",
        "Class III": "~$1,222",
        "Class IV": "~$1,222",
    },

    requirements: {
        sanitaryRegistration: {
            "Proof of Payment of Fees.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Certification of Conformity Assessment. Authenticated copy of the Certificate of Conformity. i. If it is not possible to issue a certificate of conformity within the SBAC, the registration or change of registration of the product can be granted without it, but the company must justify the impossibility and has 180 days after the certification conditions are restored to present the certificate. ii. For equipment tested abroad, test reports for the preparation of the consolidated report will be accepted if they come from laboratories accredited by ILAC signatory entities and if they evaluate all the elements for which there is testing capacity in Brazil": {
                appliesTo: {
                    "Medical Device": ["Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class II", "Class III", "Class IV"],
                }
            },
            "Manufacturer or Importer Information. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Labels. i. Manufacturer identification (name or brand); ii. Equipment identification (commercial name and model); iii. Equipment serial number; iv. ANVISA registration number of the equipment. (see ANNEX III.B for requirements)": {
                appliesTo: {
                    "Medical Device": ["Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class II", "Class III", "Class IV"],
                }
            },
            "Instruction Manual.": {
                appliesTo: {
                    "Medical Device": ["Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class II", "Class III", "Class IV"],
                }
            },
            "Certificate of Good Manufacturing Practices. ": {
                appliesTo: {
                    "Medical Device": ["Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class II", "Class III", "Class IV"],
                }
            },
            "Completed Medical Device Registration Form.": {
                appliesTo: {
                    "Medical Device": ["Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class II", "Class III", "Class IV"]
                }
            },
            "Proof of Operating Authorization for Manufacturer and/or Importer Company. For the abroad importer to market their medical product in the country. When authorized by the exporter, the importer must demonstrate the commercial relationship between the exporter and the manufacturer.": {
                appliesTo: {
                    "Medical Device": ["Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class II", "Class III", "Class IV"],
                }
            },
            "Free Trade Certificate. For imported medical products, proof of registration or a certificate of free trade or equivalent document, issued by the competent authority of the countries where the medical product is manufactured and/or marketed.": {
                appliesTo: {
                    "Medical Device": ["Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class II", "Class III", "Class IV"],
                }
            },
            "Compliance Tests/Proof. These should meet the legal provisions set out in the technical regulations, in the form of ANVISA legislation.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
        },
        technicalReport: {
            "Detailed Product Description. Must include the principles of its operation and action, its content or composition, when applicable, as well as a list of accessories intended to be part of the product.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Indication, Purpose, or Intended Use. As specified by the manufacturer.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Precautions and Restrictions. Warnings, special care, and clarifications regarding the use of the medical product, as well as its storage and transportation.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Forms of Presentation of the Medical Product. Information on how the product will be presented.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Manufacturing Process Flow Chart. Flow chart containing the stages of the manufacturing process until obtaining the finished product.": {
                appliesTo: {
                    "Medical Device": ["Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class II", "Class III", "Class IV"],
                }
            },
            "Efficacy and Safety Information. In accordance with ANVISA regulation stipulating the Essential Requirements for Efficacy and Safety of Medical Products. If this description does not prove the efficacy and safety of the product, ANVISA will request a clinical investigation of the product.": {
                appliesTo: {
                    "Medical Device": ["Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class II", "Class III", "Class IV"],
                }
            },
            // ... additional nested items under sanitaryRegistration  
        }
    }
};

export default regulationsBrasil;