import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { API, graphqlOperation } from 'aws-amplify';
import { createManufacturer, updateManufacturer } from './graphql/mutations'; // use createDistributor mutation
import { Button, TextField, MenuItem, Typography, Select, InputLabel, OutlinedInput, FormControl, ListItemText, Input, Switch } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import { Storage } from 'aws-amplify';
import { useDropzone } from 'react-dropzone';
import { createGlobalStyle } from 'styled-components';


const Container = styled.div`
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  height: calc(100vh - 70px);
  width: 100vw; // Use the full width of the viewport
  margin: 0;
  border-radius: 0;
  background: url('/background.png') no-repeat center center fixed; 
  background-size: cover; // Ensure that one image covers the full container
  overflow-y: auto; 
  overflow-x: hidden;
  padding-bottom: 40px
`;

const Logo = styled.img`
  width: 350px;
  height: 130px;
  margin-bottom: 1rem;
  margin-right: 1.5rem;
  @media (max-width: 600px) {
    width: 300px; // Smaller width for smaller screens
    height: auto; // Maintain aspect ratio
  }

  @media (max-width: 480px) {
    width: 200px; // Even smaller width for mobile screens
    height: auto; // Maintain aspect ratio
  }

`;

const WelcomeMessage = styled(Typography)`
  margin-bottom: 2rem;
  color:#000000;
  font-family: "aktiv-grotesk", Arial, sans-serif !important; /* Fallback to Arial or sans-serif */
  font-weight: 900;
`;

const Form = styled.form`
  flex-wrap: wrap;
  justify-content: flex-start; // Changed from space-between to flex-start
  max-width: 500px;
  width: 100%;
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 10px 25px -5px rgba(0, 0, 0, 0.2);
  margin-bottom: 50px;
  @media (max-width: 600px) {
    max-width: 90%; // Allow the form to scale down
    padding: 1rem;
  }

  @media (max-width: 480px) {
    max-height: calc(100vh - 80px);// Further scale down the form on very small screens
    padding: 1rem;
  }
  
`;

const FormField = styled(TextField)`
  margin-bottom: 1rem;
  width: 100%; // Subtract padding from the total width
  padding: 10px; // Add padding to prevent text from touching the edges
  box-sizing: border-box; // Ensures padding is included in width
  border-radius: 50px;
`;

const FieldContainer = styled.div`
  width: 100%; // adjust the width as needed
`;

const ActionButton = styled(Button)`
  font-family: "aktiv-grotesk", Arial, sans-serif !important; /* Fallback to Arial or sans-serif */
  margin-top: 1rem;
  border-radius: 90px;
  color: white;
  

  &:hover {
    background: linear-gradient(45deg, #5982DB 30%, #5982DB 90%);
  }

  &:active {
    transform: scale(0.98);
    transition: all .1s ease-in-out;
  }
  && {
    text-transform: Capitalize;
  }
`;

const StyledDropzone = styled.div`
border: 2px dashed #cccccc;
border-radius: 4px;
padding: 20px;
text-align: center;
cursor: pointer;
transition: border 0.3s ease;
width: 50%; // Added to make width smaller
height: 80px; 

&:hover {
  border: 2px dashed #0000cc;
}
`;

const RemoveButton = styled.button`
background-color: transparent;
border: none;
cursor: pointer;
font-size: 16px;
margin-left: 10px;
color: red;
`;

const LinkContainer = styled.div`
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    background-color: #f4f4f4;
    border-radius: 5px;
`;

const LinkText = styled.span`   // changed from 'a' to 'span' since it's not a hyperlink
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
`;

const RemoveLinkButton = styled.button`
    background-color: #a8a8a8;
    color: white;
    border: none;
    margin-left: 10px;
    border-radius: 5px;
    padding: 0 8px;
    cursor: pointer;
`;

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    //overflow: hidden; // Prevent horizontal scrolling
  }

  // Your other styles...
`;

const TitleLabel = styled.div`
  font-weight: bold; // Bold font weight for title
  margin-bottom: 5px;
  color: #808080;

  @media (max-width: 768px) {
    font-size: 14px; // Smaller font size on mobile
  }

  @media (max-width: 321px) {
    font-size: 12px; // Even smaller font size for very small screens
  }
`;

const SubtitleLabel = styled.div`
  font-weight: normal; // Normal font weight for subtitle
  color: #808080;

  @media (max-width: 768px) {
    font-size: 14px; // Smaller font size on mobile
  }

  @media (max-width: 321px) {
    font-size: 12px; // Even smaller font size for very small screens
  }
`;




const countries = [
    'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua and Barbuda', 'Argentina',
    'Armenia', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados',
    'Belarus', 'Belgium', 'Belize', 'Benin', 'Bhutan', 'Bolivia', 'Bosnia and Herzegovina', 'Botswana',
    'Brazil', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Côte d\'Ivoire', 'Cabo Verde',
    'Cambodia', 'Cameroon', 'Canada', 'Central African Republic', 'Chad', 'Chile', 'China', 'Colombia',
    'Comoros', 'Congo', 'Costa Rica', 'Croatia', 'Cuba', 'Cyprus', 'Czechia', 'Democratic Republic of the Congo',
    'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea',
    'Eritrea', 'Estonia', 'Eswatini', 'Ethiopia', 'Fiji', 'Finland', 'France', 'Gabon', 'Gambia', 'Georgia',
    'Germany', 'Ghana', 'Greece', 'Grenada', 'Guatemala', 'Guinea', 'Guinea-Bissau', 'Guyana', 'Haiti', 'Holy See',
    'Honduras', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Israel', 'Italy',
    'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia',
    'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Madagascar', 'Malawi',
    'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Mauritania', 'Mauritius', 'Mexico', 'Micronesia',
    'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Morocco', 'Mozambique', 'Myanmar', 'Namibia', 'Nauru', 'Nepal',
    'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'North Korea', 'North Macedonia', 'Norway', 'Oman',
    'Pakistan', 'Palau', 'Palestine State', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland',
    'Portugal', 'Qatar', 'Romania', 'Russia', 'Rwanda', 'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Vincent and the Grenadines',
    'Samoa', 'San Marino', 'Sao Tome and Principe', 'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone',
    'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia', 'South Africa', 'South Korea', 'South Sudan',
    'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Sweden', 'Switzerland', 'Syria', 'Tajikistan', 'Tanzania', 'Thailand',
    'Timor-Leste', 'Togo', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Tuvalu', 'Uganda', 'Ukraine',
    'United Arab Emirates', 'United Kingdom', 'United States of America', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Venezuela',
    'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe'
];


const specialtiesList = [
    'Alternative Medicine', 'Anaesthesia', 'Bariatric Equipment', 'Cardiology', 'Clinical Consumables',
    'Continence', 'Cosmetic and Plastic', 'Critical Care', 'Dermatology', 'Diabetes', 'Diagnostics',
    'Dialysis/Kidney/Renal', 'Disability-Assistive Products', 'Emergency Care', 'Endoscopy',
    'Gastroenterology', 'General Surgery', 'Gynecology & Obstetrics', 'ICU', 'Imaging/Radiology',
    'Infection Control', 'Infrastructure/Furniture/Logistics', 'IT/Data Analytics/Telemedicine',
    'Laboratory/Testing', 'Medical Equipment', 'Neonatal', 'Neurology', 'Neonatal/Pediatric Care',
    'Oncology', 'Ophthalmology', 'Orthopedics', 'Otorhinolaryngology (ENT)', 'Patient Monitoring/AI',
    'Rehabilitation/Physiotherapy', 'Respiratory', 'Simulation', 'Sterile Services', 'Surgery',
    'Textiles/Medical Clothing/PPE', 'Urology', 'Wound Care',
];

const getYears = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 1899 }, (v, i) => 1900 + i);
    return years;
};

const sizes = [
    { value: 'Small', label: 'Small (1-50)' },
    { value: 'Medium', label: 'Medium (51-200)' },
    { value: 'Large', label: 'Large (201-500)' },
    { value: 'Expanding', label: ' (500+)' },
];

const ManufacturerSignup = () => {
    const [file, setFile] = useState(null);

    const [formData, setFormData] = useState({
        companyWebsite: '',
        companyHQ: '',
        companySize: '',
        yearFounded: '',
        clinicalSegment: [],
        companyLogo: '',
    });

    const navigate = useNavigate();

    // Handles changes in form inputs
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: name === 'yearFounded' ? String(value) : value
        }));
    };

    // Handles form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        const manufacturerId = localStorage.getItem('manufacturerId');
        const companyID = localStorage.getItem('manufCompanyId');
        const productID = localStorage.getItem('manufProductId');

        //Debugging 
        //console.log("manufacturerId:", manufacturerId) 
        //console.log("CompanyID:", companyID)
        //console.log("ProductID:", productID)

        if (file) {
            const fileName = `${Date.now()}-${file.name}`;
            //console.log(fileName, file, file.type);
        
            try {
                await Storage.put(fileName, file, {
                    contentType: file.type,
                });
        
                const url = fileName;
                //console.log('File uploaded and retrieved:', url);
        
                // Update formData with the S3 URL
                formData.companyLogo = url
            } catch (error) {
                console.error('Error with AWS Storage:', error);
            }
        }


        const updatedData = {
            id: manufacturerId,
            companyID: companyID,
            productID: productID,
            ...formData,
        }

        try {
            //console.log("formData:", formData)
            const result = await API.graphql(graphqlOperation(updateManufacturer, { input: updatedData }));
            //console.log("result:", result)
            localStorage.setItem('manufacturerSignupData', JSON.stringify(updatedData));
            //console.log('Partial data saved successfully');
            navigate('/ManufacturerSignup2');

        } catch (error) {
            console.error("Error updating manufacturer:", error);
        }
    };

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const fileSelected = e.target.files[0];
            setFile(fileSelected);
        }
    };

     // Custom dropzone component for file upload
    const FileDropzone = ({ onFileChange }) => {
        const { getRootProps, getInputProps } = useDropzone({
            onDrop: acceptedFiles => {
                setFile(acceptedFiles[0]);
            }
        });
        if (file) return null;
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <StyledDropzone {...getRootProps()}>
                    <input {...getInputProps()} />
                    <img src="/upload-icon.png" width="22" height="22"></img>
                    <TitleLabel>
                        Upload your company logo
                    </TitleLabel>
                    <SubtitleLabel>Drag & drop a file here, or click to select one</SubtitleLabel>
                </StyledDropzone>
                {file && (
                    <>
                        <span>{file.name}</span>
                        <RemoveButton onClick={handleFileRemove}>X</RemoveButton>
                    </>
                )}
            </div>
        );
    };
    const handleFileRemove = () => {
        setFile(null);
    
        // Clear the companyLogo field in formData
        setFormData((prevState) => ({
            ...prevState,
            companyLogo: '',
        }));
    };

    return (
        <>
       <GlobalStyle/>
        <Container>
            <a href="https://medikana.io">
                <Logo src="/medikanaLogoWithName.png" alt="Logo"></Logo>
            </a>
            <Form onSubmit={handleSubmit}>
                <WelcomeMessage variant="h4" color="primary" style={{ marginBottom: '15px', fontSize: '28px', color: "#000000" }}>
                    About Your Company
                </WelcomeMessage>
                <FieldContainer>
                    <FormField
                        label="Website"
                        type="url"
                        name="companyWebsite"
                        value={formData.companyWebsite}
                        onChange={handleInputChange}
                        variant="outlined"
                        required
                        style={{ marginBottom: '15px' }}
                    />
                </FieldContainer>
                <FieldContainer>
                    <FormField
                        select
                        name="companyHQ"
                        label="Where is your company's headquarters?"
                        variant="outlined"
                        required
                        onChange={handleInputChange}
                        value={formData.companyHQ}
                        style={{ marginBottom: '15px' }}
                    >
                        {countries.map((country) => (
                            <MenuItem key={country} value={country}>
                                {country}
                            </MenuItem>
                        ))}
                    </FormField>
                </FieldContainer>
                <FieldContainer>
                    <FormField
                        select
                        name="companySize"
                        label="Company Size"
                        variant="outlined"
                        required
                        onChange={handleInputChange}
                        value={formData.companySize}
                        style={{ marginBottom: '15px' }}
                    >
                        {sizes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </FormField>
                </FieldContainer>
                <FieldContainer>
                    <FormField
                        select
                        name="yearFounded"
                        label="Year Founded"
                        variant="outlined"
                        required
                        onChange={handleInputChange}
                        value={formData.yearFounded}
                        style={{ marginBottom: '15px' }}
                    >
                        {getYears().map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </FormField>
                </FieldContainer>
                <FieldContainer>
                    <FormControl variant="outlined" required fullWidth>
                        <InputLabel id="territories-label" htmlFor="select-multiple-checkbox" >
                            Clinical Specialty
                        </InputLabel>
                        <Select
                            labelId="clinicalSegment-label"
                            id="clinicalSegment"
                            multiple
                            name="clinicalSegment"
                            required
                            value={formData.clinicalSegment}
                            onChange={handleInputChange}
                            input={<OutlinedInput id="select-multiple-checkbox" label="Clinical Specialty" />}
                            renderValue={(selected) => selected.join(', ')}
                            style={{ marginBottom: '15px'}}
                        >
                            {specialtiesList.map((specialty) => (
                                <MenuItem key={specialty} value={specialty}>
                                    <Checkbox checked={formData.clinicalSegment.includes(specialty)} />
                                    <ListItemText primary={specialty} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </FieldContainer>
                <FieldContainer>
                    <FileDropzone onFileChange={handleFileChange} type="file" />
                    {file && (
                        <LinkContainer>
                            <LinkText>{file.name}</LinkText>
                            <RemoveLinkButton onClick={handleFileRemove}>X</RemoveLinkButton>
                        </LinkContainer>
                    )}
                </FieldContainer>
                <ActionButton variant="contained" type="submit" style={{ width: '30%', borderRadius: '10px', marginRight: '10px', backgroundColor: "#0086ff", color: "white", marginTop: '15px' }}>
                    Continue
                </ActionButton>
            </Form>
        </Container>
        </>
    );
};

export default ManufacturerSignup;

