import React from 'react';
import styled from 'styled-components';

const FooterContainer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background: #f2f2f2;
  color: #555555;
  font-size: 14px;
`;

const Logo = styled.img`
  width: 150px;
  height: 50px;
`;

const SocialMediaLinks = styled.div`
  display: flex;
  align-items: center;

  a {
    margin-right: 10px;
    color: #555555;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #333333;
    }
  }
`;

const FooterText = styled.p`
  margin: 0;

  a {
    color: #555555;
    text-decoration: none;
    transition: color 0.3s ease;

    &:hover {
      color: #333333;
    }
  }
`;

const Footer = () => {
    return (
        <FooterContainer>
            <a href="https://medikana.io">
                <Logo src="/medikanaLogoWithName.png" alt="Logo"></Logo>
            </a>
            <SocialMediaLinks>
                <a href="https://www.facebook.com/medikana" target="_blank" rel="noopener noreferrer">
                    Facebook
                </a>
                <a href="https://www.instagram.com/medikana" target="_blank" rel="noopener noreferrer">
                    Instagram
                </a>
                <a href="https://www.twitter.com/medikana" target="_blank" rel="noopener noreferrer">
                    Twitter
                </a>
            </SocialMediaLinks>
            <FooterText>
                © 2023 medikana, inc. All rights reserved.
                <br />
                <a href="/privacy-policy">Privacy Policy</a> | <a href="/terms-of-service">Terms of Service</a>
            </FooterText>
        </FooterContainer>
    );
};

export default Footer;
