//Not included in site
import { Auth } from 'aws-amplify';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const ConfirmSignUpPage = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const confirmSignUp = async () => {
      const username = searchParams.get('user_name');
      const confirmationCode = searchParams.get('confirmation_code');

      try {
        await Auth.confirmSignUp(username, confirmationCode);
        // After confirmation, inform the user or redirect to login
        alert('Your account has been confirmed!');
        // Redirect to the login page after a short delay
        setTimeout(() => {
          window.location.href = '/login';
        }, 3000); // 3 seconds delay
      } catch (error) {
        console.error('Error confirming sign up', error);
        alert('Failed to confirm your account. Please try again.');
      }
    };

    confirmSignUp();
  }, [searchParams]);

  return (
    <div>
      Confirming your account...
      {/* Add a spinner or any loading indicator here */}
    </div>
  );
};

export default ConfirmSignUpPage;
