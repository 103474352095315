const regulationsMexico = {
    country: "Mexico",
    countryCode: 'MX',
    deviceTypes: {
        "Medical Device": {
            "Classes": ["Class I", "Class II", "Class III"]
        },
        "In Vitro Device": {
            "Classes": ["Class I", "Class II", "Class III"]
        }
    },
    timeline: "Timeframe for the department to notify the applicant about an issue in their application : 10 Business days. Timeframe for the user to respond to the notification: 5 Business days. Time for the department to make a decision: 30 Business days.  5 years of validity From the date of issuance. ",
    cost: {
        "Class I": "$770.93",
        "Class II": "$1,122.92",
        "Class III": "$1,434.46",
    },

    requirements: {
        sanitaryRegistration: {
            "Proof of Payment of Fees. Original and 2 Copies.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III"],
                    "In Vitro Device": ["Class I", "Class II", "Class III"],
                }
            },
            "Labels. In Spanish: Original.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III"],
                    "In Vitro Device": ["Class I", "Class II", "Class III"],
                }
            },
            "Instruction Manual. In Spanish: Copy.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III"],
                    "In Vitro Device": ["Class I", "Class II", "Class III"],
                }
            },
            "Certificate of Good Manufacturing Practices. Issued by the health authority of the country of origin: Original.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III"],
                    "In Vitro Device": ["Class I", "Class II", "Class III"],
                }
            },
            "Format of Authorizations, Certificates, and Site Visits. Original.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III"],
                    "In Vitro Device": ["Class I", "Class II", "Class III"],
                }
            },
            "Technical Literature/Information. Technical and Scientific information. Original.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III"],
                    "In Vitro Device": ["Class I", "Class II", "Class III"],
                }
            },
            "Description of the Manufacturing Process. Copy.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III"],
                    "In Vitro Device": ["Class I", "Class II", "Class III"],
                }
            },
            "Detailed Product Description. The structure, materials, parts, and functions: Copy. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III"],
                    "In Vitro Device": ["Class I", "Class II", "Class III"],
                }
            },
            "Laboratory Tests. Copy.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III"],
                    "In Vitro Device": ["Class I", "Class II", "Class III"],
                }
            },
            "Bibliographic References. Copy.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III"],
                    "In Vitro Device": ["Class I", "Class II", "Class III"],
                }
            },
            "Other Documents as per the Health Secretariat (SSA). Copy.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III"],
                    "In Vitro Device": ["Class I", "Class II", "Class III"],
                }
            },
            "Free Sale Certificate. Or equivalent, issued by the health authority of the country of origin: Original": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III"],
                    "In Vitro Device": ["Class I", "Class II", "Class III"],
                }
            },
            "Letter from the Manufacturer. Manufacturer's representation letter, if the product is not manufactured by the parent company, factory, or laboratory that applies for the registration in Mexico. Original. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III"],
                    "In Vitro Device": ["Class I", "Class II", "Class III"],
                }
            },
            "Certificate of Analysis. Issued by the company that manufactures the product, with the letterhead of its corporate name and signed by the responsible chemists of the foreign company. Original.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III"],
                    "In Vitro Device": ["Class I", "Class II", "Class III"],
                }
            },
            "Proof of Operating Authorization for Manufacturer and/or Importer Company. Notice of operation: Copy": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III"],
                    "In Vitro Device": ["Class I", "Class II", "Class III"],
                }
            },
            "Notice of Responsible Health Officer. Copy.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III"],
                    "In Vitro Device": ["Class I", "Class II", "Class III"],
                }
            },
            // "Letter from the Manufacturer. The manufacturer's representation letter, if the product is not manufactured by the parent company, factory, or laboratory that applies for the registration in Mexico.": {
            //     appliesTo: {
            //         "Medical Device": ["Class I", "Class II", "Class III"],
            //         "In Vitro Device": ["Class I", "Class II", "Class III"],
            //     }
            // }, appears twice
            // "The original certificate of analysis issued by the company that manufactures the product, with the letterhead of its corporate name and signed by the responsible chemists of the foreign company.": {
            //     appliesTo: {
            //         "Medical Device": ["Class I", "Class II", "Class III"],
            //         "In Vitro Device": ["Class I", "Class II", "Class III"],
            //     }
            // }, appears twice

        },
    }
};

export default regulationsMexico;