// DistributorSignupDetailed2.jsx
import React, { useState } from 'react';
import styled from 'styled-components';
import { API, graphqlOperation } from 'aws-amplify';
import { updateTodo } from './graphql/mutations';
import { useNavigate } from 'react-router-dom';
import Footer from './footer'
import Checkbox from '@material-ui/core/Checkbox';
import { Button, TextField, MenuItem, Typography, Select, InputLabel, OutlinedInput, FormControl, ListItemText } from '@material-ui/core';
import { createGlobalStyle } from 'styled-components';


const Container = styled.div`
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  height: calc(100vh - 70px);
  width: 100vw; // Use the full width of the viewport
  margin: 0;
  border-radius: 0;
  background: url('/background.png') no-repeat center center fixed; 
  background-size: cover; // Ensure that one image covers the full container
  overflow-y: auto; 
  overflow-x: hidden;
  padding-bottom: 40px
`;


const Logo = styled.img`
  width: 350px;
  height: 130px;
  margin-bottom: 1rem;
  margin-right: 1.5rem;
  @media (max-width: 600px) {
    width: 300px; // Smaller width for smaller screens
    height: auto; // Maintain aspect ratio
  }

  @media (max-width: 480px) {
    width: 240px; // Even smaller width for mobile screens
    height: auto; // Maintain aspect ratio
  }

`;

const WelcomeMessage = styled(Typography)`
  margin-bottom: 2rem;
  color:#000000;
  font-family: "aktiv-grotesk", Arial, sans-serif !important; /* Fallback to Arial or sans-serif */
  font-weight: 900;
`;

const Form = styled.form`
  flex-wrap: wrap;
  justify-content: flex-start; // Changed from space-between to flex-start
  max-width: 500px;
  min-width: 500px
  width: 100%;
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 10px 25px -5px rgba(0, 0, 0, 0.2);
  margin-bottom: 50px;
  @media (max-width: 600px) {
    max-width: 90%; // Allow the form to scale down
    padding: 1rem;
  }

  @media (max-width: 480px) {
    max-height: calc(100vh - 80px);// Further scale down the form on very small screens
    padding: 1rem;
  }
  
`;

const FormField = styled(TextField)`
  margin-bottom: 1rem;
  width: 100%; // Changed from 51% to 100%
  border-radius: 50px;

  &:focus {
    border-color: #000000;
    box-shadow: 0 0 1.25rem rgba(74, 86, 226, .5);
    transition: box-shadow .2s ease, border-color .2s ease;

  }

`;

const FieldContainer = styled.div`
  width: 100%; // adjust the width as needed
`;


const ActionButton = styled(Button)`
  font-family: "aktiv-grotesk", Arial, sans-serif !important; /* Fallback to Arial or sans-serif */
  margin-top: 1rem;
  border-radius: 90px;
  background-color: #0086ff; // Changed color here
  color: white;

  &:hover {
    background: linear-gradient(45deg, #0075db 30%, #0075db 90%); // Changed color here
  }

  &:active {
    transform: scale(0.98);
    transition: all .1s ease-in-out;
  }
  && {
    text-transform: Capitalize;
  }
`;

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden; // Prevent horizontal scrolling
  }

  // Your other styles...
`;

const specialtiesList = [
    'Alternative Medicine',
    'Anaesthesia',
    'Bariatric Equipment',
    'Cardiology',
    'Clinical Consumables',
    'Continence',
    'Cosmetic and Plastic',
    'Critcal Care',
    'Dermatology',
    'Diabetes',
    'Diagnostics',
    'Dialysis/Kidney/Renal',
    'Disability-Assistive Products',
    'Emergency Care',
    'Endoscopy',
    'Gastroenterology',
    'General Surgery',
    'Gynaecology & Obstetrics',
    'ICU',
    'Imaging/Radiology',
    'Infection Control',
    'Infrastructure/Furniture/Logistics',
    'IT/Data Analytics/Telemedicine',
    'Laboratory/Testing',
    'Medical Equipment',
    'Neonatal',
    'Neurology',
    'Neonatal/Paediatric Care',
    'Oncology',
    'Ophtalmology',
    'Orthopaedics',
    'Otorhinolaryngology (ENT)',
    'Patient Monitoring/AI',
    'Rehabilitation/Physiotherapy',
    'Respiratory',
    'Simulation',
    'Sterile Services',
    'Surgery',
    'Textiles/Medical Clothing/PPE',
    'Urology',
    'Wound Care',
];


const DistributorSignupDetailed2 = () => {
    const distributorSignupData = JSON.parse(localStorage.getItem('distributorSignupData'));
    const [formData, setFormData] = useState({
        ...distributorSignupData,
        brands: '',
        specialties: [],
        desiredSpecialties: [],
        certificates: ''
    });

    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const distributorId = localStorage.getItem('distributorId');
        //console.log('Distributor ID:', distributorId); // log the distributor ID

        if (!distributorId) {
            //console.error('Distributor ID not found in local storage');
            return;
        }

        const updatedData = {
            id: distributorId, // get the saved id from localStorage
            ...formData,
        };

        //console.log(updatedData);

        try {
            await API.graphql(graphqlOperation(updateTodo, { input: updatedData }));
            localStorage.removeItem('distributorSignupData'); // Remove the data from localStorage.
            navigate('/success');
            localStorage.clear()
        } catch (error) {
            //console.error('Error saving data', error);
        }
    };
    

    return (
        <div>
            <GlobalStyle/>
            <Container>
                <a href="https://medikana.io">
                    <Logo src="/medikanaLogoWithName.png" alt="Logo"></Logo>
                </a>
                <Form onSubmit={handleSubmit}>
                    <WelcomeMessage variant="h4" color="primary" style={{ marginBottom: '15px', fontSize: '28px', color:"#000000" }}>
                        About Your Product
                    </WelcomeMessage>
                    <FormField name="brands" label="Brands or products you currently distribute" variant="outlined" required onChange={handleInputChange} value={formData.brands} style={{ marginBottom: '15px' }} />
                    <FormControl variant="outlined" required fullWidth style={{ marginBottom: '15px' }}>
                        <InputLabel id="specialties-label" htmlFor="select-multiple-checkbox">Specialties of products you currently distribute</InputLabel>
                        <Select
                            labelId="specialties-label"
                            id="specialties"
                            multiple
                            name="specialties"
                            required
                            value={formData.specialties}
                            onChange={handleInputChange}
                            input={<OutlinedInput id="select-multiple-checkbox" label="Specialties of products you currently distribute" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: '50vh',
                                        width: '40%',
                                    },
                                },
                            }}
                        >
                            {specialtiesList.map((specialty) => (
                                <MenuItem key={specialty} value={specialty}>
                                    <Checkbox checked={formData.specialties  && formData.specialties.indexOf(specialty) > -1}  style={{color: '#a85dff'}}/>
                                    <ListItemText primary={specialty} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl variant="outlined" required fullWidth style={{ marginBottom: '15px' }}>
                        <InputLabel id="desiredSpecialties-label" htmlFor="select-multiple-checkbox">Specialties of products you would like to distribute</InputLabel>
                        <Select
                            labelId="desiredSpecialties-label"
                            id="desiredSpecialties"
                            multiple
                            name="desiredSpecialties"
                            required
                            value={formData.desiredSpecialties}
                            onChange={handleInputChange}
                            input={<OutlinedInput id="select-multiple-checkbox" label="Specialties of products you would like to distribute" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: '50vh',
                                        width: '40%',
                                    },
                                },
                            }}
                        >
                            {specialtiesList.map((desiredSpecialty) => (
                                <MenuItem key={desiredSpecialty} value={desiredSpecialty}>
                                    <Checkbox checked={formData.desiredSpecialties  && formData.desiredSpecialties.indexOf(desiredSpecialty) > -1}  style={{color: '#a85dff'}} />
                                    <ListItemText primary={desiredSpecialty} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormField
                        name="certificates"
                        label="Certificates or credentials"
                        variant="outlined"
                        onChange={handleInputChange}
                        value={formData.certificates}
                        style={{ marginBottom: '30px' }}
                    />
                    <ActionButton variant="contained" color="primary" type="submit" style={{ width: '30%', borderRadius: '10px', marginRight: '10px', backgroundColor:"#0086ff", color:"white" }}>
                        Continue
                    </ActionButton>
                </Form>
            </Container>
        </div>
    );
};



export default DistributorSignupDetailed2;

