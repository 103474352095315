import React, { useState } from 'react';
import styled from 'styled-components';
import { API, graphqlOperation } from 'aws-amplify';
import { updateManufacturer, createProducts, updateProducts } from './graphql/mutations'; // use createDistributor mutation
import { Button, TextField, MenuItem, Typography, InputLabel } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { Storage } from 'aws-amplify';
import Grid from '@material-ui/core/Grid';
import { createGlobalStyle } from 'styled-components';

const Container = styled.div`
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  height: calc(100vh - 70px);
  width: 100vw; // Use the full width of the viewport
  margin: 0;
  border-radius: 0;
  background: url('/background.png') no-repeat center center fixed; 
  background-size: cover; // Ensure that one image covers the full container
  overflow-y: auto; 
  overflow-x: hidden;
  padding-bottom: 40px
`;


const Logo = styled.img`
  width: 350px;
  height: 130px;
  margin-bottom: 1rem;
  margin-right: 1.5rem;
  @media (max-width: 600px) {
    width: 300px; // Smaller width for smaller screens
    height: auto; // Maintain aspect ratio
  }

  @media (max-width: 480px) {
    width: 200px; // Even smaller width for mobile screens
    height: auto; // Maintain aspect ratio
  }

`;


const WelcomeMessage = styled(Typography)`
  margin-bottom: 2rem;
  color:#000000;
  font-family: "aktiv-grotesk", Arial, sans-serif !important; /* Fallback to Arial or sans-serif */
  font-weight: 900;
`;

const Form = styled.form`
  flex-wrap: wrap;
  justify-content: flex-start; // Changed from space-between to flex-start
  max-width: 500px;
  width: 100%;
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 10px 25px -5px rgba(0, 0, 0, 0.2);
  margin-bottom: 50px;
  @media (max-width: 600px) {
    max-width: 90%; // Allow the form to scale down
    padding: 1rem;
  }

  @media (max-width: 480px) {
    max-height: calc(100vh - 80px);// Further scale down the form on very small screens
    padding: 1rem;
  }
  
`;

const FormField = styled(TextField)`
  margin-bottom: 1rem;
  width: 100%; // Subtract padding from the total width
  padding: 10px; // Add padding to prevent text from touching the edges
  box-sizing: border-box; // Ensures padding is included in width
  border-radius: 50px;
`;

const FieldContainer = styled.div`
  width: 100%; // adjust the width as needed
`;

const ActionButton = styled(Button)`
  font-family: "aktiv-grotesk", Arial, sans-serif !important; /* Fallback to Arial or sans-serif */
  margin-top: 1rem;
  border-radius: 90px;
  color: white;
  

  &:hover {
    background: linear-gradient(45deg, #5982DB 30%, #5982DB 90%);
  }

  &:active {
    transform: scale(0.98);
    transition: all .1s ease-in-out;
  }
  && {
    text-transform: Capitalize;
  }
`;


const StyledDropzone = styled.div`
    border: 2px dashed #cccccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: border 0.3s ease;
    width: 50%; // Added to make width smaller
    height: 90px; 
    margin-bottom: 10px;

    &:hover {
    border: 2px dashed #0000cc;
    }
`;

const LinkContainer = styled.div`
    display: inline-flex;
    align-items: center;
    padding: 5px 10px;
    background-color: #f4f4f4;
    margin: 5px;
    border-radius: 5px;
`;

const LinkText = styled.a`
    max-width: 300px;  // or another suitable value
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
`;

const RemoveLinkButton = styled.button`
    background-color: #a8a8a8;
    color: white;
    border: none;
    margin-left: 10px;
    border-radius: 5px;
    padding: 0 8px;
    cursor: pointer;
`;

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    //overflow: hidden; // Prevent horizontal scrolling
  }

  // Your other styles...
`;

const TitleLabel = styled.div`
  font-weight: bold; // Bold font weight for title
  margin-bottom: 5px;
  color: #808080;
  
  @media (max-width: 768px) {
    font-size: 14px; // Smaller font size on mobile
  }

  @media (max-width: 321px) {
    font-size: 12px; // Even smaller font size for very small screens
  }
`;

const SubtitleLabel = styled.div`
  font-weight: normal; // Normal font weight for subtitle
  color: #808080;
  @media (max-width: 768px) {
    font-size: 14px; // Smaller font size on mobile
  }

  @media (max-width: 321px) {
    font-size: 12px; // Even smaller font size for very small screens
  }
`;

const currencies = [
    { label: 'USD ($)', value: 'USD' },      // United States Dollar
    { label: 'EUR (€)', value: 'EUR' },      // Euro
    { label: 'JPY (¥)', value: 'JPY' },      // Japanese Yen
    { label: 'CNY (¥)', value: 'CNY' },      // Chinese Yuan Renminbi
    { label: 'INR (₹)', value: 'INR' },      // Indian Rupee
    { label: 'GBP (£)', value: 'GBP' },      // British Pound Sterling
];


const ManufacturerSignup3 = () => {
    const [formData, setFormData] = useState({
        clinicalIndications: '',
        competitors: '',
        targetDemographic: '',
        currency: '',
        transferPriceMin: 0,
        transferPriceMax: 0,
        distributionChannels: '',
        relatedLinks: [],
        otherDocuments: '',
    });

    const [currency, setSelectedCurrency] = useState('USD'); // default currency
    const [linkInput, setLinkInput] = useState('');  // Temporary state for the current link being inputted
    const [relatedLinks, setRelatedLinks] = useState([]);  // Array to store multiple links
    const [files, setFiles] = useState([]);

    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const manufacturerId = localStorage.getItem('manufacturerId');
        const companyID = localStorage.getItem('manufCompanyId');
        const productID = localStorage.getItem('manufProductId');

        let urls = [];
        for (const file of files) {
            const fileName = `${Date.now()}-${file.name}`;

            try {
                await Storage.put(fileName, file, {
                    contentType: file.type,
                });
                urls.push(fileName);


            } catch (error) {
                console.error('Error uploading the file:', error);
                return;
            }
        }
        formData.otherDocuments = urls

        const productData = {
            id: manufacturerId,
            companyName: formData.companyName, // Add this if companyName is part of formData
            clinicalIndications: formData.clinicalIndications,
            competitors: formData.competitors,
            targetDemographic: formData.targetDemographic,
            currency: formData.currency,
            transferPriceMin: formData.transferPriceMin,
            transferPriceMax: formData.transferPriceMax,
            distributionChannels: formData.distributionChannels,
            relatedLinks: formData.relatedLinks,
            otherDocuments: formData.otherDocuments,
            // ... other fields as per your form data
        }

        try {
            const productCreationResponse = await API.graphql(graphqlOperation(updateProducts, { input: productData }));
            console.log('Product updated successfully:', productCreationResponse);

            const updatedData = {
                id: manufacturerId,
                companyID: companyID,
                productID: productID,
                ...formData,
                relatedLinks: relatedLinks,
            }

            const result = await API.graphql(graphqlOperation(updateManufacturer, { input: updatedData }));
            console.log('Manufacturer updated successfully:', result);

            localStorage.setItem('manufacturerSignupData', JSON.stringify(updatedData));
            localStorage.setItem('manufacturerId', updatedData.id);
            localStorage.setItem('userType', 'Manufacturer')
            //console.log('Partial data saved successfully');
            navigate('/success');
            // navigate('/dashboard');

        } catch (error) {
            console.error("Error updating manufacturer:", error);
        }
    };

    const handleCurrencyChange = (event) => {
        setSelectedCurrency(event.target.value);
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleLinkInputChange = (event) => {
        setLinkInput(event.target.value);
    };

    const handleLinkInputKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (linkInput) {
                const newLinks = [...relatedLinks, linkInput];
                setRelatedLinks(newLinks);
                setFormData({ ...formData, relatedLinks: newLinks }); // Update the formData state here
                setLinkInput('');
            }
        }
    };

    const handleRemoveLink = (linkToRemove) => {
        const newLinks = relatedLinks.filter(link => link !== linkToRemove);
        setRelatedLinks(newLinks);
        setFormData({ ...formData, relatedLinks: newLinks }); // Update the formData state here too
    };

    const handleFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            setFiles(prevFiles => [...prevFiles, ...Array.from(e.target.files)]);
        }
    };

    const FileDropzone = ({ onFileChange }) => {
        const { getRootProps, getInputProps } = useDropzone({
            onDrop: acceptedFiles => {
                setFiles(prevFiles => [...prevFiles, ...acceptedFiles]);
            },
            multiple: true
        });

        return (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                <StyledDropzone {...getRootProps()}>
                    <input {...getInputProps()} />
                    <img src="/upload-icon.png" width="22" height="22"></img>
                    <TitleLabel >
                        Upload clinical or marketing documents
                    </TitleLabel>
                    <SubtitleLabel style={{ paddingBottom: '10px' }}>Drag & drop your files here, or click to select them</SubtitleLabel>
                </StyledDropzone>
            </div>
        );
    };

    const handleFileRemove = (index) => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    };

    return (
        <>
            <GlobalStyle />
            <Container>
                <a href="https://medikana.io">
                    <Logo src="/medikanaLogoWithName.png" alt="Logo"></Logo>
                </a>
                <Form onSubmit={handleSubmit}>
                    <WelcomeMessage variant="h4" color="primary" style={{ marginBottom: '15px', fontSize: '28px', color: "#000000" }}>
                        Other Product Details
                    </WelcomeMessage>
                    <FieldContainer>
                        <FormField
                            label="Applicable Clinical Indications"
                            multiline // Add this line to make it multi-line
                            rows={3}
                            name="clinicalIndications"
                            value={formData.clinicalIndications}
                            onChange={handleInputChange}
                            variant="outlined"
                            required
                            style={{ marginBottom: '15px' }}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <FormField
                            label="Applicable Competitors"
                            type="text"
                            name="competitors"
                            value={formData.competitors}
                            onChange={handleInputChange}
                            variant="outlined"
                            style={{ marginBottom: '15px' }}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <FormField
                            label="Target Demographic (If applicable)"
                            type="text"
                            name="targetDemographic"
                            value={formData.targetDemographic}
                            onChange={handleInputChange}
                            variant="outlined"
                            style={{ marginBottom: '15px' }}
                        />
                    </FieldContainer>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <FieldContainer>
                                <FormField
                                    select
                                    label="Currency"
                                    name="currency"
                                    onChange={handleCurrencyChange}
                                    value={formData.currency}
                                    variant="outlined"
                                >
                                    {currencies.map((curr) => (
                                        <MenuItem key={curr.value} value={curr.value}>
                                            {curr.label}
                                        </MenuItem>
                                    ))}
                                </FormField>
                            </FieldContainer>
                        </Grid>
                        <Grid item xs={4}>
                            <FieldContainer>
                                <FormField
                                    label="Min Transfer Price"
                                    type="text"
                                    name="transferPriceMin"
                                    value={formData.transferPriceMin}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    style={{ marginBottom: '15px', paddingRight: '20px' }}

                                />
                            </FieldContainer>
                        </Grid>
                        <Grid item xs={4}>
                            <FieldContainer>
                                <FormField
                                    label="Max Transfer Price"
                                    type="text"
                                    name="transferPriceMax"
                                    value={formData.transferPriceMax}
                                    onChange={handleInputChange}
                                    variant="outlined"
                                    style={{ paddingRight: '20px' }}
                                />
                            </FieldContainer>
                        </Grid>
                    </Grid>
                    <FieldContainer>
                        <FormField
                            label="Existing Distribution Channels"
                            type="text"
                            name="distributionChannels"
                            value={formData.distributionChannels}
                            onChange={handleInputChange}
                            variant="outlined"
                            style={{ marginBottom: '15px' }}
                        />
                    </FieldContainer>
                    <FieldContainer>
                        <FormField
                            label="Links to related journal, press article, etc (Press Enter to add)"
                            type="text"
                            name="relatedLinkInput"
                            value={linkInput}
                            onChange={handleLinkInputChange}
                            onKeyDown={handleLinkInputKeyDown}
                            variant="outlined"
                            style={{ marginBottom: '10px' }}
                        />
                        <div>
                            {relatedLinks.map(link => {
                                const absoluteLink = link.startsWith('http://') || link.startsWith('https://') ? link : 'http://' + link;

                                return (
                                    <LinkContainer key={link} style={{ marginBottom: '10px' }}>
                                        <LinkText href={absoluteLink} target="_blank" rel="noopener noreferrer">{link}</LinkText>
                                        <RemoveLinkButton onClick={() => handleRemoveLink(link)}>X</RemoveLinkButton>
                                    </LinkContainer>
                                );
                            })}
                        </div>
                    </FieldContainer>
                    <input type="hidden" name="relatedLinks" value={formData.relatedLinks} />
                    <FieldContainer>
                        <FileDropzone onFileChange={handleFileChange} />
                        {files.map((file, index) => (
                            <LinkContainer key={index} style={{ marginBottom: '5px' }}>
                                <LinkText>{file.name}</LinkText>
                                <RemoveLinkButton onClick={() => handleFileRemove(index)}>X</RemoveLinkButton>
                            </LinkContainer>
                        ))}
                    </FieldContainer>
                    <ActionButton variant="contained" type="submit" style={{ width: '30%', borderRadius: '10px', marginRight: '10px', backgroundColor: "#0086ff", color: "white", marginTop: '5px' }}>
                        Continue
                    </ActionButton>
                </Form>
            </Container>
        </>
    );
};

export default ManufacturerSignup3;
