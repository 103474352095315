import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { API, graphqlOperation } from 'aws-amplify';
import { createTodo, updateTodo } from './graphql/mutations'; // use createDistributor mutation
import { Button, TextField, MenuItem, Typography, Select, InputLabel, OutlinedInput, FormControl, ListItemText, Input, Switch } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import { createGlobalStyle } from 'styled-components';

const Container = styled.div`
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 50px;
  height: calc(100vh - 70px);
  width: 100vw; // Use the full width of the viewport
  margin: 0;
  border-radius: 0;
  background: url('/background.png') no-repeat center center fixed; 
  background-size: cover; // Ensure that one image covers the full container
  overflow-y: auto; 
  overflow-x: hidden;
  padding-bottom: 40px
`;


const Logo = styled.img`
  width: 350px;
  height: 130px;
  margin-bottom: 1rem;
  margin-right: 1.5rem;
  @media (max-width: 600px) {
    width: 300px; // Smaller width for smaller screens
    height: auto; // Maintain aspect ratio
  }

  @media (max-width: 480px) {
    width: 240px; // Even smaller width for mobile screens
    height: auto; // Maintain aspect ratio
  }

`;

const WelcomeMessage = styled(Typography)`
  margin-bottom: 2rem;
  color:#000000;
  font-family: "aktiv-grotesk", Arial, sans-serif !important; /* Fallback to Arial or sans-serif */
  font-weight: 900;
`;

const Form = styled.form`
  flex-wrap: wrap;
  justify-content: flex-start; // Changed from space-between to flex-start
  max-width: 500px;
  min-width: 500px
  width: 100%;
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 10px 25px -5px rgba(0, 0, 0, 0.2);
  margin-bottom: 50px;
  @media (max-width: 600px) {
    max-width: 90%; // Allow the form to scale down
    padding: 1rem;
  }

  @media (max-width: 480px) {
    max-height: calc(100vh - 80px);// Further scale down the form on very small screens
    padding: 1rem;
  }
  
`;

const FormField = styled(TextField)`
  margin-bottom: 1rem;
  width: 100%; // Changed from 51% to 100%
  border-radius: 50px;

  &:focus {
    border-color: #000000;
    box-shadow: 0 0 1.25rem rgba(74, 86, 226, .5);
    transition: box-shadow .2s ease, border-color .2s ease;

  }

`;
const ActionButton = styled(Button)`
  font-family: "aktiv-grotesk", Arial, sans-serif !important; /* Fallback to Arial or sans-serif */
  margin-top: 1rem;
  border-radius: 90px;
  background-color: #0086ff; // Changed color here
  color: white;

  &:hover {
    background: linear-gradient(45deg, #0075db 30%, #0075db 90%); // Changed color here
  }

  &:active {
    transform: scale(0.98);
    transition: all .1s ease-in-out;
  }
  && {
    text-transform: Capitalize;
  }
`;

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden; // Prevent horizontal scrolling
  }

  // Your other styles...
`;


// List of Latin American countries

const countryCodes = [
    { country: 'Argentina', code: '+54' },
    { country: 'Bolivia', code: '+591' },
    { country: 'Brazil', code: '+55' },
    { country: 'Chile', code: '+56' },
    { country: 'Colombia', code: '+57' },
    { country: 'Costa Rica', code: '+506' },
    { country: 'Cuba', code: '+53' },
    { country: 'Dominican Republic', code: '+1' },
    { country: 'Ecuador', code: '+593' },
    { country: 'El Salvador', code: '+503' },
    { country: 'Guatemala', code: '+502' },
    { country: 'Honduras', code: '+504' },
    { country: 'Mexico', code: '+52' },
    { country: 'Nicaragua', code: '+505' },
    { country: 'Panama', code: '+507' },
    { country: 'Paraguay', code: '+595' },
    { country: 'Peru', code: '+51' },
    { country: 'Puerto Rico', code: '+1' },
    { country: 'Uruguay', code: '+598' },
    { country: 'Venezuela', code: '+58' },
    { country: 'USA', code: '+1' },
];


const latinAmericanCountries = [
    'Argentina', 'Bolivia', 'Brazil', 'Chile', 'Colombia', 'Costa Rica', 'Cuba', 'Dominican Republic',
    'Ecuador', 'El Salvador', 'Guatemala', 'Honduras', 'Mexico', 'Nicaragua', 'Panama', 'Paraguay',
    'Peru', 'Puerto Rico', 'Uruguay', 'Venezuela',
];

const specialtiesList = [
    'Alternative Medicine',
    'Anaesthesia',
    'Bariatric Equipment',
    'Cardiology',
    'Clinical Consumables',
    'Continence',
    'Cosmetic and Plastic',
    'Critical Care',
    'Dermatology',
    'Diabetes',
    'Diagnostics',
    'Dialysis/Kidney/Renal',
    'Disability-Assistive Products',
    'Emergency Care',
    'Endoscopy',
    'Gastroenterology',
    'General Surgery',
    'Gynecology & Obstetrics',
    'ICU',
    'Imaging/Radiology',
    'Infection Control',
    'Infrastructure/Furniture/Logistics',
    'IT/Data Analytics/Telemedicine',
    'Laboratory/Testing',
    'Medical Equipment',
    'Neonatal',
    'Neurology',
    'Neonatal/Pediatric Care',
    'Oncology',
    'Ophthalmology',
    'Orthopedics',
    'Otorhinolaryngology (ENT)',
    'Patient Monitoring/AI',
    'Rehabilitation/Physiotherapy',
    'Respiratory',
    'Simulation',
    'Sterile Services',
    'Surgery',
    'Textiles/Medical Clothing/PPE',
    'Urology',
    'Wound Care',
];

// Function to generate a list of years from 1900 to current year
const getYears = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 1899 }, (v, i) => 1900 + i);
    return years;
};

const sizes = [
    { value: 'Small', label: 'Small (1-50)' },
    { value: 'Medium', label: 'Medium (51-200)' },
    { value: 'Large', label: 'Large (201-500)' },
    { value: 'Expanding', label: ' (500+)' },
];

const scope = [
    { value: 'Regional', label: 'Regional' },
    { value: 'National', label: 'National' },
    { value: 'Multinational', label: 'Multinational' },
];

const jobRoles = [
    { value: 'Management/Owner/C-Level', label: 'Management/Owner/C-Level' },
    { value: 'Engineer/Designer', label: 'Engineer/Designer' },
    { value: 'Regulatory Affairs Specialist', label: 'Regulatory Affairs Specialists' },
    { value: 'Sales and Marketing Team', label: 'Sales and Marketing Team' },
    { value: 'Supply Chain and Logistics Professionals', label: 'Supply Chain and Logistics Professionals' },
    { value: 'Customer/Technical Support Representative', label: 'Customer/Technical Support Representative' },
    { value: 'Data Scientists/Analysts', label: 'Data Scientists/Analysts' },
    { value: 'Other', label: 'Other' },

];

const DistributorSignupDetailed1 = () => {

    const [formData, setFormData] = useState({
        jobRole: '',
        phoneNumber: '',
        website: '',
        companySize: '',
        foundedYear: '',
        companyHQ: '',
        territory: [],
        scope: '',
        brands: '',
        specialties: [],
        desiredSpecialties: [],
        certificates: ''
    });


    const navigate = useNavigate();

    const [countryCode, setCountryCode] = useState(null);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        //console.log("Form Data before submission:", formData);
        // Combine existing and new data
        const distributorData = localStorage.getItem('distributorData');
        const distributorId = localStorage.getItem('distributorId');
        //console.log("DistribId", distributorId);
        const distributorEmail = localStorage.getItem('distributorEmail');

        //console.log('distributor data', distributorData)
        // console.log( 'distributorId', distributorId)

        const updatedData = {
            id: distributorId,
            ...formData,
            phoneNumber: `${countryCode} ${formData.phoneNumber}`,  // Join countryCode and phoneNumber
            brands: null,
            specialties: null,
            desiredSpecialties: null,
            certificates: null
        };

        //console.log("form data:", formData)
        //console.log('Distributor Data:', updatedData);
        try {

            const result = await API.graphql(graphqlOperation(updateTodo, { input: updatedData }));
            //console.log("API call result:", result); 
            localStorage.setItem('distributorSignupData', JSON.stringify(updatedData));
            //console.log('Partial data saved to localStorage:', updatedData); // Log the data saved to localStorage
            navigate('/distributor2');

        } catch (error) {
            console.error("Error during API call:", error);
        }
    };

    return (
        <div>
            <GlobalStyle/>
            <Container >
                <a href="https://medikana.io">
                    <Logo src="/medikanaLogoWithName.png" alt="Logo"></Logo>
                </a>
                <Form onSubmit={handleSubmit}>
                    <WelcomeMessage variant="h4" color="primary" style={{ marginBottom: '15px', fontSize: '28px', color:"#000000" }}>
                        About Your Company
                    </WelcomeMessage>
                    <FormField
                        select
                        name="jobRole"
                        label="Job Role"
                        variant="outlined"
                        required
                        onChange={handleInputChange}
                        value={formData.jobRole}
                        style={{ marginBottom: '15px' }}
                    >
                        {jobRoles.map((role) => (
                            <MenuItem key={role.value} value={role.value}>
                                {role.label}
                            </MenuItem>
                        ))}
                    </FormField>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>  {/* Adjusted size */}
                            <FormField
                                select
                                name="countryCode"
                                label="Country Code"
                                variant="outlined"
                                required
                                value={countryCode}
                                onChange={event => setCountryCode(event.target.value)}
                            >
                                {countryCodes.map((option) => (
                                    <MenuItem key={option.code} value={option.code}>
                                        {`${option.code} (${option.country})`}
                                    </MenuItem>
                                ))}
                            </FormField>
                        </Grid>
                        <Grid item xs={12} sm={8}>  {/* Adjusted size */}
                            <FormField
                                name="phoneNumber"
                                label="Phone Number"
                                variant="outlined"
                                required
                                onChange={handleInputChange}
                                value={formData.phoneNumber}
                                style={{ marginBottom: '15px' }}
                            />
                        </Grid>
                    </Grid>

                    <FormField
                        name="website"
                        label="Website"
                        variant="outlined"
                        onChange={handleInputChange}
                        value={formData.website}
                        style={{ marginBottom: '15px' }}
                    />
                    <FormField
                        select
                        name="companySize"
                        label="Company Size"
                        variant="outlined"
                        required
                        onChange={handleInputChange}
                        value={formData.companySize}
                        style={{ marginBottom: '15px' }}
                    >
                        {sizes.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </FormField>
                    <FormField
                        select
                        name="foundedYear"
                        label="Founded Year"
                        variant="outlined"
                        required
                        onChange={handleInputChange}
                        value={formData.foundedYear}
                        style={{ marginBottom: '15px' }}
                    >
                        {getYears().map((year) => (
                            <MenuItem key={year} value={year}>
                                {year}
                            </MenuItem>
                        ))}
                    </FormField>
                    <FormField
                        select
                        name="companyHQ"
                        label="Where is your company's headquarters?"
                        variant="outlined"
                        required
                        onChange={handleInputChange}
                        value={formData.companyHQ}
                        style={{ marginBottom: '15px' }}
                    >
                        {latinAmericanCountries.map((country) => (
                            <MenuItem key={country} value={country}>
                                {country}
                            </MenuItem>
                        ))}
                    </FormField>
                    <FormControl variant="outlined" required fullWidth style={{ marginBottom: '15px' }}>
                        <InputLabel id="territories-label" htmlFor="select-multiple-checkbox">What countries do you distribute in?</InputLabel>
                        <Select
                            labelId="territories-label"
                            id="territory"
                            multiple
                            name="territory"
                            value={formData.territory}
                            onChange={handleInputChange}
                            input={<OutlinedInput id="select-multiple-checkbox" label="What countries do you distribute in?" />}
                            renderValue={(selected) => selected.join(', ')}
                            MenuProps={{
                                PaperProps: {
                                    style: {
                                        maxHeight: '50vh',
                                        width: '40%',
                                    },
                                },
                            }}
                        >
                            {latinAmericanCountries.map((country) => (
                                <MenuItem key={country} value={country}>
                                    <Checkbox checked={formData.territory && formData.territory.indexOf(country) > -1} style={{color: '#a85dff'}} />
                                    <ListItemText primary={country} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormField
                        select
                        name="scope"
                        label="Scope of distribution"
                        variant="outlined"
                        onChange={handleInputChange}
                        value={formData.scope}
                        style={{ marginBottom: '15px' }}
                    >
                        {scope.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </FormField>
                    <ActionButton variant="contained" type="submit" style={{ width: '30%', borderRadius: '10px', marginRight: '10px', backgroundColor:"#0086ff", color:"white"}}>
                        Continue
                    </ActionButton>
                </Form>
            </Container>
        </div>
    );
};

export default DistributorSignupDetailed1;