import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import { Typography, TextField, Button } from '@material-ui/core';
import { listTodos } from './graphql/queries';
import { listManufacturers } from './graphql/queries';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createGlobalStyle } from 'styled-components';
import AWS from 'aws-sdk';
import { useUser } from './UserContext';


const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: 50px;
  height: calc(100vh - 70px);
  width: 100vw; // Use the full width of the viewport
  margin: 0;
  border-radius: 0;
  background: url('/background.png') no-repeat center center fixed; 
  background-size: cover; // Ensure that one image covers the full container
  overflow-y: auto; 
  overflow-x: hidden;
  padding-bottom: 20px
`;

const Logo = styled.img`
  width: 350px;
  height: 130px;
  margin-bottom: 1rem;
  margin-right: 1.5rem;
`;


const Form = styled.form`
  flex-wrap: wrap;
  justify-content: flex-start; // Changed from space-between to flex-start
  max-width: 500px;
  width: 100%;
  background: #ffffff;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0px 10px 25px -5px rgba(0, 0, 0, 0.2);
  
`;

const ActionButton = styled(Button)`
  font-family: "aktiv-grotesk", Arial, sans-serif !important; /* Fallback to Arial or sans-serif */
  margin-top: 1rem;
  border-radius: 90px;
  background-color: #0086ff; // Changed color here
  color: white;

  &:hover {
    background: #b0b0b0; /* Darker grey on hover */
  }

  &:active {
    transform: scale(0.98);
    transition: all .1s ease-in-out;
  }
  && {
    text-transform: Capitalize;
  }
`;

const ResendButton = styled(Button)`
  font-family: "aktiv-grotesk", Arial, sans-serif !important; /* Fallback to Arial or sans-serif */
  margin-top: 1rem;
  border-radius: 90px;
  background-color: #0086ff; // Changed color here
  color: white;

  &:hover {
    background: linear-gradient(45deg, #0075db 30%, #0075db 90%); // Changed color here
  }

  &:active {
    transform: scale(0.98);
    transition: all .1s ease-in-out;
  }
  && {
    text-transform: Capitalize;
  }
  `;


const FieldContainer = styled.div`
  width: 100%; // adjust the width as needed
`;

const GlobalStyle = createGlobalStyle`
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden; // Prevent horizontal scrolling
  }

  // Your other styles...
`;


const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { userId, userType, setUserId, setUserType, isAdmin, setIsAdmin, isManuf, setIsManuf } = useUser();
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const location = useLocation();
  const [resendConfirmation, setResendConfirmation] = useState(false);


  useEffect(() => {
    // Check if the URL has the 'confirmed' query parameter
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('confirmed') === 'true') {
      setConfirmationMessage('Your account has been confirmed. You can now log in.');
    }
  }, [location]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const confirmed = searchParams.get('confirmed');
    if (confirmed === 'false') {
      setErrorMessage('Confirmation unsuccessful. Please try again or contact hello@medikana.io.');
    }
  }, [location]);

  const navigate = useNavigate();

  const handleButtonClick = async (evt) => {
    evt.preventDefault();
    await handleLogin();
  };

  const handleLogin = async (evt) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false); // Reset loading to false after a delay
    }, 3000); // 3000 milliseconds delay

    try {
      const user = await Auth.signIn(email, password);
      const adminEmails = ['mariapaula@medikana.io', 'corina@medikana.io', 'isabella@medikana.io', 'mcnegron@mit.edu'];

      let userEmail = user.attributes.email;
      //console.log("Signed-in user's email:", userEmail);

      let allData;
      let userData;

      const isAdmin = adminEmails.includes(userEmail);

      //console.log("Is user an admin (before setting state):", isAdmin);
      setIsAdmin(isAdmin);
      sessionStorage.setItem('isAdmin', isAdmin.toString());

      //console.log("isAdmin state after setting:", isAdmin); // Log admin status after setting the state
      //console.log("isAdmin in session storage:", sessionStorage.getItem('isAdmin')); // Log admin status in session storage


      if (user.attributes['custom:userType'] === 'manufacturer') {
        allData = await API.graphql(graphqlOperation(listManufacturers)); // Fetch manufacturer data
        setUserType('Manufacturer');
        setIsManuf(true);
        userData = allData.data.listManufacturers.items.find(item => {
          userEmail = user.challengeParam
            ? user.challengeParam.userAttributes.email
            : user.attributes?.email;

          if (!userEmail) return false;

          return item.email === userEmail;
        });
      } else {
        allData = await API.graphql(graphqlOperation(listTodos)); // Fetch distributor data
        setUserType('Distributor'); // Example, adjust as per your logic
        setIsManuf(false);
        userData = allData.data.listTodos.items.find(item => {
          userEmail = user.challengeParam
            ? user.challengeParam.userAttributes.email
            : user.attributes?.email;


          if (!userEmail) return false;

          return item.email === userEmail;
        });

      }
      //console.log(userData)

      //Debugging 

      //console.log(userData.companyID, userData.productID)

      if (userData) {
        // assuming userType is a property on userData
        const userType = userData.userType;

        if (userType === 'Manufacturer') {
          setUserType('Manufacturer');
          setUserId(userData.id)
          //console.log(userId)
          localStorage.setItem('manufacturerData', JSON.stringify(userData));
          localStorage.setItem('manufacturerId', userData.id)
          localStorage.setItem('userType', userType)
          localStorage.setItem('manufProductId', userData.productID)
          localStorage.setItem('manufCompanyId', userData.companyID)

          if (!userData.companyWebsite) {
            navigate("/ManufacturerSignup");
          } else if (!userData.productName) {
            navigate("/ManufacturerSignup2");
          } else if (!userData.clinicalIndications) {
            navigate("/ManufacturerSignup3");
          } else {
            navigate("/dashboard");
          }

        } else if (userType === 'Distributor') {
          setUserType('Distributor');
          if (userData.jobRole) {
            setUserId(userData.id)
            localStorage.setItem('distributorData', JSON.stringify(userData));
            localStorage.setItem('distributorId', userData.id)
            localStorage.setItem('userType', userType)
            navigate("/dashboard");
          } else {
            localStorage.setItem('distributorData', JSON.stringify(userData));
            localStorage.setItem('distributorId', userData.id)
            localStorage.setItem('userType', userType)
            navigate("/distributor");

          }
        } else {
          console.error('Unknown user type');
          setErrorMessage('Unknown user type. Please contact support.');
        }
      } else {
        console.error('No matching user data found');
        Auth.signOut();
        setErrorMessage('No matching user data found. Please sign up.');
      }

    } catch (signInError) {
      console.error('Error signing in:', signInError);
      if (signInError.code === 'UserNotFoundException') {
        setErrorMessage('No account found with this email. Please sign up.');
      } else if (signInError.code === 'NotAuthorizedException') {
        setErrorMessage('Incorrect username or password.');
      }
      else if (signInError.code === 'InvalidParameterException') {
        setErrorMessage('Incorrect username or password.');

      }
      else if (signInError.code === 'UserNotConfirmedException') {
        // Inform the user their account is not confirmed, without resending the email
        setErrorMessage('Your account is not confirmed. Please check your email for the confirmation link or contact support if you need assistance.');

        setLoading(false);
      } else {
        setErrorMessage(signInError.message || 'Having trouble signing in? Contact us at hello@medikana.io');
      }
      if (signInError && signInError.code === 'UserNotConfirmedException') {
        // Set to true when user needs to confirm
      }
      if (signInError.code === 'UserNotConfirmedException') {
        setErrorMessage('Your account is not confirmed. Please check your email for the confirmation link or resend the confirmation link.');
        setResendConfirmation(true); // Directly indicate that the user can resend the confirmation link
      } else {
        setResendConfirmation(false); // For any other errors, ensure resend option is not shown
      }

    } finally {
      setLoading(false);
    }
  }

  const handleResendConfirmation = async () => {
    try {
      setLoading(true);
      await Auth.resendSignUp(email); // This will trigger your CognitoCustomMessage Lambda function
      setConfirmationMessage('A new confirmation link has been sent to your email.');
      setErrorMessage('')
    } catch (error) {
      console.error('Error resending confirmation link: ', error);
      setErrorMessage(error.message || 'Error resending confirmation link.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <GlobalStyle />
      <Container onSubmit={handleLogin}>
        <a href="https://medikana.io">
          <Logo src="/medikanaLogoWithName.png" alt="Logo"></Logo>
        </a>
        <Form style={{ padding: '30px', margin: 'auto', marginTop: '20px', maxWidth: '500px' }}>
          <div><img src="/welcome-back.jpg" alt="Login" style={{ width: '210px', height: '36px', marginBottom: '5px' }} /> </div>
          <FieldContainer>
            <div style={{ position: 'relative' }}>  {/* This is the relative container */}
              <TextField
                fullWidth
                margin="normal"
                label="Email"
                variant="outlined"
                type="email"
                onChange={(evt) => setEmail(evt.target.value)}
              />
              <TextField
                fullWidth
                margin="normal"
                label="Password"
                variant="outlined"
                type="password"
                onChange={(evt) => setPassword(evt.target.value)}
              />
              <a
                href="/" // Replace with your password reset route
                onClick={(e) => { e.preventDefault(); navigate('/forgotPassword'); }}
                style={{
                  color: '#0086ff',
                  cursor: 'pointer',
                  position: 'absolute',   // This makes the link positioned relative to the container            // Centers the link vertically
                  right: '0',             // Places it to the far right
                  transform: 'translateY(-50%)', // Fine-tune the vertical centering
                  marginTop: '45px',
                  marginRight: '10px',
                  fontSize: ' 15px',

                }}
              >
                Forgot Password?
              </a>
            </div>
          </FieldContainer>

          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', width: '35%', marginBottom: '15px' }}> {/* Adjust width as needed */}
              <ActionButton variant="contained" type="submit" disabled={loading} onClick={handleButtonClick} style={{ width: '100%', borderRadius: '20px', marginRight: '10px', backgroundColor: "#0086ff", color: 'white', marginTop: '10px' }}>
                Login
              </ActionButton>
              {loading && <CircularProgress size={25} style={{ marginTop: '9px', color: '#0086ff' }} />}
            </div>
            {resendConfirmation && (
              <div>
                <ActionButton variant="contained" type="submit" disabled={loading} onClick={handleResendConfirmation} style={{ width: '100%', borderRadius: '20px', marginRight: '10px', backgroundColor: "#4daaff", color: 'white', marginBottom:'5px' }}>Resend Confirmation Link</ActionButton>
              </div>
            )}


          </div>

          <a
            href="/" // Replace with your signup route
            onClick={(e) => { e.preventDefault(); navigate('/'); }}
            style={{ textDecoration: 'underline', color: '#0086ff', cursor: 'pointer', marginTop: '5px', fontSize: '15px' }}
          >
            Don’t have an account?
            Register instead here.
          </a>

          {errorMessage && (
            <Typography
              variant="body2"
              style={{
                marginTop: '16px',
                textAlign: 'center',
                color: errorMessage.includes('Thank you for visiting us! Our login feature will be ready soon.') ? '#0086ff' : 'red'
              }}
            >
              {errorMessage}
            </Typography>
          )}
          {confirmationMessage && (
            <Typography variant="body2" style={{ marginTop: '16px', textAlign: 'center', color: 'green' }}>
              {confirmationMessage}
            </Typography>
          )}

        </Form>
      </Container>
    </div>
  );
}

export default LoginPage;
