/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createTodo = /* GraphQL */ `
  mutation CreateTodo(
    $input: CreateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    createTodo(input: $input, condition: $condition) {
      email
      id
      name
      companyName
      jobRole
      phoneNumber
      website
      companySize
      foundedYear
      territory
      companyHQ
      scope
      brands
      specialties
      desiredSpecialties
      certificates
      userType
      createdAtUnix
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateTodo = /* GraphQL */ `
  mutation UpdateTodo(
    $input: UpdateTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    updateTodo(input: $input, condition: $condition) {
      email
      id
      name
      companyName
      jobRole
      phoneNumber
      website
      companySize
      foundedYear
      territory
      companyHQ
      scope
      brands
      specialties
      desiredSpecialties
      certificates
      userType
      createdAtUnix
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteTodo = /* GraphQL */ `
  mutation DeleteTodo(
    $input: DeleteTodoInput!
    $condition: ModelTodoConditionInput
  ) {
    deleteTodo(input: $input, condition: $condition) {
      email
      id
      name
      companyName
      jobRole
      phoneNumber
      website
      companySize
      foundedYear
      territory
      companyHQ
      scope
      brands
      specialties
      desiredSpecialties
      certificates
      userType
      createdAtUnix
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createManufacturer = /* GraphQL */ `
  mutation CreateManufacturer(
    $input: CreateManufacturerInput!
    $condition: ModelManufacturerConditionInput
  ) {
    createManufacturer(input: $input, condition: $condition) {
      companyID
      productID
      email
      userType
      id
      name
      companyName
      companyWebsite
      companyHQ
      companySize
      yearFounded
      clinicalSegment
      companyLogo
      productName
      productDescription
      competitiveAdvantage
      developmentStage
      regulatoryApprovals
      approvalDocuments
      clinicalIndications
      competitors
      productType
      targetDemographic
      currency
      transferPriceMin
      transferPriceMax
      distributionChannels
      relatedLinks
      otherDocuments
      createdAtUnix
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateManufacturer = /* GraphQL */ `
  mutation UpdateManufacturer(
    $input: UpdateManufacturerInput!
    $condition: ModelManufacturerConditionInput
  ) {
    updateManufacturer(input: $input, condition: $condition) {
      companyID
      productID
      email
      userType
      id
      name
      companyName
      companyWebsite
      companyHQ
      companySize
      yearFounded
      clinicalSegment
      companyLogo
      productName
      productDescription
      competitiveAdvantage
      developmentStage
      regulatoryApprovals
      approvalDocuments
      clinicalIndications
      competitors
      productType
      targetDemographic
      currency
      transferPriceMin
      transferPriceMax
      distributionChannels
      relatedLinks
      otherDocuments
      createdAtUnix
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteManufacturer = /* GraphQL */ `
  mutation DeleteManufacturer(
    $input: DeleteManufacturerInput!
    $condition: ModelManufacturerConditionInput
  ) {
    deleteManufacturer(input: $input, condition: $condition) {
      companyID
      productID
      email
      userType
      id
      name
      companyName
      companyWebsite
      companyHQ
      companySize
      yearFounded
      clinicalSegment
      companyLogo
      productName
      productDescription
      competitiveAdvantage
      developmentStage
      regulatoryApprovals
      approvalDocuments
      clinicalIndications
      competitors
      productType
      targetDemographic
      currency
      transferPriceMin
      transferPriceMax
      distributionChannels
      relatedLinks
      otherDocuments
      createdAtUnix
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createRegulatoryProfile = /* GraphQL */ `
  mutation CreateRegulatoryProfile(
    $input: CreateRegulatoryProfileInput!
    $condition: ModelRegulatoryProfileConditionInput
  ) {
    createRegulatoryProfile(input: $input, condition: $condition) {
      id
      productId
      companyId
      AR
      BR
      CL
      CO
      CR
      GT
      EC
      MX
      PA
      PE
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateRegulatoryProfile = /* GraphQL */ `
  mutation UpdateRegulatoryProfile(
    $input: UpdateRegulatoryProfileInput!
    $condition: ModelRegulatoryProfileConditionInput
  ) {
    updateRegulatoryProfile(input: $input, condition: $condition) {
      id
      productId
      companyId
      AR
      BR
      CL
      CO
      CR
      GT
      EC
      MX
      PA
      PE
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteRegulatoryProfile = /* GraphQL */ `
  mutation DeleteRegulatoryProfile(
    $input: DeleteRegulatoryProfileInput!
    $condition: ModelRegulatoryProfileConditionInput
  ) {
    deleteRegulatoryProfile(input: $input, condition: $condition) {
      id
      productId
      companyId
      AR
      BR
      CL
      CO
      CR
      GT
      EC
      MX
      PA
      PE
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createProducts = /* GraphQL */ `
  mutation CreateProducts(
    $input: CreateProductsInput!
    $condition: ModelProductsConditionInput
  ) {
    createProducts(input: $input, condition: $condition) {
      id
      productId
      companyId
      companyName
      productName
      productDescription
      productType
      competitiveAdvantage
      developmentStage
      regulatoryApprovals
      approvalDocuments
      clinicalIndications
      competitors
      targetDemographic
      currency
      transferPriceMin
      transferPriceMax
      distributionChannels
      relatedLinks
      otherDocuments
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProducts = /* GraphQL */ `
  mutation UpdateProducts(
    $input: UpdateProductsInput!
    $condition: ModelProductsConditionInput
  ) {
    updateProducts(input: $input, condition: $condition) {
      id
      productId
      companyId
      companyName
      productName
      productDescription
      productType
      competitiveAdvantage
      developmentStage
      regulatoryApprovals
      approvalDocuments
      clinicalIndications
      competitors
      targetDemographic
      currency
      transferPriceMin
      transferPriceMax
      distributionChannels
      relatedLinks
      otherDocuments
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProducts = /* GraphQL */ `
  mutation DeleteProducts(
    $input: DeleteProductsInput!
    $condition: ModelProductsConditionInput
  ) {
    deleteProducts(input: $input, condition: $condition) {
      id
      productId
      companyId
      companyName
      productName
      productDescription
      productType
      competitiveAdvantage
      developmentStage
      regulatoryApprovals
      approvalDocuments
      clinicalIndications
      competitors
      targetDemographic
      currency
      transferPriceMin
      transferPriceMax
      distributionChannels
      relatedLinks
      otherDocuments
      createdAt
      updatedAt
      __typename
    }
  }
`;
