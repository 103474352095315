import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import countryRegulations from './regulations'; // Import country regulations
import { Amplify, Auth, API, graphqlOperation } from 'aws-amplify';
import Sidebar from './sideBar';
import ReactCountryFlag from "react-country-flag";
import { getManufacturer } from './graphql/queries'; // adjust your path
import { useUser } from './UserContext';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { S3Client, GetObjectCommand, PutObjectCommand } from '@aws-sdk/client-s3';
import { createRegulatoryProfile, updateRegulatoryProfile } from './graphql/mutations'; // Adjust your path
import { getRegulatoryProfile } from './graphql/queries';
import { Button as MuiButton } from '@material-ui/core';
import styled from 'styled-components';

const styles = {
    container: {
        display: 'flex',
        minHeight: '100vh'
    },
    content: {
        flex: 1,
        padding: '20px',
        backgroundColor: '#f4f4f4', // Light grey background for the content area
    },
    title: {
        color: '#333', // Dark grey color for text
        marginBottom: '20px',
    },
    section: {
        backgroundColor: '#fff', // White background for each section
        padding: '15px',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Subtle shadow for depth
        marginBottom: '20px',
    },
    sectionTitle: {
        color: '#0056b3', // Blue color for section titles
        marginBottom: '10px',
    },
    requirement: {
        marginBottom: '15px',
        borderBottom: '1px solid #eee', // Light line for separating requirements
        paddingBottom: '10px',
    },
    fileInput: {
        border: '1px solid #ddd',
        borderRadius: '4px',
        padding: '10px',
        marginRight: '10px',
    },
    uploadButton: {
        backgroundColor: '#4caf50', // Updated button color
        color: 'white',
        padding: '10px 15px',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        transition: 'background-color 0.2s',
    },
};

const StyledFileButton = styled(MuiButton)`
  font-family: "aktiv-grotesk" !important;
  margin-top: 1rem;
  border-radius: 20px;
  background-color: #4caf50; // You can choose your color
  color: white;
  padding: 10px 15px;

  &:hover {
    background: linear-gradient(45deg, #43a047 30%, #66bb6a 90%);
  }

  &:active {
    transform: scale(0.98);
    transition: all .1s ease-in-out;
  }

  && {
    text-transform: capitalize;
  }
`;

const getCountryCode = (countryName) => {
    // This is a simple example; you may need a more comprehensive list or a better mapping strategy
    const countryCodes = {
        "Argentina": "AR",
        "Brasil": "BR",
        "Chile": "CL",
        "Colombia": "CO",
        "Costa Rica": "CR",
        "Guatemala": "GT",
        "Ecuador": "EC",
        "Mexico": "MX",
        "Panama": "PA",
        "Peru": "PE",
        // ... add other countries as needed ...
    };


    return countryCodes[countryName];
};

const countryLinks = {
    Argentina: {
        regulatoryEntity: "https://www.argentina.gob.ar/anmat/regulados/productos-medicos",
        lawDocument: "http://servicios.infoleg.gob.ar/infolegInternet/anexos/90000-94999/93486/norma.htm"
    },
    Brasil: {
        regulatoryEntity: "https://www.gov.br/anvisa/pt-br",
        lawDocument: "https://bvsms.saude.gov.br/bvs/saudelegis/anvisa/2001/rdc0185_22_10_2001.pdf"
    },
    // ... Add other countries as needed ...
};

async function fetchUserData(userId) {
    try {
        console.log(userId)
        const userDataResponse = await API.graphql(graphqlOperation(getManufacturer, { id: userId }));
        if (userDataResponse.data.getManufacturer) {
            return {
                companyId: userDataResponse.data.getManufacturer.companyID,
                productId: userDataResponse.data.getManufacturer.productID,
            };
        } else {
            console.error("Manufacturer data not found");
            return null;
        }
    } catch (err) {
        console.error("Error fetching user data:", err);
        throw err;
    }
}


const FileUploadPage = () => {
    const { country } = useParams();
    const navigate = useNavigate();
    const countryInfo = countryRegulations.find(c => c.country.toLowerCase() === country.toLowerCase());
    const countryCode = getCountryCode(countryInfo.country);
    const { userId, userType, setUserId, setUserType } = useUser();
    const [localUserId, setLocalUserId] = useState(userId || localStorage.getItem('manufacturerId') || localStorage.getItem('distributorId'));
    const [companyId, setCompanyId] = useState(null);
    const [productId, setProductId] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState({});
    const [error, setError] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState({});
    const [uploadStatus, setUploadStatus] = useState({});



    useEffect(() => {
        // Update localUserId if userId from context changes
        if (userId) {
            setLocalUserId(userId);
        }
    }, [userId])

    useEffect(() => {
        // Fetch regulatory profile and set uploaded files
        const fetchAndSetUploadedFiles = async () => {
            try {
                const profileData = await API.graphql(graphqlOperation(getRegulatoryProfile, { id: localUserId }));
                const profile = profileData.data.getRegulatoryProfile;
                const filesInCountry = profile[countryCode];

                if (filesInCountry) {
                    const urls = await Promise.all(filesInCountry.map(async (fileKey) => {
                        const url = await fetchPresignedURL(fileKey);
                        return { key: fileKey, url };
                    }));

                    setUploadedFiles(urls.reduce((acc, file) => ({ ...acc, [file.key]: file.url }), {}));
                }
            } catch (error) {
                console.error("Error fetching uploaded files:", error);
            }
        };

        if (localUserId) {
            fetchAndSetUploadedFiles();
        }
    }, [localUserId, countryCode]);

    const handleFileSelection = (requirementName, files) => {
        setSelectedFiles(prevFiles => ({
            ...prevFiles,
            [requirementName]: files
        }));
    };

    const handleUpload = async (requirementName, files) => {
        // Use localUserId in your function
        if (!localUserId) {
            console.error("UserId is null, cannot proceed with upload");
            return;
        }

        let currentCompanyId = companyId;
        let currentProductId = productId;

        if (!companyId || !productId) {
            const freshData = await fetchUserData(localUserId);
            if (freshData) {
                setCompanyId(freshData.companyId); // Update state
                setProductId(freshData.productId); // Update state
                console.log("Fetched fresh company and product IDs:", freshData.companyId, freshData.productId);

                currentCompanyId = freshData.companyId;
                currentProductId = freshData.productId;
            } else {
                throw new Error("Failed to fetch company or product ID");
            }
        }

        if (!files || files.length === 0) {
            console.log("No files selected for upload:", requirementName);
            return;
        }

        for (const file of files) {
            // Use currentCompanyId and currentProductId here
            console.log("Uploading file:", file.name);
            console.log("ProductID :", currentProductId);
            console.log("CompanyID :", currentCompanyId);

            const documentId = `DOC-${requirementName}-${Date.now()}`;
            const s3Key = `${currentCompanyId}#${currentProductId}/${countryCode}#${documentId}-${file.name}`;

            const uploadResult = await uploadFileToS3(file, s3Key);

            if (uploadResult.success) {
                console.log("File uploaded successfully to S3 with key:", uploadResult.s3Key);
                //console.log("companyId sent to saveFileReferenceToDatabase", productId)
                await saveFileReferenceToDatabase(uploadResult.s3Key, currentProductId, currentProductId, countryInfo);
                console.log("File reference saved to database:", uploadResult.s3Key);

                const url = await fetchPresignedURL(uploadResult.s3Key);
                if (url) {
                    setUploadedFiles(prevFiles => ({
                        ...prevFiles,
                        [s3Key]: url // Merge the new file into the uploadedFiles state
                    }));
                }
            } else {
                console.log("Failed to upload file to S3:", file.name);
            }
        }
        setSelectedFiles(prevFiles => ({
            ...prevFiles,
            [requirementName]: []
        }));

        //setUploadProgress(prevProgress => prevProgress + (100 / Object.keys(countryInfo.requirements).length));
    };

    const uploadFileToS3 = async (file, fileName) => {
        const s3Client = new S3Client({
            region: 'us-east-1',
            credentials: {
                accessKeyId: "AKIAWH47LBWIJ52QVAHC",
                secretAccessKey: "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"
            }
        });

        try {
            const command = new PutObjectCommand({
                Bucket: 'manufactureruploads171127-dev',
                Key: `public/${fileName}`,
                Body: file
            });

            await s3Client.send(command);

            console.log("S3 Key for uploaded file:", fileName);
            return { success: true, s3Key: fileName };

        } catch (error) {
            console.error("Error uploading file to S3:", error);
            return { success: false };
        }
    };

    const fetchPresignedURL = async (fileName) => {
        if (!fileName) {
            console.error("FileName is missing.");
            return null;
        }

        try {
            // Setup the S3 client
            const s3Client = new S3Client({
                region: 'us-east-1',
                credentials: {
                    accessKeyId: "AKIAWH47LBWIJ52QVAHC",
                    secretAccessKey: "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"
                }
            });


            // Create the command
            const command = new GetObjectCommand({
                Bucket: 'manufactureruploads171127-dev',
                Key: `public/${fileName}`

            });

            // Get the presigned URL
            const url = await getSignedUrl(s3Client, command, {
                expiresIn: 3600 // URL valid for 1 hour
            });

            if (!url) {
                console.error("Failed to generate presigned URL.");
                return null;
            }

            return url;
        } catch (error) {
            console.error("Error fetching presigned URL:", error);
            return null;
        }
    };

    const saveFileReferenceToDatabase = async (s3Key, companyId, productId, countryInfo) => {
        try {
            // Ensure userId is available
            // if (!s3Key || !companyId || !productId || !countryCode || !countryInfo) {
            //     throw new Error("Required data is not available");
            // }

            const countryCode = countryInfo.countryCode

            //console.log("UserId",localUserId)
            // Check if the regulatory profile already exists
            const existingProfileData = await API.graphql(graphqlOperation(getRegulatoryProfile, { id: localUserId }));
            const existingProfile = existingProfileData.data.getRegulatoryProfile;

            if (!existingProfile) {
                // Create a new profile if it doesn't exist
                const newProfileData = {
                    id: localUserId,
                    productId: productId,
                    companyId: companyId,
                    [countryCode]: [s3Key],
                };
                await API.graphql(graphqlOperation(createRegulatoryProfile, { input: newProfileData }));
                console.log("New regulatory profile created");
            } else {
                // Update existing profile
                const existingDocuments = existingProfile[countryCode] || [];
                const updatedDocuments = [...existingDocuments, s3Key]; // Add the new s3Key

                const updatedProfileData = {
                    id: localUserId,
                    productId: productId,
                    companyId: companyId,
                    [countryCode]: updatedDocuments
                };
                await API.graphql(graphqlOperation(updateRegulatoryProfile, { input: updatedProfileData }));
                console.log("Regulatory profile updated");
            }
        } catch (error) {
            console.error("Error saving file reference to database:", error);
        }
    };

    const handleRemoveFile = async (fileKey, requirementName) => {
        if (fileKey && !window.confirm("Are you sure you want to delete this file?")) {
            return; // If user cancels, exit the function
        }

        try {
            // Example: await deleteFileFromS3(fileKey); // Remove the file from S3

            // Remove the file reference from the database
            const existingProfileData = await API.graphql(graphqlOperation(getRegulatoryProfile, { id: localUserId }));
            const existingProfile = existingProfileData.data.getRegulatoryProfile;
            if (existingProfile && existingProfile[countryCode]) {
                const updatedDocuments = existingProfile[countryCode].filter(key => key !== fileKey);
    
                const updatedProfileData = {
                    id: localUserId,
                    [countryCode]: updatedDocuments
                };
                await API.graphql(graphqlOperation(updateRegulatoryProfile, { input: updatedProfileData }));
                console.log("Regulatory profile updated, file reference removed");
    
                // Update the uploadedFiles state to reflect the change
                setUploadedFiles(prevFiles => {
                    const updatedFiles = { ...prevFiles };
                    delete updatedFiles[fileKey];
                    return updatedFiles;
                });
            }
        } catch (error) {
            console.error("Error removing file:", error);
        }
    };


    const formatFileDisplayName = (requirementName, index) => {
        return `${requirementName}${index > 0 ? ' ' + (index + 1) : ''}`;
    };

    const formatTitle = (title) => {
        return title
            // Split the title at each uppercase letter
            .split(/(?=[A-Z])/)
            // Capitalize the first letter of each word and join them with a space
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };


    if (!countryInfo) {
        return <div>Country not found</div>;
    }

    const getFirstSentence = (text) => {
        let match = text.match(/[^\.!\?]+/);
        return match ? match[0] : text;
    };

    const FileInputButton = ({ onChange }) => {
        return (
          <label htmlFor="file-upload" style={{ display: 'inline-block', cursor: 'pointer' }}>
            <input
              id="file-upload"
              type="file"
              style={{ display: 'none' }}
              onChange={onChange}
            />
            <StyledFileButton component="span">
              Choose File
            </StyledFileButton>
          </label>
        );
      };

    //console.log("URL:", countryLinks[countryInfo.country]);

    return (
        <div style={styles.container}>
            <Sidebar />
            <div style={styles.content}>
                <h1 style={styles.title}>Upload Files for {countryInfo.country}
                    <ReactCountryFlag
                        countryCode={countryCode}
                        svg
                        style={{
                            width: '2em',
                            height: '2em',
                            marginLeft: '10px',
                            borderRadius: '50%', // Make the flag circular
                            objectFit: 'cover', // Ensure the flag covers the area
                            border: '1px solid #ddd' // Optional: add a border for better visibility
                        }}
                    /></h1>
                <div>
                    <div style={styles.section}>
                        <a href={countryLinks[countryInfo.country]?.regulatoryEntity} style={{ textDecoration: 'underline' }} >
                            Visit {countryInfo.country} Regulatory Entity Here
                        </a>
                    </div>
                </div>

                {/* Progress Bar */}
                {/* <div style={progressBarStyle}>
                    <div style={progressStyle}>{uploadProgress.toFixed(0)}%</div>
                </div> */}
                {Object.entries(countryInfo.requirements).map(([sectionName, requirements]) => (
                    <div key={sectionName} style={styles.section}>
                        <h2 style={styles.sectionTitle}>{formatTitle(sectionName)}</h2>
                        {Object.entries(requirements).map(([requirementName, requirementDetails]) => (
                            <div key={requirementName} style={styles.requirement}>
                                <p>{getFirstSentence(requirementName)}</p>
                                <input
                                    type="file"
                                    style={styles.fileInput}
                                    onChange={(e) => handleFileSelection(requirementName, e.target.files)}
                                />
                                <button
                                    style={styles.uploadButton}
                                    onClick={() => handleUpload(requirementName, selectedFiles[requirementName])}
                                >
                                    Upload
                                </button>
                                {Object.entries(uploadedFiles)
                                    .filter(([fileKey]) => fileKey.includes(requirementName))
                                    .map(([fileKey, fileUrl], index) => (
                                        <div key={fileKey}>
                                            <a href={fileUrl} target="_blank" rel="noopener noreferrer">
                                                {`${getFirstSentence(requirementName)}${index > 0 ? ' ' + (index + 1) : ''}`}
                                            </a>
                                            <button onClick={() => handleRemoveFile(fileKey, null)}>Remove</button>
                                        </div>
                                    ))
                                }
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FileUploadPage;


