const regulationsPeru = {
    country: "Peru",
    countryCode: 'PE',
    deviceTypes: {
        "Medical Device": {
            "Classes": ["Class I", "Class II", "Class III", "Class IV"]
        },
        "In Vitro Device": {
            "Classes": ["Class I", "Class II", "Class III", "Class IV"]
        }
    },
    timeline: "Class I (low risk): Up to sixty (60) calendar days. Class II (moderate risk): Up to ninety (90) calendar days. Class III (high risk): Up to one hundred and twenty (120) calendar days. Class IV (critical in terms of risk): Up to one hundred and twenty (120) calendar days.",
    cost: {
        "Class I": "$286.27",
        "Class II": "$286.27",
        "Class III": "$286.27",
        "Class IV": "$286.27",
    },

    requirements: {
        sanitaryRegistration: {
            "Certificate of Good Manufacturing Practices. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Detailed Product Description.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Risk Management and Safety Compliance Documentation. Information Supporting the Equipment's Safety.": {
                appliesTo: {
                    "Medical Device": ["Class III", "Class IV"],
                    "In Vitro Device": ["Class III", "Class IV"]
                }
            },
            "List of Standards Used. List of Standards or Regulations used in the documentation.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Description of how the Essential Safety Requirements are met.": {
                appliesTo: {
                    "Medical Device": ["Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class II", "Class III", "Class IV"]
                }
            },
            "Clinical Studies on use to Demonstrate Safety and Effectiveness. Unless the devices are innovations.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Free Sale Certificate. Copy of Authorization for Import/Commercialization": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Notification to the Institution where the Equipment will be Installed": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Letter from the Manufacturer. Sworn Declaration from the Manufacturer or Representative in Peru with Several Details, such as that the Equipment is not under experimentation and maintenance service will be provided": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
        },
        documentDM: {
            "Type of device. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            }, 
            "Sanitary Registration. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            }, 
            "Device Classification. According to risk level.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            }, 
            "Name of the device. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            }, 
            "Universal Nomenclature and Identification Code of the Universal Nomenclature of the Medical Device.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            }, 
            "Description of the Manufacturing Process. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            }, 
            "Name of the Laboratory that Adds Information to the Product's Package.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            }, 
            "Forms of Presentation of the Medical Product. Details about the commercial form of the device": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            }, 
        },
        technicalReport: {
            "Medical and Technical Specifications. Technical specifications, methods used and Official Technical Standards of Reference.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Efficacy and Safety Information. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Detailed Product Description. Detailed description of the device, including operation, composition, and accessories.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Indication, Purpose, or Intended Use. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Precautions and Restrictions. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Forms of Presentation of the Medical Product. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Storage Method of the Device.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Description of the Manufacturing Process. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Efficacy and Safety Information. Description of the efficacy and safety of the device.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            }, 
        },
        otherRequirements: {
            "Instruction Manual. If it is an instrument, provide a copy of the catalog where it is included and a summary if it is justified that the full manual is not required. See requirements in Article 40.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            }, 
            "Sterilization Process. Copy of the sterilization or calibration process validation reports.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            }, 
            "Disposal Method of the Product, when applicable. ": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            }, 
            "Labels. Labeling for the packaging (Article 137 for requirements).": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            }, 
            "Risk Analysis Management Report. According to a specific ISO standard.": {
                appliesTo: {
                    "Medical Device": ["Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class II", "Class III", "Class IV"],
                }
            }, 
            "Quality Parameters. According to ISO standard and/or safety established by the FDA or European Community.": {
                appliesTo: {
                    "Medical Device": ["Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class II", "Class III", "Class IV"],
                }
            }, 
            "Commercial History. List of countries where the device is marketed, for imported products.": {
                appliesTo: {
                    "Medical Device": [ "Class III", "Class IV"],
                    "In Vitro Device": ["Class III", "Class IV"],
                }
            }, 
            "Presentation of a Surveillance Program by the Manufacturer. Post-marketing surveillance program for the device by the manufacturer.": {
                appliesTo: {
                    "Medical Device": ["Class III", "Class IV"],
                    "In Vitro Device": ["Class III", "Class IV"],
                }
            }, 
            "Biological Safety Certificate. In the case of medical devices made from human or animal tissues and their derivatives, present a certificate demonstrating their biological safety.": {
                appliesTo: {
                    "Medical Device": ["Class IV"],
                    "In Vitro Device": [ "Class IV"],
                }
            }, 
            "**Imported products must present their documentation in the original language and its translation into Spanish.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            }, 
        }
    }
};

export default regulationsPeru;