const regulationsChile = {
    country: "Chile",
    countryCode: 'CL',
    deviceTypes: {
        "Medical Device": {
            "Classes": ["Class I", "Class II", "Class III", "Class IV"]
        },
        "In Vitro Device": {
            "Classes": ["Class I", "Class II", "Class III", "Class IV"]
        }
    },
    timeline: "Currently, the majority of medical devices marketed in Chile are not under mandatory sanitary control. Indeed, according to current regulations, only the following medical devices are under mandatory sanitary control. Licenses issued in Chile are valid until the expiration of the device's first relevant certificate (FSC, ISO 13485, LoA). Authorization lasts three years and is automatically renewed every three years unless cancelled according to regulations.",
    cost: {
        "Class I": "$147.38",
        "Class II": "$147.38",
        "Class III": "$147.38",
        "Class IV": "$147.38",
    },

    requirements: {
        sanitaryCertification: {
            "Proof of Payment of Fees.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Certification of Conformity Assessment. Issued by an authorized body (as per Supreme Decree No. 825, 1998; Article No. 8 and following). ": {
                appliesTo: {
                    "Medical Device": [""],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"]
                }
            },
            "Manufacturer or Importer Information. Document issued by the manufacturer, acknowledging the applicant company as a distributor of their products." : {
                appliesTo: {
                    "Medical Device": [""],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Instruction Manual.": {
                appliesTo: {
                    "Medical Device": [""],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Certificate of Good Manufacturing Practices. Quality Management System Certificate in Manufacturing (e.g., ISO 13485, ISO 9001, or others).": {
                appliesTo: {
                    "Medical Device": [""],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Free Sale Certificate. Or other certificate for export purposes granted by the corresponding health authority, duly legalized.": {
                appliesTo: {
                    "Medical Device": [""],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Sterilization Process. Sterility certificate for the declared lots (required for syringes and needles).": {
                appliesTo: {
                    "Medical Device": [""],
                    "In Vitro Device": ["Class I", "Class II", "Class III", "Class IV"],
                }
            },
            "Name or Corporate Name of the Institution. For the institution, service, laboratory, or establishment provide address, Unique Tax Role, telephone or fax.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": [""],
                }
            },
            "Identification of the Owner or Legal Representative of the Entity.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": [""],
                }
            },
            "Indication, Purpose, or Intended Use. Indication of the item(s) whose conformity verification is proposed to be carried out.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": [""],
                }
            },
            "Compliance with the INN R 401 or or INN 100-607 standards. Regulations for product certification bodies and testing laboratories, or for product certification bodies, as applicable.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": [""],
                }
            },
            "Indication of the Personnel Available to carry out Verification. Include their respective professions, trades, or activities.": {
                appliesTo: {
                    "Medical Device": ["Class I", "Class II", "Class III", "Class IV"],
                    "In Vitro Device": [""],
                }
            },
        },
    }
};

export default regulationsChile;