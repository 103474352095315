import React, { useState, useEffect, useRef } from 'react';
import { Amplify, Auth, API, graphqlOperation } from 'aws-amplify';
import styled from 'styled-components';
import { getManufacturer, getTodo } from './graphql/queries'; // adjust your path
import { updateTodo, updateManufacturer } from './graphql/mutations'; // use createDistributor mutation
import { FormControl, InputLabel, MenuItem, Chip, Button, Typography } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Sidebar from './sideBar';
import { useDropzone } from 'react-dropzone';
import awsconfig from './aws-exports';
import { S3Client, GetObjectCommand, PutObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { useUser } from './UserContext';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { createGlobalStyle } from 'styled-components';

Amplify.configure(awsconfig);


const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'aktiv-grotesk', Arial, sans-serif;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  background: url('/corners.png') no-repeat center center fixed; 
  background-size: cover; // Ensure that one image covers the full container

`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 3;  // Give more flex space
  padding: 40px;
  overflow-x: auto; 
`;

const Label = styled.label`
  display: block;
  width: 100%
  
`;

const Input = styled.input`
  font-family: 'aktiv-grotesk', Arial, sans-serif !important;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ddd;
  margin-top: 5px
  overflow-x: hidden;
  white-space: wrap;  // Content doesn't wrap to next line
  text-overflow: ellipsis;  // Show ellipsis when content overflows

`;

const ActionButton = styled(Button)`
  font-family: "aktiv-grotesk", Arial, sans-serif !important; /* Fallback to Arial or sans-serif */ 
  margin-top: 1rem;
  border-radius: 90px;
  background-color: #0086ff; // Changed color here
  color: white;

  &:hover {
    background: linear-gradient(45deg, #0075db 30%, #0075db 90%); // Changed color here
  }

  &:active {
    transform: scale(0.98);
    transition: all .1s ease-in-out;
  }
  && {
    text-transform: Capitalize;
  }
`;

const ProfileSection = styled.div`
  border-radius: 10px;
  padding: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; // This will center the ProfileContent horizontally
`;

const ProfileContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center; // This will center the fields horizontally inside ProfileContent
    width: 60%;
    margin: auto; // This will center ProfileContent within ProfileSection
`;

const StyledLine = styled.div`
  height: 1px;
  background-color: #f1f2fa;  // or another color that matches the design
  margin: 8px ;  // add some margin for spacing
  width: 80%;  // or adjust to fit the desired length
`;

const AccountTitle = styled(Typography)`
  font-size: 1.5em;  
  margin-bottom: 3px;
  font-weight: 300, 
`;

const ProfileTitle = styled(Typography)`
  font-size: 1.2em;
  margin-top: 25px;
  margin-bottom: 30px;
`;

const LogoutButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #0086ff;
  margin: 20px;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`;

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: -10px;  // Adjust this value based on how much you want to move the section up
`;

const StyledDropzone = styled.div`
    border: 2px dashed #cccccc;
    border-radius: 4px;
    padding-bottom: 30px;
    padding-top: 10px;
    text-align: center;
    cursor: pointer;
    transition: border 0.3s ease;
    width: 60%; // Added to make width smaller
    height: 80px; 
    margin-bottom: 15px;
    &:hover {
    border: 2px dashed #0000cc;
    }
`;

const LinkContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    background-color: #f4f4f4;
    border-radius: 5px;
    margin-bottom: 5px;
    
`;

const LinkText = styled.a`
    flex: 1; // Allow it to grow and take up available space
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #0086ff;
    margin-right: 10px; // Space before the remove button
`;

const RemoveLinkButton = styled.button`
    background-color: #a8a8a8;
    color: white;
    border: none;
    margin-left: 10px;
    border-radius: 5px;
    padding: 0 8px;
    cursor: pointer;

    &:hover {
        background: linear-gradient(45deg, #0086ff 30%, #0075db 90%);
    }

    &:active {
        transform: scale(0.98);
        transition: all .1s ease-in-out;
    }
    && {
        text-transform: Capitalize;
    }
`;


const distributorFields = [
    { key: 'jobRole', label: 'Job Role' },
    { key: 'phoneNumber', label: 'Phone Number' },
    { key: 'website', label: 'Website' },
    { key: 'companySize', label: 'Company Size' },
    { key: 'foundedYear', label: 'Founded Year' },
    { key: 'companyHQ', label: 'Company HQ' },
    { key: 'territory', label: 'Distribution Territories' },
    { key: 'scope', label: 'Distribution Scope' },
    { key: 'brands', label: 'Brands or products you currently distribute' },
    { key: 'specialties', label: 'Specialties of products you currently distribute' },
    { key: 'desiredSpecialties', label: 'Specialties of products you would like to distribute' },
    { key: 'certificates', label: 'Certificates or Credentials' }
];

const manufacturerFields = [
    { key: 'companyName', label: 'Company Name' },
    { key: 'companyWebsite', label: 'Company Website' },
    { key: 'companyHQ', label: 'Company Headquarters' },
    { key: 'companySize', label: 'Company Size' },
    { key: 'yearFounded', label: 'Year Founded' },
    { key: 'clinicalSegment', label: 'Clinical Segment' },
    { key: 'companyLogo', label: 'Company Logo' },
    // { key: 'productName', label: 'Product Name' },
    // { key: 'productDescription', label: 'Product Description' },
    // { key: 'productType', label: 'Product Type' },
    // { key: 'developmentStage', label: 'Development Stage' },
    // { key: 'competitiveAdvantage', label: 'Competitive Advantage' },
    // { key: 'regulatoryApprovals', label: 'Regulatory Approvals' },
    // { key: 'approvalDocuments', label: 'Approval Documents' },
    // { key: 'clinicalIndications', label: 'Clinical Indications' },
    // { key: 'competitors', label: 'Competitors' },
    // { key: 'targetDemographic', label: 'Target Demographic' },
    // { key: 'currency', label: 'Currency' },
    // { key: 'transferPriceMin', label: 'Transfer Price Minimum' },
    // { key: 'transferPriceMax', label: 'Transfer Price Maximum' },
    // { key: 'distributionChannels', label: 'Distribution Channels' },
    // { key: 'relatedLinks', label: 'Related Links (Click enter to add)' },
    // { key: 'otherDocuments', label: 'Clinical Documents' }
];

const countryCodes = [
    { country: 'Argentina', code: '+54' },
    { country: 'Bolivia', code: '+591' },
    { country: 'Brazil', code: '+55' },
    { country: 'Chile', code: '+56' },
    { country: 'Colombia', code: '+57' },
    { country: 'Costa Rica', code: '+506' },
    { country: 'Cuba', code: '+53' },
    { country: 'Dominican Republic', code: '+1' },
    { country: 'Ecuador', code: '+593' },
    { country: 'El Salvador', code: '+503' },
    { country: 'Guatemala', code: '+502' },
    { country: 'Honduras', code: '+504' },
    { country: 'Mexico', code: '+52' },
    { country: 'Nicaragua', code: '+505' },
    { country: 'Panama', code: '+507' },
    { country: 'Paraguay', code: '+595' },
    { country: 'Peru', code: '+51' },
    { country: 'Puerto Rico', code: '+1' },
    { country: 'Uruguay', code: '+598' },
    { country: 'Venezuela', code: '+58' },
    { country: 'USA', code: '+1' },
];

const latinAmericanCountries = [
    'Argentina', 'Bolivia', 'Brazil', 'Chile', 'Colombia', 'Costa Rica', 'Cuba', 'Dominican Republic',
    'Ecuador', 'El Salvador', 'Guatemala', 'Honduras', 'Mexico', 'Nicaragua', 'Panama', 'Paraguay',
    'Peru', 'Puerto Rico', 'Uruguay', 'Venezuela',
];

const countries = [
    'Afghanistan', 'Albania', 'Algeria', 'Andorra', 'Angola', 'Antigua and Barbuda', 'Argentina',
    'Armenia', 'Australia', 'Austria', 'Azerbaijan', 'Bahamas', 'Bahrain', 'Bangladesh', 'Barbados',
    'Belarus', 'Belgium', 'Belize', 'Benin', 'Bhutan', 'Bolivia', 'Bosnia and Herzegovina', 'Botswana',
    'Brazil', 'Brunei', 'Bulgaria', 'Burkina Faso', 'Burundi', 'Côte d\'Ivoire', 'Cabo Verde',
    'Cambodia', 'Cameroon', 'Canada', 'Central African Republic', 'Chad', 'Chile', 'China', 'Colombia',
    'Comoros', 'Congo', 'Costa Rica', 'Croatia', 'Cuba', 'Cyprus', 'Czechia', 'Democratic Republic of the Congo',
    'Denmark', 'Djibouti', 'Dominica', 'Dominican Republic', 'Ecuador', 'Egypt', 'El Salvador', 'Equatorial Guinea',
    'Eritrea', 'Estonia', 'Eswatini', 'Ethiopia', 'Fiji', 'Finland', 'France', 'Gabon', 'Gambia', 'Georgia',
    'Germany', 'Ghana', 'Greece', 'Grenada', 'Guatemala', 'Guinea', 'Guinea-Bissau', 'Guyana', 'Haiti', 'Holy See',
    'Honduras', 'Hungary', 'Iceland', 'India', 'Indonesia', 'Iran', 'Iraq', 'Ireland', 'Israel', 'Italy',
    'Jamaica', 'Japan', 'Jordan', 'Kazakhstan', 'Kenya', 'Kiribati', 'Kuwait', 'Kyrgyzstan', 'Laos', 'Latvia',
    'Lebanon', 'Lesotho', 'Liberia', 'Libya', 'Liechtenstein', 'Lithuania', 'Luxembourg', 'Madagascar', 'Malawi',
    'Malaysia', 'Maldives', 'Mali', 'Malta', 'Marshall Islands', 'Mauritania', 'Mauritius', 'Mexico', 'Micronesia',
    'Moldova', 'Monaco', 'Mongolia', 'Montenegro', 'Morocco', 'Mozambique', 'Myanmar', 'Namibia', 'Nauru', 'Nepal',
    'Netherlands', 'New Zealand', 'Nicaragua', 'Niger', 'Nigeria', 'North Korea', 'North Macedonia', 'Norway', 'Oman',
    'Pakistan', 'Palau', 'Palestine State', 'Panama', 'Papua New Guinea', 'Paraguay', 'Peru', 'Philippines', 'Poland',
    'Portugal', 'Qatar', 'Romania', 'Russia', 'Rwanda', 'Saint Kitts and Nevis', 'Saint Lucia', 'Saint Vincent and the Grenadines',
    'Samoa', 'San Marino', 'Sao Tome and Principe', 'Saudi Arabia', 'Senegal', 'Serbia', 'Seychelles', 'Sierra Leone',
    'Singapore', 'Slovakia', 'Slovenia', 'Solomon Islands', 'Somalia', 'South Africa', 'South Korea', 'South Sudan',
    'Spain', 'Sri Lanka', 'Sudan', 'Suriname', 'Sweden', 'Switzerland', 'Syria', 'Tajikistan', 'Tanzania', 'Thailand',
    'Timor-Leste', 'Togo', 'Tonga', 'Trinidad and Tobago', 'Tunisia', 'Turkey', 'Turkmenistan', 'Tuvalu', 'Uganda', 'Ukraine',
    'United Arab Emirates', 'United Kingdom', 'United States of America', 'Uruguay', 'Uzbekistan', 'Vanuatu', 'Venezuela',
    'Vietnam', 'Yemen', 'Zambia', 'Zimbabwe'
];

const specialtiesList = [
    'Alternative Medicine',
    'Anaesthesia',
    'Bariatric Equipment',
    'Cardiology',
    'Clinical Consumables',
    'Continence',
    'Cosmetic and Plastic',
    'Critical Care',
    'Dermatology',
    'Diabetes',
    'Diagnostics',
    'Dialysis/Kidney/Renal',
    'Disability-Assistive Products',
    'Emergency Care',
    'Endoscopy',
    'Gastroenterology',
    'General Surgery',
    'Gynecology & Obstetrics',
    'ICU',
    'Imaging/Radiology',
    'Infection Control',
    'Infrastructure/Furniture/Logistics',
    'IT/Data Analytics/Telemedicine',
    'Laboratory/Testing',
    'Medical Equipment',
    'Neonatal',
    'Neurology',
    'Neonatal/Pediatric Care',
    'Oncology',
    'Ophthalmology',
    'Orthopedics',
    'Otorhinolaryngology (ENT)',
    'Patient Monitoring/AI',
    'Rehabilitation/Physiotherapy',
    'Respiratory',
    'Simulation',
    'Sterile Services',
    'Surgery',
    'Textiles/Medical Clothing/PPE',
    'Urology',
    'Wound Care',
];

const sizes = [
    { value: 'Small', label: 'Small (1-50)' },
    { value: 'Medium', label: 'Medium (51-200)' },
    { value: 'Large', label: 'Large (201-500)' },
    { value: 'Expanding', label: ' (500+)' },
];

const scope = [
    { value: 'Regional', label: 'Regional' },
    { value: 'National', label: 'National' },
    { value: 'Multinational', label: 'Multinational' },
];

const jobRoles = [
    { value: 'Management/Owner/C-Level', label: 'Management/Owner/C-Level' },
    { value: 'Engineer/Designer', label: 'Engineer/Designer' },
    { value: 'Regulatory Affairs Specialist', label: 'Regulatory Affairs Specialists' },
    { value: 'Sales and Marketing Team', label: 'Sales and Marketing Team' },
    { value: 'Supply Chain and Logistics Professionals', label: 'Supply Chain and Logistics Professionals' },
    { value: 'Customer/Technical Support Representative', label: 'Customer/Technical Support Representative' },
    { value: 'Data Scientists/Analysts', label: 'Data Scientists/Analysts' },
    { value: 'Other', label: 'Other' },

];

const productTypes = [
    'Software', 'Consumable', 'Implantable Devices', 'Capital Equipment',
    'In Vitro Diagnostics', 'Reusable Equipment', 'Hybrid', 'Other'
];

const regulatoryApproval = [
    'FDA (United States)', 'CE Mark (Europe)', 'COFEPRIS (Mexico)', 'ANVISA (Brazil)', 'NMPA (China)',
    'PMDA (Japan)', 'CDSCO (India)', 'Other - Country of Origin', 'Other'
]

const currencies = [
    { label: 'USD ($)', value: 'USD' },      // United States Dollar
    { label: 'EUR (€)', value: 'EUR' },      // Euro
    { label: 'JPY (¥)', value: 'JPY' },      // Japanese Yen
    { label: 'CNY (¥)', value: 'CNY' },      // Chinese Yuan Renminbi
    { label: 'INR (₹)', value: 'INR' },      // Indian Rupee
    { label: 'GBP (£)', value: 'GBP' },      // British Pound Sterling
];

const stages = [
    'Design and Development', 'Pre-Clinical Testing', 'Regulatory Submitted',
    'Regulatory Approved and Commercial in Country of Origin', 'Regulatory Approved and Commercial in other countries',
    'Other'
];

const dropdownFields = {
    jobRole: jobRoles,
    companyHQ: latinAmericanCountries.map(compHQ => ({ value: compHQ, label: compHQ })),
    companySize: sizes,
    scope: scope,
    territory: latinAmericanCountries.map(country => ({ value: country, label: country })),
    specialties: specialtiesList.map(specialty => ({ value: specialty, label: specialty })),
    desiredSpecialties: specialtiesList.map(specialty => ({ value: specialty, label: specialty }))
};

const dropdownFieldsManufacturer = {
    companyHQ: countries.map(compHQ => ({ value: compHQ, label: compHQ })),
    companySize: sizes,
    clinicalSegment: specialtiesList.map(specialty => ({ value: specialty, label: specialty })),
    productType: productTypes.map(productTypes => ({ value: productTypes, label: productTypes })),
    developmentStage: stages.map(stages => ({ value: stages, label: stages })),
    regulatoryApprovals: regulatoryApproval.map(regulatoryApproval => ({ value: regulatoryApproval, label: regulatoryApproval })),
    currency: currencies,
}

const theme = createTheme({
  typography: {
    fontFamily: 'aktiv-grotesk, Arial, sans-serif',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          fontFamily: 'aktiv-grotesk, Arial, sans-serif',
        },
      },
    },
  },
});


const SESSION_TIMEOUT = 15 * 60 * 1000;

const Dashboard = () => {
    const [userData, setUserData] = useState({});
    const [editData, setEditData] = useState({});
    const [images, setImages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [companyLogoUrl, setCompanyLogoUrl] = useState('/default-profile.png');
    const { userId, userType, setUserId, setUserType, isAdmin, setIsAdmin, isManuf, setIsManuf } = useUser();
    const navigate = useNavigate();
    const logoutTimerRef = useRef(null);
    const [files, setFiles] = useState({
        companyLogo: [],
        approvalDocuments: [],
        otherDocuments: []
    });
    const [presignedURLs, setPresignedURLs] = useState({
        companyLogo: null, // This assumes you only have one companyLogo
        approvalDocs: [],
        otherDocs: []
    });

    setUserType(localStorage.getItem('userType'))

    // Determine user ID based on user type
    if (userType === 'Distributor') {
        setUserId(localStorage.getItem('distributorId')); // Get user ID from local storage
    } else if (userType === 'Manufacturer') {
        setIsManuf(true)
        setUserId(localStorage.getItem('manufacturerId')); // Get user ID from local storage

    }

    // Effect to fetch user data based on userType and userId
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                if (userId) {
                    let userDataResponse;
                    if (userType === 'Distributor') {
                        userDataResponse = await API.graphql(graphqlOperation(getTodo, { id: userId }));
                        setUserData(userDataResponse.data.getTodo);
                        setEditData(userDataResponse.data.getTodo);
                    } else if (userType === 'Manufacturer') {
                        userDataResponse = await API.graphql(graphqlOperation(getManufacturer, { id: userId }));
                        setUserData(userDataResponse.data.getManufacturer);
                        setEditData(userDataResponse.data.getManufacturer);
                    }
                    const userData = userDataResponse.data.getTodo || userDataResponse.data.getManufacturer;
                    if (editData.companyLogo) {
                        try {
                            const url = await fetchPresignedURL(editData.companyLogo);
                            setCompanyLogoUrl(url); // Save the fetched URL to state
                            setPresignedURLs(prevState => ({
                                ...prevState,
                                companyLogo: url
                            }));
                        } catch (error) {
                            console.error('Error fetching presigned URL for company logo:', error);
                        }
                    }
                    // Fetch presigned URLs for existing filenames
                    for (const field of ['companyLogo', 'approvalDocuments', 'otherDocuments']) {
                        if (userData[field]) {
                            const fileNames = Array.isArray(userData[field]) ? userData[field] : [userData[field]];
                            const urls = await Promise.all(fileNames.map(async fileName => await fetchPresignedURL(fileName)));
                            setPresignedURLs(prevState => {
                                return { ...prevState, [field]: urls };
                            });
                        }
                    }
                } else {
                    console.error('User ID not found in local storage');
                }
            } catch (error) {
                console.error('Error fetching user data', error);
            }
        };
        fetchUserData();
    }, [userId, userType]);

    useEffect(() => {
        const fetchPresignedImageUrl = async () => {
            // Ensure that editData.companyLogo is a string before checking it with startsWith
            if (typeof editData.companyLogo === 'string' && !editData.companyLogo.startsWith('/')) {
                try {
                    const url = await fetchPresignedURL(editData.companyLogo);
                    // If the fetch operation is successful, set the company logo URL
                    setCompanyLogoUrl(url);
                } catch (error) {
                    console.error('Error fetching presigned URL for company logo:', error);
                    // If an error occurs (e.g., due to a deleted image), set to the default image
                    setCompanyLogoUrl('/default-profile.png');
                }
            } else {
                // If companyLogo is null, undefined, or not a string, set to default image
                setCompanyLogoUrl('/default-profile.png');
            }
        };

        // Call the function to fetch the presigned URL or set the default image
        fetchPresignedImageUrl();
    }, [editData.companyLogo]);



    const updateProfile = async (changes) => {
        try {
            const changes = getChangedFields(userData, editData);
            changes.id = changes.id || userData.id; // Ensuring ID is present

            // Check if it's a Manufacturer and ensure required fields are present
            if (userType === 'Manufacturer') {
                changes.companyID = changes.companyID || userData.companyID; // Ensuring companyID is present
                changes.productID = changes.productID || userData.productID; // Ensuring productID is present

                if (!changes.companyID || !changes.productID || !changes.id) {
                    console.error('Missing required fields for Manufacturer', changes);
                    alert('Error: Missing required data for Manufacturer. Please try again.');
                    return;
                }
            } else if (userType === 'Distributor' && !changes.id) {
                console.error('Missing required ID for Distributor', changes);
                alert('Error: Missing required data for Distributor. Please try again.');
                return;
            }

            if (Object.keys(changes).length > 1) {
                if (userType === 'Distributor') {
                    await API.graphql(graphqlOperation(updateTodo, { input: changes }));
                }
                else if (userType === 'Manufacturer') {
                    console.log(changes)
                    await API.graphql(graphqlOperation(updateManufacturer, { input: changes }));
                }
                setUserData(editData);
                alert('Data saved successfully!');
            } else {
                console.log('No changes to update');
            }
        } catch (error) {
            console.error('Error updating user data', error);
            if (error.errors) console.error('Error details: ', error.errors);
        }
    };

    const getChangedFields = (originalData, updatedData) => {
        const changes = {};
        Object.keys(updatedData).forEach(key => {
            if (originalData[key] !== updatedData[key]) {
                changes[key] = updatedData[key];
            }
        });
        return changes;
    };

    const fetchPresignedURL = async (fileName) => {
        if (!fileName) {
            console.error("FileName is missing.");
            return null;
        }

        try {
            // Setup the S3 client
            const s3Client = new S3Client({
                region: 'us-east-1',
                credentials: {
                    accessKeyId: "AKIAWH47LBWIJ52QVAHC",
                    secretAccessKey: "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"
                }
            });


            // Create the command
            const command = new GetObjectCommand({
                Bucket: 'manufactureruploads171127-dev',
                Key: `public/${fileName}`

            });

            // Get the presigned URL
            const url = await getSignedUrl(s3Client, command, {
                expiresIn: 3600 // URL valid for 1 hour
            });

            if (!url) {
                console.error("Failed to generate presigned URL.");
                return null;
            }

            return url;
        } catch (error) {
            console.error("Error fetching presigned URL:", error);
            return null;
        }
    };

    const FileDropzone = ({ docType, onFilesChange, dbFileNames }) => {
        //console.log(dbFileNames)
        const { getRootProps, getInputProps } = useDropzone({
            onDrop: acceptedFiles => {
                onFilesChange(docType, acceptedFiles);
            },
            multiple: docType !== 'companyLogo'  // allow multiple only if not a companyLogo
        });

        // Use dbFileNames for displaying the file names stored in the database
        const fileNames = Array.isArray(dbFileNames) ? dbFileNames : dbFileNames ? [dbFileNames] : [];
        const urlsArray = Array.isArray(presignedURLs[docType])
            ? presignedURLs[docType]
            : presignedURLs[docType] ? [presignedURLs[docType]] : [];

        //console.log(fileNames, urlsArray)

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {/* Display existing files linked to their presigned URLs */}
                {urlsArray.map((url, index) => (
                    <LinkContainer key={index}>
                        <LinkText href={url} target="_blank">
                            {docType === 'companyLogo' ? fileNames[index] : fileNames[index] || `Document ${index + 1}`}
                        </LinkText>
                        <RemoveLinkButton onClick={(event) => handleDocumentRemove(docType, index, event)}>X</RemoveLinkButton>
                    </LinkContainer>
                ))}
                {((docType === 'companyLogo' && urlsArray.length === 0) || docType !== 'companyLogo') && (
                    <StyledDropzone {...getRootProps()}>
                        <input {...getInputProps()} />
                        <img src="/upload-icon.png" width="24" height="24"></img>
                        <InputLabel style={{ fontWeight: '550', marginBottom: '5px' }}>
                            Upload {formatDocType(docType)}
                        </InputLabel>
                        <InputLabel style={{ marginBottom: '10px' }}>Drag & drop your files here, or select them</InputLabel>
                    </StyledDropzone>
                )}

                {/* Display newly selected files for upload with their associated presigned URLs */}

            </div>
        );
    };


    const formatDocType = (docType) => {
        return docType
            .split(/(?=[A-Z])/) // split by camelCase
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter and make the rest lowercase
            .join(' '); // Join words with space
    };

    const handleFilesChange = async (field, acceptedFiles) => {
        // Initialize an empty array to hold unique file names
        const uniqueFileNames = [];

        for (let file of acceptedFiles) {
            // Generate a unique file name
            const uniqueFileName = `${Date.now()}-${file.name}`;
            uniqueFileNames.push(uniqueFileName); // Add the unique file name to the array

            // Upload the file to S3
            const uploadResult = await uploadFileToS3(file, uniqueFileName);

            if (uploadResult.success) {
                // Fetch the presigned URL with the unique file name
                const url = await fetchPresignedURL(uniqueFileName);
                if (url) {
                    setPresignedURLs((prevState) => ({
                        ...prevState,
                        [field]: [...(Array.isArray(prevState[field]) ? prevState[field] : []), url],
                    }));
                }
            }
        }

        // Update editData with the unique file names
        setEditData((prevState) => ({
            ...prevState,
            [field]: field === 'companyLogo' ? uniqueFileNames[0] : uniqueFileNames,
        }));

        // Update the files state based on whether it's companyLogo or other fields
        setFiles((prevState) => ({
            ...prevState,
            [field]: field === 'companyLogo' ? [acceptedFiles[0]] : [...acceptedFiles],
        }));
    };


    const uploadFileToS3 = async (file, fileName) => {
        const s3Client = new S3Client({
            region: 'us-east-1',
            credentials: {
                accessKeyId: "AKIAWH47LBWIJ52QVAHC",
                secretAccessKey: "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"
            }
        });

        try {
            const command = new PutObjectCommand({
                Bucket: 'manufactureruploads171127-dev',
                Key: `public/${fileName}`,
                Body: file
            });

            await s3Client.send(command);

            return { success: true };
        } catch (error) {
            console.error("Error uploading file to S3:", error);
            return { success: false };
        }
    };

    const handleDocumentRemove = async (docType, index, event) => {
        event.preventDefault(); // Prevent the default behavior
        event.stopPropagation(); // Stop event propagation

        const confirmation = window.confirm("Are you sure you want to delete this document?");
        if (!confirmation) {
            return; // User cancelled the deletion
        }

        // Update the UI by removing the document's URL from the list.
        setPresignedURLs(prevState => {
            const updatedUrls = [...prevState[docType]];
            updatedUrls.splice(index, 1);
            return { ...prevState, [docType]: updatedUrls };
        });

        // Update the editData state
        setEditData(prevState => {
            const updatedDocs = [...(prevState[docType] || [])];
            updatedDocs.splice(index, 1);
            return { ...prevState, [docType]: updatedDocs };
        });
    };

    const handleSignOut = async () => {
        try {
            await Auth.signOut();  // Calling signOut method from Auth module
            localStorage.clear();
            navigate('/login');
            console.log('User signed out!');
            setIsAdmin(false); // Reset isAdmin to false on sign out

            // Here, navigate the user to the sign-in page or do any other necessary cleanup
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };

    const handleChange = (key, value) => {
        if (key === 'relatedLinks' && typeof value === 'string') {
            setEditData(prev => ({ ...prev, [key]: [...prev[key] || [], value] }));
        } else if (Array.isArray(value)) {
            setEditData(prev => ({ ...prev, [key]: value }));
        } else {
            setEditData(prev => ({ ...prev, [key]: value }));
        }
    };


    const handleSave = async () => {
        // Clone the current editData to manipulate before saving
        let updatedEditData = { ...editData };
        console.log(companyLogoUrl)
        // Check if the companyLogoUrl is the default and editData has a companyLogo
        if (companyLogoUrl === '/default-profile.png') {
            // Set the companyLogo in the cloned object to an empty string
            updatedEditData.companyLogo = '';
        }
        console.log(updatedEditData.companyLogo)

        // Check if there are changes to save
        if (JSON.stringify(userData) !== JSON.stringify(updatedEditData)) {
            // Perform the save operation with the potentially modified editData
            await updateProfile(updatedEditData);

            // If companyLogo was set to an empty string, we want to refresh to show changes
            if (updatedEditData.companyLogo === '') {
                // You might want to update local state to reflect this as well
                setUserData(updatedEditData);
                setEditData(updatedEditData);

                // Refresh the page
                window.location.reload();
            }
        }
    };

    const setSessionTimeout = () => {
        if (logoutTimerRef.current) {
            clearTimeout(logoutTimerRef.current);
        }

        logoutTimerRef.current = setTimeout(() => {
            handleSessionTimeout();
        }, SESSION_TIMEOUT);
    };

    const handleSessionTimeout = async () => {
        try {
            await Auth.signOut();
            localStorage.clear();
            navigate('/login');
            console.log('Session timed out. User signed out!');
        } catch (error) {
            console.error('Error handling session timeout: ', error);
        }
    };

    const parsedEditData = { ...editData };

    if (typeof parsedEditData.territory === 'string') {
        parsedEditData.territory = parsedEditData.territory.split(', ').map(item => item.trim());
    }
    if (parsedEditData.scope && parsedEditData.scope.length === 1) {
        parsedEditData.scope = parsedEditData.scope[0].split(',').map(item => item.trim());
    }
    if (parsedEditData.specialties && parsedEditData.specialties.length === 1) {
        parsedEditData.specialties = parsedEditData.specialties[0].split(',').map(item => item.trim());
    }
    if (parsedEditData.desiredSpecialties && parsedEditData.desiredSpecialties.length === 1) {
        parsedEditData.desiredSpecialties = parsedEditData.desiredSpecialties[0].split(',').map(item => item.trim());
    }


    return (
        <ThemeProvider theme={theme}>
             <GlobalStyle />
            <Container style={{ width: '100%', height: '100%' }}>
                <Sidebar activeItem="accountSettings" />
                <MainContent >
                    <FlexContainer>
                        <h1>Account Details</h1>
                        {/* <AccountTitle variant="h5" style={{ fontWeight: '550', padddingTop: '' }}>Account Details</AccountTitle> */}
                        <LogoutButton onClick={handleSignOut} >Sign Out</LogoutButton>
                    </FlexContainer>
                    <StyledLine />
                    <div>
                        {images.map((image) => (
                            <img
                                src={image}
                                key={image}
                                style={{ width: 500, height: 300 }}
                            />
                        ))}
                    </div>
                    <div style={{ width: '700px' }}>
                        <ProfileSection>
                            <ProfileTitle variant="h6" className="profile-title" style={{ paddingRight: '660px', borderBottom: '2px solid #f1f2fa' }}>Profile</ProfileTitle>
                            <ProfileContent style={{ paddingLeft: '20px' }}>
                                {/* Profile Picture */}
                                <div style={{ padding: '20px' }}>
                                    <img
                                        src={companyLogoUrl} // Use the state variable here
                                        alt="Profile"
                                        style={{
                                            width: '100px',
                                            height: '100px',
                                            borderRadius: '50%',
                                            objectFit: 'cover',
                                            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)'
                                        }}
                                        onError={() => setCompanyLogoUrl('/default-profile.png')}
                                    />
                                </div>

                                {/* Name Label/Input */}
                                <Label style={{ padding: '0 20px' }}>
                                    Name:
                                    <Input type="text" value={editData.name || ''} onChange={(e) => handleChange('name', e.target.value)} />
                                </Label>

                                {/* Email Label */}
                                <Label>
                                    Email:
                                    <Input type="text" value={editData.email || ''} onChange={(e) => handleChange('email', e.target.value)} disabled />
                                </Label>

                                {/* UserType Label */}
                                <Label>
                                    User Type:
                                    <Input type="text" value={editData.userType || ''} onChange={(e) => handleChange('userType', e.target.value)} disabled style={{ marginBottom: '20px' }} />
                                </Label>
                            </ProfileContent>
                        </ProfileSection>
                        {editData.userType === 'Distributor' && distributorFields.map(field => (
                            <React.Fragment key={field.key}>
                                {field.key === 'phoneNumber' && <ProfileTitle variant="h6" style={{ marginBottom: '15px', borderBottom: '2px solid #f1f2fa' }}>Company</ProfileTitle>}
                                <ProfileContent style={{ paddingLeft: '40px' }}>
                                    <Label style={dropdownFields[field.key] ? { display: 'block', marginBottom: '30px', maxWidth: '100%' } : { display: 'block', marginBottom: '10px' }}>
                                        {field.label}:
                                        {dropdownFields[field.key] ? (
                                            <Select
                                                isMulti={['territory', 'specialties', 'desiredSpecialties'].includes(field.key)}
                                                value={['territory', 'specialties', 'desiredSpecialties'].includes(field.key)
                                                    ? dropdownFields[field.key].filter(option => parsedEditData[field.key].includes(option.value))
                                                    : dropdownFields[field.key].find(option => option.value === parsedEditData[field.key])}
                                                options={dropdownFields[field.key]}
                                                onChange={selected => {
                                                    const selectedValues = Array.isArray(selected) ? selected.map(s => s.value) : selected?.value;
                                                    handleChange(field.key, selectedValues);
                                                }}
                                            />
                                        ) : (
                                            (field.key === 'brands' || field.key === 'certificates') ? (
                                                <Input
                                                    value={editData[field.key] || ''}
                                                    onChange={(e) => handleChange(field.key, e.target.value)}
                                                    style={{
                                                        fontFamily: '"aktiv-grotesk", Arial, sans-serif !important',
                                                        width: '100%',
                                                        fontSize: '16px',
                                                        border: '1px solid #ccc',
                                                        borderRadius: '4px',
                                                        marginBottom: '20px',
                                                        boxSizing: 'border-box',
                                                        padding: '10px 10px 40px 10px',
                                                        overflowWrap: 'break-word',
                                                        resize: 'none',
                                                    }}
                                                />
                                            ) : (
                                                <Input
                                                    type="text"
                                                    value={editData[field.key] || ''}
                                                    onChange={(e) => handleChange(field.key, e.target.value)}
                                                    style={{
                                                        fontFamily: '"aktiv-grotesk", Arial, sans-serif !important',
                                                        width: '100%',
                                                        fontSize: '16px',
                                                        border: '1px solid #ccc',
                                                        borderRadius: '4px',
                                                        marginBottom: '20px',
                                                        boxSizing: 'border-box',
                                                    }}
                                                />
                                            )
                                        )}
                                    </Label>
                                </ProfileContent>
                                {field.key === 'scope' && <ProfileTitle variant="h6" style={{ marginBottom: '15px', borderBottom: '2px solid #f1f2fa' }}>Products</ProfileTitle>}
                            </React.Fragment>
                        ))}
                        {
                            editData.userType === 'Manufacturer' && manufacturerFields.map(field => (

                                <React.Fragment key={field.key}>

                                    {field.key === 'companyName' && <ProfileTitle variant="h6" className="profile-title" style={{ marginBottom: '20px', borderBottom: '2px solid #f1f2fa' }}>Company Information</ProfileTitle>}
                                    {field.key === 'productName' && <ProfileTitle variant="h6" className="profile-title" style={{ marginBottom: '20px', borderBottom: '2px solid #f1f2fa' }}>Product Details</ProfileTitle>}
                                    {field.key === 'competitors' && <ProfileTitle variant="h6" className="profile-title" style={{ marginBottom: '20px', borderBottom: '2px solid #f1f2fa' }}>Product Distribution & Pricing Details</ProfileTitle>}
                                    <ProfileContent>
                                        <Label style={dropdownFieldsManufacturer[field.key] ? { display: 'block', marginBottom: '30px', maxWidth: '100%', paddingLeft: '50px' } : { display: 'block', marginBottom: '10px', paddingLeft: '50px' }}>
                                            <div>{field.label}:</div>

                                            {(['productDescription', 'competitiveAdvantage', 'clinicalIndications', 'competitors', 'targetDemographic', 'distributionChannels'].includes(field.key)) ? (
                                                <Input
                                                    value={editData[field.key] || ''}
                                                    onChange={(e) => handleChange(field.key, e.target.value)}
                                                    style={{
                                                        fontFamily: '"aktiv-grotesk" !important',
                                                        width: '100%',
                                                        height: '110px', // Adjust as needed for 2 lines
                                                        fontSize: '16px',
                                                        lineHeight: '20px', // Ensures proper line spacing
                                                        border: '1px solid #ccc',
                                                        borderRadius: '4px',
                                                        marginBottom: '20px',
                                                        padding: '10px 10px', // Adjust padding to align text to the top
                                                        boxSizing: 'border-box',
                                                        resize: 'none', // Prevents resizing. Set to 'vertical' if resizing is desired
                                                    }}
                                                />
                                            ) : ['companyLogo', 'approvalDocuments', 'otherDocuments'].includes(field.key) ? (
                                                <div style={{ marginTop: '10px' }}>
                                                    <FileDropzone
                                                        docType={field.key}
                                                        onFilesChange={handleFilesChange}
                                                        dbFileNames={editData[field.key]}
                                                    />
                                                </div>
                                            ) : field.key === 'relatedLinks' ? (
                                                <Select
                                                    isMulti
                                                    isClearable
                                                    isSearchable
                                                    inputValue={inputValue}
                                                    menuIsOpen={false}
                                                    value={(editData[field.key] || []).map(link => ({ label: link, value: link }))}
                                                    styles={{
                                                        control: (provided) => ({
                                                            ...provided,
                                                            maxWidth: '100%',
                                                            marginBottom: '25px'
                                                        }),
                                                    }}
                                                    onInputChange={(inputValue, { action }) => {
                                                        setInputValue(inputValue);
                                                        if (action === 'input-blur' || action === 'menu-close') return;
                                                        if (action === 'set-value') {
                                                            setInputValue(''); // Just clear the input value on selecting, since we're handling Enter key separately now
                                                        }
                                                    }}
                                                    onKeyDown={e => {
                                                        if (e.key === 'Enter' && inputValue.trim() !== '') {
                                                            handleChange('relatedLinks', [...(editData.relatedLinks || []), inputValue]);
                                                            setInputValue(''); // clear the inputValue after adding
                                                        }
                                                    }}
                                                    onChange={(selected) => {
                                                        const selectedValues = Array.isArray(selected) ? selected.map(s => s.value) : [];
                                                        handleChange(field.key, selectedValues);
                                                    }}
                                                />
                                            ) : dropdownFieldsManufacturer[field.key] ? (
                                                <Select
                                                    isMulti={['clinicalSegment', 'regulatoryApprovals'].includes(field.key)}  // Update with actual keys if any
                                                    value={
                                                        ['clinicalSegment', 'regulatoryApprovals'].includes(field.key)
                                                            ? dropdownFieldsManufacturer[field.key].filter(option => parsedEditData[field.key].includes(option.value))
                                                            : dropdownFieldsManufacturer[field.key].find(option => option.value === parsedEditData[field.key])
                                                    }
                                                    options={dropdownFieldsManufacturer[field.key]}
                                                    onChange={selected => {
                                                        const selectedValues = Array.isArray(selected) ? selected.map(s => s.value) : selected?.value;
                                                        handleChange(field.key, selectedValues);
                                                    }}
                                                />
                                            ) : (
                                                <Input
                                                    type="text"
                                                    value={editData[field.key] || ''}
                                                    onChange={(e) => handleChange(field.key, e.target.value)}
                                                    style={{
                                                        fontFamily: '"aktiv-grotesk", Arial, sans-serif !important',
                                                        width: '100%',
                                                        fontSize: '16px',
                                                        border: '1px solid #ccc',
                                                        borderRadius: '4px',
                                                        marginBottom: '20px',
                                                        boxSizing: 'border-box'

                                                    }}
                                                />
                                            )}
                                        </Label>
                                    </ProfileContent>
                                </React.Fragment>
                            ))
                        }
                        <div style={{ width: '450px' }}>
                            <ActionButton variant="contained" type="submit" style={{ width: '35%', borderRadius: '10px', marginRight: '10px', backgroundColor: "#0086ff", color: 'white', marginTop: '5px' }} onClick={handleSave}> Save</ActionButton>
                        </div>
                    </div>
                </MainContent>
            </Container>
        </ThemeProvider>

    );
};

export default Dashboard;
