import React, { useState, useEffect, useRef } from 'react';
import { Auth, API, graphqlOperation } from 'aws-amplify';
import styled from 'styled-components';
import { getManufacturer, getTodo, getProducts, listProducts, listManufacturers, getProductByProductId } from './graphql/queries'; // adjust your path
import { updateTodo, updateManufacturer, updateProducts } from './graphql/mutations'; // use createDistributor mutation
import { Button, Typography } from '@material-ui/core';
import { useParams, useNavigate } from 'react-router-dom';
import { InputLabel } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { S3Client, GetObjectCommand, PutObjectCommand } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';

import Select from 'react-select';
import Sidebar from './sideBar';


const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #ffffff;
  margin: 0;
  padding-top: 0px;
  min-height: 100vh;
  width: 100%;
  

`;

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding: 40px;
  overflow-x: hidden; 
`;


const Label = styled.label`
  display: block;
  margin-bottom: 10px;
  
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #ddd;
  margin-top: 5px
  overflow-x: hidden;
  white-space: wrap;  // Content doesn't wrap to next line
  text-overflow: ellipsis;  // Show ellipsis when content overflows

`;

const ActionButton = styled(Button)`
  font-family: "aktiv-grotesk", Arial, sans-serif !important; /* Fallback to Arial or sans-serif */
  margin-top: 1rem;
  border-radius: 90px;
  background-color: #0086ff; // Changed color here
  color: white;

  &:hover {
    background: linear-gradient(45deg, #0075db 30%, #0075db 90%); // Changed color here
  }

  &:active {
    transform: scale(0.98);
    transition: all .1s ease-in-out;
  }
  && {
    text-transform: Capitalize;
  }
`;

const StyledLine = styled.div`
  height: 1px;
  background-color: #f1f2fa;  // or another color that matches the design
  margin: 8px ;  // add some margin for spacing
  width: 90%;  // or adjust to fit the desired length
`;

const AccountTitle = styled(Typography)`
  font-size: 1.5em;  
  margin-bottom: 3px;
  font-weight: 300, 
`;

const ProfileTitle = styled(Typography)`
  font-size: 1.2em;
  margin-top: 25px;
  margin-bottom: 30px;
`;

const LogoutButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  color: #0086ff;
  margin: 20px;
  font-size: 16px;
  &:hover {
    text-decoration: underline;
  }
`;

const FlexContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: -10px;  // Adjust this value based on how much you want to move the section up
`;

const StyledDropzone = styled.div`
    border: 2px dashed #cccccc;
    border-radius: 4px;
    padding-bottom: 30px;
    padding-top: 10px;
    text-align: center;
    cursor: pointer;
    transition: border 0.3s ease;
    width: 60%; // Added to make width smaller
    height: 80px; 
    margin-bottom: 15px;
    &:hover {
    border: 2px dashed #0000cc;
    }
`;

const LinkContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 10px;
    background-color: #f4f4f4;
    border-radius: 5px;
    margin-bottom: 5px;
    
`;

const LinkText = styled.a`
    flex: 1; // Allow it to grow and take up available space
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #0086ff;
    margin-right: 10px; // Space before the remove button
`;

const RemoveLinkButton = styled.button`
    background-color: #a8a8a8;
    color: white;
    border: none;
    margin-left: 10px;
    border-radius: 5px;
    padding: 0 8px;
    cursor: pointer;

    &:hover {
        background: linear-gradient(45deg, #0086ff 30%, #0075db 90%);
    }

    &:active {
        transform: scale(0.98);
        transition: all .1s ease-in-out;
    }
    && {
        text-transform: Capitalize;
    }
`;



const manufacturerFields = [
    //{ key: 'companyName', label: 'Company Name' },
    { key: 'productName', label: 'Product Name' },
    { key: 'productDescription', label: 'Product Description' },
    { key: 'productType', label: 'Product Type' },
    { key: 'developmentStage', label: 'Development Stage' },
    { key: 'competitiveAdvantage', label: 'Competitive Advantage' },
    { key: 'regulatoryApprovals', label: 'Regulatory Approvals' },
    { key: 'approvalDocuments', label: 'Approval Documents' },
    { key: 'clinicalIndications', label: 'Clinical Indications' },
    { key: 'competitors', label: 'Competitors' },
    { key: 'targetDemographic', label: 'Target Demographic' },
    { key: 'currency', label: 'Currency' },
    { key: 'transferPriceMin', label: 'Transfer Price Minimum' },
    { key: 'transferPriceMax', label: 'Transfer Price Maximum' },
    { key: 'distributionChannels', label: 'Distribution Channels' },
    { key: 'relatedLinks', label: 'Related Links (Click enter to add)' },
    { key: 'otherDocuments', label: 'Clinical Documents' }
];

const productFields = [
    { key: 'companyName', label: 'Company Name' },
    { key: 'productName', label: 'Product Name' },
    { key: 'productDescription', label: 'Product Description' },
    { key: 'productType', label: 'Product Type' },
    { key: 'developmentStage', label: 'Development Stage' },
    { key: 'competitiveAdvantage', label: 'Competitive Advantage' },
    { key: 'regulatoryApprovals', label: 'Regulatory Approvals' },
    { key: 'approvalDocuments', label: 'Approval Documents' },
    { key: 'clinicalIndications', label: 'Clinical Indications' },
    { key: 'competitors', label: 'Competitors' },
    { key: 'targetDemographic', label: 'Target Demographic' },
    { key: 'currency', label: 'Currency' },
    { key: 'transferPriceMin', label: 'Transfer Price Minimum' },
    { key: 'transferPriceMax', label: 'Transfer Price Maximum' },
    { key: 'distributionChannels', label: 'Distribution Channels' },
    { key: 'relatedLinks', label: 'Related Links (Click enter to add)' },
    { key: 'otherDocuments', label: 'Clinical Documents' }
];


const specialtiesList = [
    'Alternative Medicine',
    'Anaesthesia',
    'Bariatric Equipment',
    'Cardiology',
    'Clinical Consumables',
    'Continence',
    'Cosmetic and Plastic',
    'Critical Care',
    'Dermatology',
    'Diabetes',
    'Diagnostics',
    'Dialysis/Kidney/Renal',
    'Disability-Assistive Products',
    'Emergency Care',
    'Endoscopy',
    'Gastroenterology',
    'General Surgery',
    'Gynecology & Obstetrics',
    'ICU',
    'Imaging/Radiology',
    'Infection Control',
    'Infrastructure/Furniture/Logistics',
    'IT/Data Analytics/Telemedicine',
    'Laboratory/Testing',
    'Medical Equipment',
    'Neonatal',
    'Neurology',
    'Neonatal/Pediatric Care',
    'Oncology',
    'Ophthalmology',
    'Orthopedics',
    'Otorhinolaryngology (ENT)',
    'Patient Monitoring/AI',
    'Rehabilitation/Physiotherapy',
    'Respiratory',
    'Simulation',
    'Sterile Services',
    'Surgery',
    'Textiles/Medical Clothing/PPE',
    'Urology',
    'Wound Care',
];

//Generate a list of years from 1900 to current year
const getYears = () => {
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: currentYear - 1899 }, (v, i) => 1900 + i);
    return years;
};

const sizes = [
    { value: 'Small', label: 'Small (1-50)' },
    { value: 'Medium', label: 'Medium (51-200)' },
    { value: 'Large', label: 'Large (201-500)' },
    { value: 'Expanding', label: ' (500+)' },
];

const scope = [
    { value: 'Regional', label: 'Regional' },
    { value: 'National', label: 'National' },
    { value: 'Multinational', label: 'Multinational' },
];

const jobRoles = [
    { value: 'Management/Owner/C-Level', label: 'Management/Owner/C-Level' },
    { value: 'Engineer/Designer', label: 'Engineer/Designer' },
    { value: 'Regulatory Affairs Specialist', label: 'Regulatory Affairs Specialists' },
    { value: 'Sales and Marketing Team', label: 'Sales and Marketing Team' },
    { value: 'Supply Chain and Logistics Professionals', label: 'Supply Chain and Logistics Professionals' },
    { value: 'Customer/Technical Support Representative', label: 'Customer/Technical Support Representative' },
    { value: 'Data Scientists/Analysts', label: 'Data Scientists/Analysts' },
    { value: 'Other', label: 'Other' },

];

const productTypes = [
    'Software', 'Consumable', 'Implantable Devices', 'Capital Equipment',
    'In Vitro Diagnostics', 'Reusable Equipment', 'Hybrid', 'Other'
];

const regulatoryApproval = [
    'FDA (United States)', 'CE Mark (Europe)', 'COFEPRIS (Mexico)', 'ANVISA (Brazil)', 'NMPA (China)',
    'PMDA (Japan)', 'CDSCO (India)', 'Other - Country of Origin', 'Other'
]

const currencies = [
    { label: 'USD ($)', value: 'USD' },      // United States Dollar
    { label: 'EUR (€)', value: 'EUR' },      // Euro
    { label: 'JPY (¥)', value: 'JPY' },      // Japanese Yen
    { label: 'CNY (¥)', value: 'CNY' },      // Chinese Yuan Renminbi
    { label: 'INR (₹)', value: 'INR' },      // Indian Rupee
    { label: 'GBP (£)', value: 'GBP' },      // British Pound Sterling
];

const stages = [
    'Design and Development', 'Pre-Clinical Testing', 'Regulatory Submitted',
    'Regulatory Approved and Commercial in Country of Origin', 'Regulatory Approved and Commercial in other countries',
    'Other'
];

const dropdownFields = {
    specialties: specialtiesList.map(specialty => ({ value: specialty, label: specialty })),
    desiredSpecialties: specialtiesList.map(specialty => ({ value: specialty, label: specialty }))
};

const dropdownFieldsManufacturer = {
    companySize: sizes,
    clinicalSegment: specialtiesList.map(specialty => ({ value: specialty, label: specialty })),
    productType: productTypes.map(productTypes => ({ value: productTypes, label: productTypes })),
    developmentStage: stages.map(stages => ({ value: stages, label: stages })),
    regulatoryApprovals: regulatoryApproval.map(regulatoryApproval => ({ value: regulatoryApproval, label: regulatoryApproval })),
    currency: currencies,
}

const dropdownFieldsProduct = {
    productType: productTypes.map(productTypes => ({ value: productTypes, label: productTypes })),
    developmentStage: stages.map(stages => ({ value: stages, label: stages })),
    regulatoryApprovals: regulatoryApproval.map(regulatoryApproval => ({ value: regulatoryApproval, label: regulatoryApproval })),
    currency: currencies,
}

const SESSION_TIMEOUT = 15 * 60 * 1000;

const ProductPage = () => {
    const [userData, setUserData] = useState({});
    const [editData, setEditData] = useState({});
    const [companyName, setCompanyName] = useState({});
    const [editDataProd, setEditDataProd] = useState({});
    const [logoURL, setLogoURL] = useState({});
    const [images, setImages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [products, setProducts] = useState([]);
    const [showAddProductForm, setShowAddProductForm] = useState(false);
    const effectiveUserId = localStorage.getItem('userId');
    const [companyData, setCompanyData] = useState({ companyId: '', companyName: '' });
    const [errorMessage, setErrorMessage] = useState('');
    const [previousEditData, setPreviousEditData] = useState({});
    const [companyLogoUrl, setCompanyLogoUrl] = useState('');
    const { productId: productIdFromURL } = useParams();
    const [activeProductId, setActiveProductId] = useState(null); 
    const [selectedProductId, setSelectedProductId] = useState(productIdFromURL || null);

    const [presignedURLs, setPresignedURLs] = useState({
        approvalDocs: [],  // An array for multiple approvalDocs
        otherDocs: []      // An array for multiple otherDocs
    });
    const [files, setFiles] = useState({
        approvalDocuments: [],
        otherDocuments: []
    });

    const navigate = useNavigate();
    const logoutTimerRef = useRef(null);

    const userType = localStorage.getItem('userType')
    let userId;

    if (userType === 'Distributor') {
        userId = localStorage.getItem('distributorId'); // Get user ID from local storage
    } else if (userType === 'Manufacturer') {
        userId = localStorage.getItem('manufacturerId'); // Get user ID from local storage

    }    //console.log(userId, userType)

    useEffect(() => {
        const fetchManufacturerData = async () => {
            //console.log("Fetching data for userId:", effectiveUserId);
            if (!effectiveUserId) {
                console.error("No userId available");
                setErrorMessage("No userId available");
                return;
            }

            try {
                const userDataResponse = await API.graphql(graphqlOperation(getManufacturer, { id: effectiveUserId }));
                //console.log("Manufacturer Data Response:", userDataResponse);
                const manufacturerData = userDataResponse.data.getManufacturer;

                if (manufacturerData) {
                    setCompanyData({
                        companyId: manufacturerData.companyID,
                    });
                } else {
                    console.error("Manufacturer data not found for userId:", effectiveUserId);
                    setErrorMessage('Manufacturer data not found for the given userId.');
                }
            } catch (error) {
                console.error("Error fetching manufacturer data:", error);
                setErrorMessage('Error fetching manufacturer data.');
            }
        };

        fetchManufacturerData();
    }, [effectiveUserId]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                if (editData && editData.companyID) {
                    const filter = { companyId: { eq: editData.companyID } };
                    //console.log("Company ID: ", companyData.companyId);
                    const productData = await API.graphql(graphqlOperation(listProducts, { filter: filter }));
                    const productsList = productData.data.listProducts.items;

                    if (productsList.length > 0) {
                        setProducts(productsList);
                        setSelectedProductId(productsList[0].id); // Set the first product's ID as the selectedProductId
                        
                    }
                } else {
                    console.log('No company ID found in editData');
                }
            } catch (err) {
                console.error('Error fetching products:', err);
            }
        };

        if (editData && editData.companyID) {
            fetchProducts();
        }
    }, [editData]);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                if (userId) {
                    if (userType === 'Manufacturer') {
                        const userData = await API.graphql(graphqlOperation(getManufacturer, { id: userId }));
                        setUserData(userData.data.getManufacturer);
                        setEditData(userData.data.getManufacturer);
                        setCompanyName(userData.companyName)

                    }
                    //console.log(companyName)
                } else {
                    console.error('User ID not found in local storage');
                }
            } catch (error) {
                console.error('Error fetching user data', error);
            }
        };

        fetchUserData();
        //fetchImages();

        // Set up session timeout
        setSessionTimeout();

        // Set up event listeners for user activity
        window.addEventListener("mousemove", resetSessionTimeout);
        window.addEventListener("mousedown", resetSessionTimeout);
        window.addEventListener("keypress", resetSessionTimeout);
        window.addEventListener("scroll", resetSessionTimeout);

        return () => {
            // Cleanup logic...
            clearTimeout(logoutTimerRef.current);

            // Remove event listeners
            window.removeEventListener("mousemove", resetSessionTimeout);
            window.removeEventListener("mousedown", resetSessionTimeout);
            window.removeEventListener("keypress", resetSessionTimeout);
            window.removeEventListener("scroll", resetSessionTimeout);
        };
    }, [userId]);


    const updateProfile = async () => {
        try {
            const changes = getChangedFields(userData, editData);
            changes.id = changes.id || userData.id; // Ensuring ID is present

            // Check if it's a Manufacturer and ensure required fields are present
            if (userType === 'Manufacturer') {
                changes.companyID = changes.companyID || userData.companyID; // Ensuring companyID is present
                changes.productID = changes.productID || userData.productID; // Ensuring productID is present

                if (!changes.companyID || !changes.productID || !changes.id) {
                    console.error('Missing required fields for Manufacturer', changes);
                    alert('Error: Missing required data for Manufacturer. Please try again.');
                    return;
                }
            }
            if (Object.keys(changes).length > 1) {
                if (userType === 'Manufacturer') {
                    await API.graphql(graphqlOperation(updateManufacturer, { input: changes }));
                }
                setUserData(editData);
                alert('Data saved successfully!');
            } else {
                console.log('No changes to update');
            }
        } catch (error) {
            console.error('Error updating user data', error);
            if (error.errors) console.error('Error details: ', error.errors);
        }
    };

    const fetchPresignedURL = async (fileName) => {
        if (!fileName) {
            console.error("FileName is missing.");
            return null;
        }

        try {
            // Setup the S3 client
            const s3Client = new S3Client({
                region: 'us-east-1',
                credentials: {
                    accessKeyId: "AKIAWH47LBWIJ52QVAHC",
                    secretAccessKey: "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"
                }
            });


            // Create the command
            const command = new GetObjectCommand({
                Bucket: 'manufactureruploads171127-dev',
                Key: `public/${fileName}`

            });

            // Get the presigned URL
            const url = await getSignedUrl(s3Client, command, {
                expiresIn: 3600 // URL valid for 1 hour
            });

            if (!url) {
                console.error("Failed to generate presigned URL.");
                return null;
            }

            return url;
        } catch (error) {
            console.error("Error fetching presigned URL:", error);
            return null;
        }
    };


    const FileDropzone = ({ docType, onFilesChange, dbFileNames }) => {
        //console.log(dbFileNames)
        const { getRootProps, getInputProps } = useDropzone({
            onDrop: acceptedFiles => {
                onFilesChange(docType, acceptedFiles);
            },
        });

        // Use dbFileNames for displaying the file names stored in the database
        const fileNames = Array.isArray(dbFileNames) ? dbFileNames : dbFileNames ? [dbFileNames] : [];
        const urlsArray = Array.isArray(presignedURLs[docType])
            ? presignedURLs[docType]
            : presignedURLs[docType] ? [presignedURLs[docType]] : [];

        //console.log(fileNames, urlsArray)

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {/* Display existing files linked to their presigned URLs */}
                {urlsArray.map((url, index) => (
                    <LinkContainer key={index}>
                        <LinkText href={url} target="_blank">
                            {docType === 'companyLogo' ? fileNames[index] : fileNames[index] || `Document ${index + 1}`}
                        </LinkText>
                        <RemoveLinkButton onClick={(event) => handleDocumentRemove(docType, index, event)}>X</RemoveLinkButton>
                    </LinkContainer>
                ))}
                {((docType === 'companyLogo' && urlsArray.length === 0) || docType !== 'companyLogo') && (
                    <StyledDropzone {...getRootProps()}>
                        <input {...getInputProps()} />
                        <img src="/upload-icon.png" width="24" height="24"></img>
                        <InputLabel style={{ fontWeight: '550', marginBottom: '5px' }}>
                            Upload {formatDocType(docType)}
                        </InputLabel>
                        <InputLabel style={{ marginBottom: '10px' }}>Drag & drop your files here, or select them</InputLabel>
                    </StyledDropzone>
                )}

                {/* Display newly selected files for upload with their associated presigned URLs */}

            </div>
        );
    };

    const formatDocType = (docType) => {
        return docType
            .split(/(?=[A-Z])/) // split by camelCase
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter and make the rest lowercase
            .join(' '); // Join words with space
    };

    const uploadFileToS3 = async (file, fileName) => {
        const s3Client = new S3Client({
            region: 'us-east-1',
            credentials: {
                accessKeyId: "AKIAWH47LBWIJ52QVAHC",
                secretAccessKey: "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"
            }
        });

        try {
            const command = new PutObjectCommand({
                Bucket: 'manufactureruploads171127-dev',
                Key: `public/${fileName}`,
                Body: file
            });

            await s3Client.send(command);

            return { success: true };
        } catch (error) {
            console.error("Error uploading file to S3:", error);
            return { success: false };
        }
    };


    const getChangedFields = (originalData, updatedData) => {
        const changes = {};
        Object.keys(updatedData).forEach(key => {
            if (originalData[key] !== updatedData[key]) {
                changes[key] = updatedData[key];
            }
        });
        return changes;
    };

    const handleFilesChange = async (field, acceptedFiles) => {
        // Initialize an empty array to hold unique file names
        const uniqueFileNames = [];

        for (let file of acceptedFiles) {
            // Generate a unique file name
            const uniqueFileName = `${Date.now()}-${file.name}`;
            uniqueFileNames.push(uniqueFileName); // Add the unique file name to the array

            // Upload the file to S3
            const uploadResult = await uploadFileToS3(file, uniqueFileName);

            if (uploadResult.success) {
                // Fetch the presigned URL with the unique file name
                const url = await fetchPresignedURL(uniqueFileName);
                if (url) {
                    setPresignedURLs((prevState) => ({
                        ...prevState,
                        [field]: [...(Array.isArray(prevState[field]) ? prevState[field] : []), url],
                    }));
                }
            }
        }

        // Update editData with the unique file names
        setEditData((prevState) => ({
            ...prevState,
            [field]: field === 'companyLogo' ? uniqueFileNames[0] : uniqueFileNames,
        }));

        // Update the files state based on whether it's companyLogo or other fields
        setFiles((prevState) => ({
            ...prevState,
            [field]: field === 'companyLogo' ? [acceptedFiles[0]] : [...acceptedFiles],
        }));
    };

    const handleSignOut = async () => {
        try {
            await Auth.signOut();  // Calling signOut method from Auth module
            localStorage.clear()
            navigate('/login')
            //console.log('User signed out!');

            // Here, navigate the user to the sign-in page or do any other necessary cleanup
        } catch (error) {
            console.error('Error signing out: ', error);
        }
    };

    const handleChange = (key, value) => {
        if (key === 'relatedLinks' && typeof value === 'string') {
            setEditData(prev => ({ ...prev, [key]: [...prev[key] || [], value] }));
        } else if (Array.isArray(value)) {
            setEditData(prev => ({ ...prev, [key]: value }));
        } else {
            setEditData(prev => ({ ...prev, [key]: value }));
        }
    };

    useEffect(() => {
        if (selectedProductId) {
            fetchProductData(selectedProductId, null);
        } else {
            setEditDataProd({}); // Reset the product data when no product is selected
        }
    }, [selectedProductId]);

    const fetchProductData = async (productId, urlProductId) => {
        //console.log("Inside fetchProductData - productId:", productId, "urlProductId:", urlProductId);
        if (urlProductId) {
            try {
                const URLProductResponse = await API.graphql(graphqlOperation(getProducts, { id: urlProductId }));
                //console.log("GraphQL response for URL product data:", URLProductResponse);
    
                if (URLProductResponse.data.getProducts) {
                    //console.log("Setting editDataProd with:", URLProductResponse.data.getProducts);
                    setPreviousEditData(editData);
                    setEditData(URLProductResponse.data.getProducts);
                    handleSelectProduct(productIdFromURL);
                } else {
                    //console.log("URL Product data is null or undefined.");
                    setEditDataProd({});
                }
            } catch (error) {
                //console.error("Error fetching URL product data:", error);
                setEditDataProd({});
            }
        }
    
        // Fetch product data based on provided productId argument
      if (productId) {
            try {
                const response = await API.graphql(graphqlOperation(getProducts, { id: productId }));
                //console.log("GraphQL response for product data:", response);
    
                if (response.data.getProducts) {
                    //console.log("Setting editDataProd with:", response.data.getProducts);
                    setPreviousEditData(editData);
                    setEditData(response.data.getProducts);
                } else {
                    //console.log("Product data is null or undefined.");
                    setEditDataProd({});
                }
            } catch (error) {
                //console.error("Error fetching product data:", error);
                setEditDataProd({});
            }
        }
    };
    

    useEffect(() => {
        //console.log("useEffect - productIdFromURL:", productIdFromURL);
        if (productIdFromURL) {
            setActiveProductId(productIdFromURL);
            fetchProductData(null, productIdFromURL);
        }
    }, [productIdFromURL]);

    const handleSelectProduct = (productId) => {
        // Fetch product data and update state as needed
        fetchProductData(productId);
        setSelectedProductId(productId);
    };

    const handleDocumentRemove = async (docType, index, event) => {
        event.preventDefault(); // Prevent the default behavior
        event.stopPropagation(); // Stop event propagation

        const confirmation = window.confirm("Are you sure you want to delete this document?");
        if (!confirmation) {
            return; // User cancelled the deletion
        }

        // Update the UI by removing the document's URL from the list.
        setPresignedURLs(prevState => {
            const updatedUrls = [...prevState[docType]];
            updatedUrls.splice(index, 1);
            return { ...prevState, [docType]: updatedUrls };
        });

        // Update the editData state
        setEditData(prevState => {
            const updatedDocs = [...(prevState[docType] || [])];
            updatedDocs.splice(index, 1);
            return { ...prevState, [docType]: updatedDocs };
        });
    };

    useEffect(() => {
        //console.log("Edit Data:", editData);
    }, [editData]);

    useEffect(() => {
        //console.log("Edit Data for Product:", editDataProd);
    }, [editDataProd]);

    const handleSave = async () => {
        if (JSON.stringify(userData) !== JSON.stringify(editData)) {
            try {
                // Prepare the fields for updating the product
                const productFields = {
                    id: editData.id,
                    companyName: editData.companyName,
                    productName: editData.productName,
                    productDescription: editData.productDescription,
                    productType: editData.productType,
                    developmentStage: editData.developmentStage,
                    competitiveAdvantage: editData.competitiveAdvantage,
                    regulatoryApprovals: editData.regulatoryApprovals,
                    approvalDocuments: editData.approvalDocuments,
                    clinicalIndications: editData.clinicalIndications,
                    competitors: editData.competitors,
                    targetDemographic: editData.targetDemographic,
                    currency: editData.currency,
                    transferPriceMin: editData.transferPriceMin,
                    transferPriceMax: editData.transferPriceMax,
                    distributionChannels: editData.distributionChannels,
                    relatedLinks: editData.relatedLinks,
                    otherDocuments: editData.otherDocuments,
                };

                // Update the product data
                const productUpdateResponse = await API.graphql(graphqlOperation(updateProducts, { input: productFields }));
                //console.log('Product update response:', productUpdateResponse);

                //console.log("EditData Product ID: ", editData.productId);

                const filter = { companyID: { eq: editData.companyId } };
                const manufacturerResponse = await API.graphql(graphqlOperation(listManufacturers, { filter: filter }));
                const manufacturers = manufacturerResponse.data.listManufacturers.items;
                // Check if any of the manufacturers have the product
                const isProductInManufacturer = manufacturers.some(manufacturer => manufacturer.productID === editData.productId);
                //console.log("Is Product in Manufacturer's DB? ", isProductInManufacturer);


                if (isProductInManufacturer) {
                    const manufacturerFields = {
                        id: editData.id, // The unique identifier for the manufacturer
                        productID: editData.productId,
                        companyID: editData.companyId,
                        productName: editData.productName,
                        productDescription: editData.productDescription,
                        productType: editData.productType,
                        competitiveAdvantage: editData.competitiveAdvantage,
                        developmentStage: editData.developmentStage,
                        regulatoryApprovals: editData.regulatoryApprovals,
                        approvalDocuments: editData.approvalDocuments,
                        clinicalIndications: editData.clinicalIndications,
                        competitors: editData.competitors,
                        targetDemographic: editData.targetDemographic,
                        currency: editData.currency,
                        transferPriceMin: editData.transferPriceMin,
                        transferPriceMax: editData.transferPriceMax,
                        distributionChannels: editData.distributionChannels,
                        relatedLinks: editData.relatedLinks,
                        otherDocuments: editData.otherDocuments,
                    };

                    // Update the manufacturer data
                    //console.log("Updating manufacturer with:", JSON.stringify(manufacturerFields, null, 2));

                    const manufacturerUpdateResponse = await API.graphql(graphqlOperation(updateManufacturer, { input: manufacturerFields }));
                    //console.log("Manufacturer update response: ", manufacturerUpdateResponse);
                } else {
                    //console.log("Product not found in manufacturer's database");
                }

                setUserData(editData);
                alert('Product Data saved successfully!');
            } catch (error) {
                console.error('Error updating product data', error);
                if (error.errors) {
                    console.error('Error details: ', error.errors);
                    // Log more detailed error info if available
                    //console.log(JSON.stringify(error, null, 2));
                }
                alert('An error occurred while saving the data.');
            }


            // Assuming each product in userData.products has a 'productId' field

            // Check if the product is part of the manufacturer's databas
        }
    };


    const setSessionTimeout = () => {
        if (logoutTimerRef.current) {
            clearTimeout(logoutTimerRef.current);
        }

        logoutTimerRef.current = setTimeout(() => {
            handleSessionTimeout();
        }, SESSION_TIMEOUT);
    };

    const resetSessionTimeout = () => {
        setSessionTimeout();
    };

    const handleSessionTimeout = async () => {
        try {
            await Auth.signOut();
            localStorage.clear();
            navigate('/login');
           // console.log('Session timed out. User signed out!');
        } catch (error) {
            console.error('Error handling session timeout: ', error);
        }
    };

    const customSelectStyles = {
        container: (provided) => ({
            ...provided,
            width: '100%', // Adjust the width as needed
            maxWidth: '370px', // You can set a maximum width
            paddingTop: '5px'
        }),
        control: (provided) => ({
            ...provided,
            minHeight: '30px', // Adjust the height of the dropdown
            fontSize: '16px', // Adjust font size as needed
        }),
        menu: (provided) => ({
            ...provided,
            fontSize: '16px', // Adjust font size in dropdown menu
        }),
        menu: (provided) => ({
            ...provided,
            fontSize: '16px', // Adjust font size in dropdown menu
            zIndex: 9999, // Ensure dropdown is on top of other elements
        }),
        menuPortal: base => ({ ...base, zIndex: 9999 })
        // Add more custom styles if needed
    };

    products.map(product => ({
        value: product.id,
        label: product.productName
    }));

    const AddProductButton = () => {

        const navigateToAddProduct = () => {
            navigate('/AddProduct');
        };

        return (
            <ActionButton
                variant="contained"
                onClick={navigateToAddProduct}
                style={{
                    fontFamily: '"aktiv-grotesk", sans-serif',
                    marginTop: "1rem",

                    backgroundColor: "#0086ff",
                    color: "white",
                    '&:hover': {
                        background: "linear-gradient(45deg, #0075db 30%, #0075db 90%)",
                    },
                    '&:active': {
                        transform: "scale(0.98)",
                        transition: "all 0.1s ease-in-out",
                    },
                }}
            >
                Add New Product
            </ActionButton>
        );
    }



    const parsedEditData = { ...editData };

    if (typeof parsedEditData.territory === 'string') {
        parsedEditData.territory = parsedEditData.territory.split(', ').map(item => item.trim());
    }
    if (parsedEditData.scope && parsedEditData.scope.length === 1) {
        parsedEditData.scope = parsedEditData.scope[0].split(',').map(item => item.trim());
    }
    if (parsedEditData.specialties && parsedEditData.specialties.length === 1) {
        parsedEditData.specialties = parsedEditData.specialties[0].split(',').map(item => item.trim());
    }
    if (parsedEditData.desiredSpecialties && parsedEditData.desiredSpecialties.length === 1) {
        parsedEditData.desiredSpecialties = parsedEditData.desiredSpecialties[0].split(',').map(item => item.trim());
    }


    return (
        <Container style={{ width: '100%', height: '100%' }}>
            <Sidebar />
            <MainContent >
                <FlexContainer>
                    <AccountTitle variant="h5" style={{ fontWeight: '550' }}>Product Dashboard</AccountTitle>
                    <AddProductButton variant="contained" type="submit" style={{ width: '35%', borderRadius: '10px', paddingBottom: '10px', marginRight: '10px', backgroundColor: "#0086ff", color: 'white', marginTop: '10px' }} onClick={handleSave}> Add New Product</AddProductButton>
                    <LogoutButton onClick={handleSignOut}>Sign Out</LogoutButton>
                </FlexContainer>
                <StyledLine />
                <div>
                    {images.map((image) => (
                        <img
                            src={image}
                            key={image}
                            style={{ width: 500, height: 300 }}
                        />
                    ))}
                </div>
                Select your Product
                <Select
                    value={{ value: selectedProductId, label: products.find(product => product.id === selectedProductId)?.productName || '' }}
                    onChange={(selectedOption) => {
                        handleSelectProduct(selectedOption.value);
                    }}
                    options={products.map(product => ({ value: product.id, label: product.productName }))}
                    styles={customSelectStyles}
                    menuPortalTarget={document.body}
                />

                <div style={{ maxWidth: '370px' }}>
                    {selectedProductId && editData && (
                        manufacturerFields.map((field) => (
                            <React.Fragment key={field.key}>
                                {field.key === 'productName' && <ProfileTitle variant="h6" style={{ marginBottom: '15px', fontWeight: 'bold', borderBottom: '2px solid #f1f2fa', marginTop: '20px' }}>Product Details</ProfileTitle>}
                                {field.key === 'competitors' && <ProfileTitle variant="h6" style={{ marginBottom: '15px', fontWeight: 'bold',  borderBottom: '2px solid #f1f2fa' }}>Product Distribution & Pricing Details</ProfileTitle>}
                                <Label style={dropdownFieldsManufacturer[field.key] ? { display: 'block', marginBottom: '30px', maxWidth: '100%' } : { display: 'block', marginBottom: '10px' }}>
                                    {field.label}:
                                    {(['productDescription', 'competitiveAdvantage', 'clinicalIndications', 'competitors', 'targetDemographic', 'distributionChannels'].includes(field.key)) ? (
                                            <textarea
                                                value={editData[field.key] || ''}
                                                onChange={(e) => handleChange(field.key, e.target.value)}
                                                style={{
                                                    width: '100%',
                                                    height: '110px', // Adjust as needed for 2 lines
                                                    fontSize: '16px',
                                                    lineHeight: '20px', // Ensures proper line spacing
                                                    border: '1px solid #ccc',
                                                    borderRadius: '4px',
                                                    marginBottom: '20px',
                                                    padding: '10px 10px', // Adjust padding to align text to the top
                                                    boxSizing: 'border-box',
                                                    resize: 'none', // Prevents resizing. Set to 'vertical' if resizing is desired
                                                    fontFamily: 'aktiv-grotesk',
                                                }}
                                            />
                                        ) :
                                    field.key === 'relatedLinks' ? (
                                        <Select
                                            isMulti
                                            isClearable
                                            isSearchable
                                            inputValue={inputValue}
                                            menuIsOpen={false}
                                            value={(editData[field.key] || []).map(link => ({ label: link, value: link }))}
                                            styles={{
                                                control: (provided) => ({
                                                    ...provided,
                                                    maxWidth: '100%',
                                                }),
                                            }}
                                            onInputChange={(inputValue, { action }) => {
                                                setInputValue(inputValue);
                                                if (action === 'input-blur' || action === 'menu-close') return;
                                                if (action === 'set-value') {
                                                    setInputValue(''); // Just clear the input value on selecting, since we're handling Enter key separately now
                                                }
                                            }}
                                            onKeyDown={e => {
                                                if (e.key === 'Enter' && inputValue.trim() !== '') {
                                                    handleChange('relatedLinks', [...(editData.relatedLinks || []), inputValue]);
                                                    setInputValue(''); // clear the inputValue after adding
                                                }
                                            }}
                                            onChange={(selected) => {
                                                const selectedValues = Array.isArray(selected) ? selected.map(s => s.value) : [];
                                                handleChange(field.key, selectedValues);
                                            }}
                                        />
                                    ) : dropdownFieldsManufacturer[field.key] ? (
                                        <Select
                                            isMulti={['clinicalSegment', 'regulatoryApprovals'].includes(field.key)}  // Update with actual keys if any
                                            value={
                                                ['clinicalSegment', 'regulatoryApprovals'].includes(field.key) && parsedEditData[field.key]
                                                    ? dropdownFieldsManufacturer[field.key].filter(option => parsedEditData[field.key].includes(option.value))
                                                    : dropdownFieldsManufacturer[field.key].find(option => option.value === parsedEditData[field.key])
                                            }

                                            options={dropdownFieldsManufacturer[field.key]}
                                            onChange={selected => {
                                                const selectedValues = Array.isArray(selected) ? selected.map(s => s.value) : selected?.value;
                                                handleChange(field.key, selectedValues);
                                            }}
                                        />
                                    ) : ['approvalDocuments', 'otherDocuments'].includes(field.key) ? (
                                        <div style={{ marginTop: '10px' }}>
                                            <FileDropzone
                                                docType={field.key}
                                                onFilesChange={handleFilesChange}
                                                dbFileNames={editData[field.key]}
                                            />
                                        </div>
                                    ) : (
                                        <Input
                                            type="text"
                                            value={editData[field.key] || ''}
                                            onChange={(e) => handleChange(field.key, e.target.value)}
                                            style={{
                                                width: '100%',
                                                fontSize: '16px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                marginBottom: '20px',
                                                boxSizing: 'border-box',
                                                fontFamily: '"aktiv-grotesk", Arial, sans-serif !important',
                                            }}
                                        />
                                    )}
                                </Label>
                            </React.Fragment>
                        ))
                    )}
                    {selectedProductId && (
                        <ActionButton
                            variant="contained"
                            type="submit"
                            style={{ width: '35%', borderRadius: '10px', marginRight: '10px', backgroundColor: "#0086ff", color: 'white', marginTop: '10px' }}
                            onClick={handleSave}
                        >
                            Save
                        </ActionButton>
                    )}
                </div>
            </MainContent>
        </Container>
    );
};

export default ProductPage;
