import React, { useState, useEffect, useRef } from 'react';
import Sidebar from './sideBar';
import { API, graphqlOperation } from 'aws-amplify';
import { listManufacturers, listTodos } from './graphql/queries';
import { useUser } from './UserContext';

const DatabaseManagementDashboard = () => {
    const [distributors, setDistributors] = useState([]);
    const [manufacturers, setManufacturers] = useState([]);
    const [distributorFields, setDistributorFields] = useState({});
    const [manufacturerFields, setManufacturerFields] = useState({});
    const [showDistributorDropdown, setShowDistributorDropdown] = useState(false);
    const [showManufacturerDropdown, setShowManufacturerDropdown] = useState(false);
    const distributorDropdownRef = useRef();
    const manufacturerDropdownRef = useRef();
    const [distributorSearch, setDistributorSearch] = useState('');
    const [manufacturerSearch, setManufacturerSearch] = useState('');
    const [distributorCompanySearch, setDistributorCompanySearch] = useState('');
    const [manufacturerCompanySearch, setManufacturerCompanySearch] = useState('');
    const [distributorProductSearch, setDistributorProductSearch] = useState('');
    const [manufacturerProductSearch, setManufacturerProductSearch] = useState('');

    const [activeTab, setActiveTab] = useState('distributors');


    const distributorCompanyFields = ['companyName', 'phoneNumber', 'website', 'companySize', 'foundedYear', 'companyHQ', 'territory', 'scope'];
    const manufacturerCompanyFields = ['companyName', 'companyWebsite', 'companyHQ', 'companySize', 'yearFounded', 'clinicalSegment', 'companyLogo'];
    const distributorProductFields = ['companyName','brands', 'specialties', 'desiredSpecialties', 'certificates'];
    const manufacturerProductFields = ['companyName','productName', 'productDescription', 'productType', 'developmentStage', 'competitiveAdvantage',
        'regulatoryApprovals', 'approvalDocuments', 'clinicalIndications', 'competitors', 'targetDemographic',
        'currency', 'transferPriceMin', 'transferPriceMax', 'distributionChannels', 'relatedLinks', 'otherDocuments'];


    useEffect(() => {
        const fetchAllData = async () => {
            try {
                const distributorsResponse = await API.graphql(graphqlOperation(listTodos));
                const manufacturersResponse = await API.graphql(graphqlOperation(listManufacturers));

                setDistributors(distributorsResponse.data.listTodos.items);
                setManufacturers(manufacturersResponse.data.listManufacturers.items);

                // Initialize fields
                initFields(distributorsResponse.data.listTodos.items, setDistributorFields);
                initFields(manufacturersResponse.data.listManufacturers.items, setManufacturerFields);
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };

        fetchAllData();
    }, []);

    const initFields = (data, setFields) => {
        const fieldVisibility = {};
        if (data.length > 0) {
            Object.keys(data[0]).forEach(field => {
                fieldVisibility[field] = true; // Set all fields to visible initially
            });
        }
        setFields(fieldVisibility);
    };


    const handleDistributorSearch = (event) => {
        setDistributorSearch(event.target.value.toLowerCase());
    };

    const handleManufacturerSearch = (event) => {
        setManufacturerSearch(event.target.value.toLowerCase());
    };

    const handleDistributorCompanySearch = (event) => {
        setDistributorCompanySearch(event.target.value.toLowerCase());
    };

    const handleManufacturerCompanySearch = (event) => {
        setManufacturerCompanySearch(event.target.value.toLowerCase());
    };




    const extractCompanyData = (data, fields) => {
        return data.map(item => {
            return fields.reduce((acc, field) => {
                acc[field] = item[field];
                return acc;
            }, {});
        });
    };


    const renderTable = (data, searchQuery, fields) => {
        if (!data.length) return <p>No data available</p>;

        const filteredData = data.filter(item => {
            return Object.entries(item).some(([key, value]) => {
                if (value === null || value === undefined) {
                    return false;
                }
                return value.toString().toLowerCase().includes(searchQuery);
            });
        });

        return (
            <div style={{ overflowY: 'auto', maxHeight: '300px', overflowX: 'auto' }}>
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                    <thead style={{ position: 'sticky', top: 0, backgroundColor: '#FFF' }}>
                        <tr>
                            {fields.map((field, index) => (
                                <th key={index} style={tableHeaderStyle}>{field}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((item, index) => (
                            <tr key={index}>
                                {fields.map((field, subIndex) => (
                                    <td key={subIndex} style={tableCellStyle}>
                                        {item[field] !== null && item[field] !== undefined ? item[field].toString() : ''}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const handleFieldCheckboxChange = (type, field, isChecked) => {
        if (type === 'distributor') {
            setDistributorFields(prevFields => ({ ...prevFields, [field]: isChecked }));
        } else if (type === 'manufacturer') {
            setManufacturerFields(prevFields => ({ ...prevFields, [field]: isChecked }));
        }
    };

    const tableHeaderStyle = {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: '8px',
    };

    const tableCellStyle = {
        border: '1px solid #dddddd',
        textAlign: 'left',
        padding: '8px',
    };

    const handleClickOutside = (event, ref, setShowDropdown) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setShowDropdown(false);
        }
    };

    useEffect(() => {
        const handleClickOutsideDistributor = (event) => handleClickOutside(event, distributorDropdownRef, setShowDistributorDropdown);
        const handleClickOutsideManufacturer = (event) => handleClickOutside(event, manufacturerDropdownRef, setShowManufacturerDropdown);

        document.addEventListener('mousedown', handleClickOutsideDistributor);
        document.addEventListener('mousedown', handleClickOutsideManufacturer);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideDistributor);
            document.removeEventListener('mousedown', handleClickOutsideManufacturer);
        };
    }, []);

    const renderFieldCheckboxes = (fields, type, showDropdown, setShowDropdown, ref) => (
        <div style={{ position: 'relative', marginBottom: '10px' }}>
            <button onClick={() => setShowDropdown(!showDropdown)} style={buttonStyle}>
                {type} Fields
            </button>
            {showDropdown && (
                <div ref={ref} style={dropdownStyle}>
                    {Object.keys(fields).map((field, index) => (
                        <label key={index} style={{ display: 'block' }}>
                            <input
                                type="checkbox"
                                checked={fields[field]}
                                onChange={(e) => handleFieldCheckboxChange(type, field, e.target.checked)}
                            /> {field}
                        </label>
                    ))}
                </div>
            )}
        </div>
    );

    const renderTabContent = () => {
        switch (activeTab) {
            case 'distributors':
                return (
                    <div>
                        <h2>Distributors</h2>
                        <input
                            type="text"
                            placeholder="Search Distributors..."
                            value={distributorSearch}
                            onChange={handleDistributorSearch}
                            style={{ padding: '8px', marginBottom: '10px' }}
                        />
                        {renderFieldCheckboxes(distributorFields, 'distributor', showDistributorDropdown, setShowDistributorDropdown, distributorDropdownRef)}
                        {renderTable(distributors, distributorSearch, Object.keys(distributorFields).filter(field => distributorFields[field]))}

                        <h2>Distributor Company Information</h2>
                        <input
                            type="text"
                            placeholder="Search Distributor Company..."
                            value={distributorCompanySearch}
                            onChange={handleDistributorCompanySearch}
                            style={{ padding: '8px', marginBottom: '10px' }}
                        />
                        {renderTable(extractCompanyData(distributors, distributorCompanyFields), distributorCompanySearch, distributorCompanyFields)}
                        <h2>Distributor Product Information</h2>
                        <input
                            type="text"
                            placeholder="Search Distributor Products..."
                            value={distributorProductSearch}
                            onChange={(e) => setDistributorProductSearch(e.target.value.toLowerCase())}
                            style={{ padding: '8px', marginBottom: '10px' }}
                        />
                        {renderTable(extractCompanyData(distributors, distributorProductFields), distributorProductSearch, distributorProductFields)}
                    </div>
                );
            case 'manufacturers':
                return (
                    <div>
                        <h2>Manufacturers</h2>
                        <input
                            type="text"
                            placeholder="Search Manufacturers..."
                            value={manufacturerSearch}
                            onChange={handleManufacturerSearch}
                            style={{ padding: '8px', marginBottom: '10px' }}
                        />
                        {renderFieldCheckboxes(manufacturerFields, 'manufacturer', showManufacturerDropdown, setShowManufacturerDropdown, manufacturerDropdownRef)}
                        {renderTable(manufacturers, manufacturerSearch, Object.keys(manufacturerFields).filter(field => manufacturerFields[field]))}

                        <h2>Manufacturer Company Information</h2>
                        <input
                            type="text"
                            placeholder="Search Manufacturer Company..."
                            value={manufacturerCompanySearch}
                            onChange={handleManufacturerCompanySearch}
                            style={{ padding: '8px', marginBottom: '10px' }}
                        />
                        {renderTable(extractCompanyData(manufacturers, manufacturerCompanyFields), manufacturerCompanySearch, manufacturerCompanyFields)}
                        <h2>Manufacturer Product Information</h2>
                        <input
                            type="text"
                            placeholder="Search Manufacturer Products..."
                            value={manufacturerProductSearch}
                            onChange={(e) => setManufacturerProductSearch(e.target.value.toLowerCase())}
                            style={{ padding: '8px', marginBottom: '10px' }}
                        />
                        {renderTable(extractCompanyData(manufacturers, manufacturerProductFields), manufacturerProductSearch, manufacturerProductFields)}
                    </div>
                );
            default:
                return null;
        }
    };


    const buttonStyle = {
        cursor: 'pointer',
        padding: '5px 10px',
        border: '1px solid #ddd',
        borderRadius: '4px',
        background: '#f0f0f0',
        marginBottom: '5px',
    };

    const dropdownStyle = {
        position: 'absolute',
        backgroundColor: '#fff',
        border: '1px solid #ddd',
        borderRadius: '4px',
        boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
        padding: '10px',
        zIndex: 1000,
        maxHeight: '200px',
        overflowY: 'auto',
    };


    return (
        <div style={{ display: 'flex', minHeight: '100vh' }}>
            <Sidebar />
            <div style={{ flex: 1, padding: '20px', overflowX: 'auto' }}>
                <h1>Database Overview Panel</h1>

                {/* Tab Buttons */}
                <div>
                    <button onClick={() => setActiveTab('distributors')} style={buttonStyle}>Distributors</button>
                    <button onClick={() => setActiveTab('manufacturers')} style={buttonStyle}>Manufacturers</button>
                </div>

                {/* Tab Content */}
                {renderTabContent()}
            </div>
        </div>
    );

};

export default DatabaseManagementDashboard;
